import backArrow from "../../../assets/img/backArrow.png"
import { CoverImage } from "./OfferingEditingSections/CoverImage"
import { Overview } from "./OfferingEditingSections/Overview"
import { Video } from "./OfferingEditingSections/Video"
import { Photos } from "./OfferingEditingSections/Photos"
import { ProgressPhotos } from "./OfferingEditingSections/ProgressPhotos"
import { Documents } from "./OfferingEditingSections/Documents"
import { isPhoneSize } from "utils/util"

export function OfferingEditing(props) {
    const { setView } = props

    const marginLeft = isPhoneSize() ? "0px" : "20px"

    return <>
        <div style={{ margin: "20px", display: "flex", justifyContent: "space-between", marginLeft: marginLeft }}>
            <img style={{ width: "15px", height: "20px", cursor: "pointer" }}
                src={backArrow}
                onClick={() => setView("fund")}
            ></img>

        </div>

        <div style={{ marginLeft: marginLeft }}>
            <CoverImage />
        </div>

        <div style={{ marginLeft: marginLeft }}>
            <Overview />
        </div>

        <div style={{ marginLeft: marginLeft }}>
            <Video />
        </div>

        <div style={{ marginLeft: marginLeft }}>
            <Photos />
        </div>

        <div style={{ marginLeft: marginLeft }}>
            <ProgressPhotos />
        </div>

        <div style={{ marginLeft: marginLeft }}>
            <Documents />
        </div>
    </>
}