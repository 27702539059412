import backArrow from "../../assets/img/backArrow.png"
import { Spin, message } from "antd"
import { useState, useEffect } from "react"
import { get } from "utils/fetch"
import api from "api"
import { USDollar } from "utils/format"
import { getTodayDateString, strToFloat } from "utils/util"

export function InterestManager(props) {
    const { setView, investmentId, frequency } = props
    const [loading, setLoading] = useState(true)
    const [interests, setInterests] = useState([])
    const [messageApi, contextHolder] = message.useMessage();
    const [interestMap, setInterestMap] = useState({})
    const [saveButtonText, setSaveButtonText] = useState("Save")
    const [saveButtonWidth, setSaveButtonWidth] = useState("50px")
    const [addedInterest, setAddedInterest] = useState([])
    // ids of interest entries to be deleted
    const [interestIdToBeDeleted, setInterestIdToBeDeleted] = useState(new Set())
    // console.log(addedInterest)
    const displayErrorMessage = (message) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };

    async function fetchPrevInterest(id) {
        await get(api.manager.interestByInvestment(id), setInterests, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            })
        )
        setLoading(false)
    }

    const entryStyle = {
        margin: "20px",
        fontSize: '20px'
    }

    const inputStyle = {
        border: "0",
        outline: "none",
        borderBottom: "1px solid black",
        fontSize: '20px',
        background: "transparent"
    }

    useEffect(() => {
        // set id -> amount as key value pair
        setInterestMap(
            interests
                .reduce((acc, cur) => {
                    acc[cur.id] = cur.amount
                    return acc
                }, {})
        )
        // console.log(map)
    }, [interests])

    useEffect(() => {
        fetchPrevInterest(investmentId)
    }, [investmentId])
    // console.log(addedInterest)

    async function saveInterest() {
        if (saveButtonText === "Saving") {
            return
        }
        setLoading(true)
        setSaveButtonText("Saving")
        setSaveButtonWidth("80px")
        console.log(interestIdToBeDeleted)
        // upload new interests
        const body = JSON.stringify({
            investmentId,
            interestMap,
            addedInterest,
            interestIdToBeDeleted: Array.from(interestIdToBeDeleted)
        })

        const options = {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            headers: {
                "Content-Type": "application/json"
            },
            body
        }
        await fetch(api.manager.updateInterests, options); // new record
        setAddedInterest([])
        fetchPrevInterest(investmentId)
        setSaveButtonText("Save")
        setSaveButtonWidth("50px")
    }

    return <>
        <div>
            <div style={{ margin: "20px" }}>
                <img style={{ width: "15px", height: "20px", cursor: "pointer" }}
                    src={backArrow}
                    onClick={() => setView("investment")}
                ></img>
            </div>
        </div>
        {loading && <div style={{ marginTop: "30px", textAlign: "center" }}><Spin /></div>}
        {!loading &&
            <div>
                <div style={entryStyle}>
                    <div style={{ display: "flex", fontSize: "17px" }}>
                        <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                            Time
                        </div>
                        <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                            Amount
                        </div>
                        <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                            Dollar Amount
                        </div>
                        <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                            Status
                        </div>
                    </div>
                    <div style={{ border: "1px solid black", width: "600px" }}>
                        {interests
                            .sort((a, b) => {
                                if (a.timestamp === b.timestamp && a.amount === b.amount) {
                                    return a.id < b.id ? -1 : 1;
                                }
                                if (a.timestamp === b.timestamp) {
                                    return a.amount < b.amount ? -1 : 1;
                                }
                                return a.timestamp < b.timestamp ? 1 : -1
                            })
                            .map((interest, idx) => <div key={idx} style={{ display: "flex", color: interestIdToBeDeleted.has(interest.id) ? "red" : "black" }}>
                                <div key={idx} style={{ display: "flex", flexDirection: "row", borderTop: idx > 0 ? "1px solid black" : "" }}>
                                    <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                        {interest.timestamp}
                                    </div>
                                    <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                        <input
                                            value={interestMap[interest.id] || 0}
                                            style={{ ...inputStyle, width: "150px", textAlign: "center", color: interestIdToBeDeleted.has(interest.id) ? "red" : "black" }}
                                            onChange={e => setInterestMap({ ...interestMap, [parseInt(interest.id)]: parseFloat(e.target.value) || 0 })}>
                                        </input>
                                    </div>
                                    <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                        {USDollar.format(interestMap[interest.id])}
                                    </div>
                                    <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                        {interest.status}
                                    </div>
                                </div>
                                {frequency.toLowerCase() === "customized" && <button style={{
                                    height: "30px",
                                    width: "30px",
                                    borderRadius: "30px",
                                    background: "rgb(17 32 86)",
                                    color: "white",
                                    cursor: "pointer",
                                    fontSize: "20px",
                                    display: "flex",
                                    alignItems: "center",
                                    placeContent: "center"
                                }}
                                    onClick={e => {
                                        if (interestIdToBeDeleted.has(interest.id)) {
                                            // remove id from delete list
                                            setInterestIdToBeDeleted(interestIdToBeDeleted => {
                                                const updatedSet = new Set(interestIdToBeDeleted)
                                                updatedSet.delete(interest.id)
                                                return updatedSet
                                            })
                                        } else {
                                            setInterestIdToBeDeleted(interestIdToBeDeleted => new Set(interestIdToBeDeleted).add(interest.id))
                                        }
                                    }}
                                >
                                    {interestIdToBeDeleted.has(interest.id) ? "√" : "X"}
                                </button>
                                }
                            </div>)}
                        {addedInterest.map((interest, idx) => <div key={idx} style={{ display: "flex" }}>
                            <div key={idx} style={{ display: "flex", flexDirection: "row", borderTop: idx === 0 && interests.length === 0 ? "" : "1px solid black" }}>
                                <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                    <input
                                        type="date"
                                        value={interest[0]}
                                        onChange={(e) => {
                                            // if (new Date(e.target.value + "T00:00:00-07:00") < new Date()) {
                                            //     alert("Please select a future date")
                                            //     return
                                            // }
                                            setAddedInterest(addedInterest.map((item, i) => (i === idx ? [e.target.value, item[1]] : item)))
                                        }}
                                    />
                                </div>
                                <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                    <input
                                        value={interest[1] || 0}
                                        style={{ ...inputStyle, width: "150px", textAlign: "center" }}
                                        onChange={e => setAddedInterest(addedInterest.map((item, i) => (i === idx ? [item[0], strToFloat(e.target.value)] : item)))}>
                                    </input>
                                </div>
                                <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                    {USDollar.format(interest[1])}
                                </div>
                                <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                    {"unpaid"}
                                </div>
                            </div>
                            {frequency.toLowerCase() === "customized" && <button style={{
                                height: "30px",
                                width: "30px",
                                borderRadius: "30px",
                                background: "rgb(17 32 86)",
                                color: "white",
                                cursor: "pointer",
                                fontSize: "20px",
                                display: "flex",
                                alignItems: "center",
                                placeContent: "center"
                            }}
                                onClick={e =>
                                    setAddedInterest(addedInterest.filter((_, i) => i !== idx))
                                }
                            >
                                {"X"}
                            </button>
                            }
                        </div>)}
                    </div>
                </div>

                {frequency.toLowerCase() === "customized" &&
                    <button style={{
                        height: "30px",
                        width: "30px",
                        borderRadius: "30px",
                        background: "rgb(17 32 86)",
                        color: "white",
                        cursor: "pointer",
                        fontSize: "30px",
                        display: "flex",
                        alignItems: "center",
                        placeContent: "center"
                    }}
                        onClick={e => {
                            setAddedInterest([...addedInterest, [getTodayDateString(), 0]])
                        }}
                    >
                        +
                    </button>
                }

                <button style={{
                    height: "30px",
                    width: saveButtonWidth,
                    borderRadius: "30px",
                    background: "rgb(17 32 86)",
                    color: "white",
                    cursor: "pointer",
                }}
                    onClick={saveInterest}>
                    {saveButtonText}
                </button>
            </div>
        }
    </>
}