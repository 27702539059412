import { AppHeader } from 'components/AppHeader';
import { CompanyInfo } from 'components/CompanyInfo';
import { useEffect, useState, useContext } from 'react';
import { message, Spin, Divider } from 'antd';
import { get } from 'utils/fetch';
import api from 'api';
import { getFileIcon, convertAndFormatDatetime, isPhoneSize } from 'utils/util';
import "./Documents.css"
import { GlobalContext } from 'GlobalContext';
import { DocumentSubView } from './DocumentSubView';

export function Documents(props) {
    const { email, basicInfo, setEmail, setBasicInfo } = props
    const { accessToken } = useContext(GlobalContext)
    const [emailAttachments, setEmailAttachments] = useState([])
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(true)
    const [personalDocuments, setPersonalDocuments] = useState([])
    const [investmentDocuments, setInvestmentDocumments] = useState([])
    const [uniqueFundNames, setUniqueFundNames] = useState([])

    const displayErrorMessage = (message) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };

    async function fetchEmailAttachments(email) {
        await get(api.email.emailAttachments(email), setEmailAttachments, (errors) => errors.forEach(err => {
            displayErrorMessage(err.message);
        }), accessToken);
    };

    async function fetchInvestmentFiles(id) {
        await get(api.investor.getInvestmentFiles(id), setInvestmentDocumments, (errors) => errors.forEach(err => {
            displayErrorMessage(err.message);
        }), accessToken);
        setLoading(false)
    };

    async function fetchPersonalDocuments(id) {
        await get(api.investor.getPersonalDocuments(id), setPersonalDocuments, (errors) => errors.forEach(err => {
            displayErrorMessage(err.message);
        }), accessToken);
    };

    async function fetchDocumentsByInvestorId(id) {
        await get(api.investor.getDocumentsByInvestorId(id), setInvestmentDocumments, (errors) => errors.forEach(err => {
            displayErrorMessage(err.message);
        }), accessToken);
        setLoading(false)
    };

    const entryStyle = {
        margin: "20px",
        fontSize: '20px',
    }

    const dividerStyle = {
        margin: "0px"
    }

    useEffect(() => {
        var uniqueFundNames = new Set()
        for (const document of investmentDocuments) {
            uniqueFundNames.add(document.name)
        }
        setUniqueFundNames(Array.from(uniqueFundNames))
    }, [investmentDocuments])

    // console.log(basicInfo)
    // console.log(email)
    async function fetchDocuments(id) {
        setLoading(true)
        // console.log("fetchEmailAttachments")
        // await fetchEmailAttachments(basicInfo.email)
        // console.log("fetchInvestmentFiles")
        // fetchInvestmentFiles(id)
        fetchPersonalDocuments(id)
        fetchDocumentsByInvestorId(id)
    }
    useEffect(() => {
        if (accessToken === "" || Object.keys(basicInfo).length === 0) {
            return
        }
        fetchDocuments(basicInfo.id)
    }, [basicInfo, accessToken])
    // console.log(investmentDocuments)
    const content = loading ? <div style={{ width: "100%", marginTop: "20px", textAlign: "center" }}>
        <Spin />
    </div>
        :
        <div style={{ overflowY: "scroll", scrollbarWidth: "none", height: "calc(100vh - 80px)" }}>
            <div style={{ ...entryStyle, height: "calc(100vh - 200px)" }}>
                <DocumentSubView titleName={"Personal Documents"} files={personalDocuments} />
                {investmentDocuments.filter((document) => document.is_attachment).length > 0 &&
                    <DocumentSubView titleName={"Attachment"} files={investmentDocuments
                        .filter(document => document.is_attachment)} />}
                {uniqueFundNames.map((fundName, id) => {
                    return <DocumentSubView key={id} titleName={fundName} files={investmentDocuments
                        .filter(document => document.name === fundName)} />
                })}
                {isPhoneSize() && <div style={{
                    fontSize: "12px",
                    textAlign: "left",
                    marginTop: "20px"
                }}>

                    <CompanyInfo />
                </div>}
                {isPhoneSize() && <div style={{
                    display: "flex",
                    height: "50px"
                }}>
                </div>}
            </div>
        </div>
    return <>
        {!isPhoneSize() && <div style={{ display: "flex", flexDirection: "row" }}>

            <div style={{ width: "100%" }}>
                <AppHeader setBasicInfo={setBasicInfo} basicInfo={basicInfo} setEmail={setEmail} />
                {content}
            </div>
            <div style={{
                width: '380px',
                textAlign: "left",
                background: 'transparent',
                padding: 12,
                overflowY: 'scroll',
                overflowX: "hidden",
                height: "100vh",
                scrollbarWidth: "none",
                // height: 'calc(100vh - 144px)',
                height: "100vh"
            }}>

                <CompanyInfo />
            </div>
        </div >}
        {isPhoneSize() && <div style={{ display: "flex", flexDirection: "column" }}>

            <div style={{ width: "100%", }}>
                {content}
            </div>

        </div >}
    </>
}