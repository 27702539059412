import api from "api";
import { get } from "utils/fetch";
import { getMimeType, getFileIcon, stringifyDict, isPhoneSize } from "utils/util";
import { GlobalContext } from "GlobalContext";
import { useEffect, useState, useContext, useRef } from "react"
import { Select, Space, message, Spin, Switch } from "antd"
import { FundPhotoGallery } from "components/FundPhotoGallery";
import { DeleteDocumentComfirmation } from "ConfirmationPages/DeleteDocumentComfirmation";

export function Documents() {
    const { manageRecordId, accessToken } = useContext(GlobalContext)
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(true)
    const [documentSources, setDocumentSources] = useState([])
    const [uploading, setUploading] = useState(false);
    const [selectedDocuments, setSelectedDocuments] = useState([])
    const [documentURLToDelete, setDocumentURLToDelete] = useState("")
    const [documentIdToDelete, setDocumentIdToDelete] = useState(0)

    const displayErrorMessage = (message) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };

    async function fetchDocumentSources(id) {
        await get(api.offering.getDocumentSources(id), setDocumentSources, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            }), accessToken
        );
        setLoading(false);
    }

    const handleDocumentChange = (e) => {
        var newFiles = []
        for (var i = 0; i < e.target.files.length; i++) {
            const curFile = e.target.files[i]
            if (curFile) {
                // Generate a temporary URL for the file
                const tempUrl = URL.createObjectURL(curFile);
                newFiles.push([{
                    filename: curFile.name,
                    url: tempUrl,
                    blob: curFile
                }, ""]); // Allow multiple files to be uploaded
            }
        }
        setSelectedDocuments(newFiles)
    };

    useEffect(() => {
        // if (accessToken === "") return;
        fetchDocumentSources(manageRecordId);
    }, [accessToken]);

    console.log(documentSources)

    const handleUpload = async () => {
        if (selectedDocuments.length === 0) {
            alert("Please select files first!");
            return;
        }

        setLoading(true)
        const formData = new FormData();

        selectedDocuments.map(file => formData.append('documents', new File([file[0].blob], file[0].filename, { type: getMimeType(file[0].filename) })))

        formData.append("fundId", manageRecordId);

        try {
            const response = await fetch(api.offering.uploadDocuments(manageRecordId), {
                method: "POST",
                body: formData,
            });

            if (!response.ok) {
                throw new Error("Upload failed");
            }

            const data = await response.json();
            // setUploadedImages(data.uploaded_files);
        } catch (error) {
            console.error("Error uploading files:", error);
        }
        setSelectedDocuments([]);
        fetchDocumentSources(manageRecordId)
    };

    async function deletionCallback() {
        setLoading(true)
        const body = JSON.stringify({
            documentURLToDelete
        })

        const options = {
            method: "DELETE", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${accessToken}`,
            },
            body
        }
        const result = await fetch(api.offering.deleteDocument(documentIdToDelete), options);
        const data = await result.json()
        setDocumentURLToDelete("")
        fetchDocumentSources(manageRecordId)
    }

    return <div style={{ justifyItems: "center" }}>
        <div style={isPhoneSize() ? {
            width: window.innerWidth - 20,
        } : {
            width: "560px",
        }}>
            <h1>
                Documents
            </h1>

            {loading && <div style={{ marginTop: "30px", textAlign: "center" }}><Spin /></div>}

            {!loading && <>
                {documentURLToDelete.length > 0 && <>
                    <DeleteDocumentComfirmation documentURLToDelete={documentURLToDelete} setDocumentURLToDelete={setDocumentURLToDelete} deletionCallback={deletionCallback} />
                </>}

                {/* Hidden File Input */}
                <input
                    type="file"
                    multiple
                    onChange={handleDocumentChange}
                    style={{ display: "none" }}
                    id="document-upload-input"
                />

                <div style={{ display: "flex" }}>
                    <div style={{ maxWidth: "700px", display: "flex", flexDirection: "column" }}>
                        {documentSources.map((file, id) => {
                            return <div key={id} style={{ display: "flex", flexDirection: "row" }}>
                                <a href={file.url} key={id} target="_blank" rel="noreferrer noopener">
                                    <div key={id} style={{ display: "flex", alignItems: "center", fontSize: "15px", color: "black" }}>
                                        <div style={{ padding: "5px", paddingBottom: "0" }}>
                                            {getFileIcon(file.url)}
                                        </div>
                                        {file.url.substring(file.url.lastIndexOf('/') + 1)}
                                    </div>
                                </a>

                                <span style={{
                                    marginLeft: "10px",
                                    alignContent: "center",
                                    fontWeight: "bold",
                                    color: "#FF0000",
                                    fontSize: "15px",
                                    cursor: "pointer"
                                }} className="remove-btn" onClick={() => {
                                    setDocumentURLToDelete(file.url)
                                    setDocumentIdToDelete(file.id)
                                }}>
                                    &times;
                                </span>
                            </div>
                        })}
                    </div>
                </div>

                {/* Image Previews */}
                {selectedDocuments && <div>
                    {selectedDocuments.map((file, idx) =>
                        <div key={idx} style={{ display: "flex", flexDirection: "row" }}>
                            <a href={file[0].url} target="_blank" rel="noreferrer noopener">
                                {/* <iframe src={pdfUrl} width="100%" height="600px" title="PDF Preview" /> */}
                                <div style={{ display: "flex", alignItems: "center", fontSize: "15px", color: "black" }}>
                                    <div style={{ padding: "5px", paddingBottom: "0" }}>
                                        {getFileIcon(file[0].filename)}
                                        {/* <img src={PdfIcon} style={{ width: "14px" }}></img> */}
                                    </div>
                                    <span style={{color: "#a9a9a9"}}>
                                        {file[0].filename}
                                    </span>
                                </div>
                            </a>

                            <span style={{
                                marginLeft: "10px",
                                alignContent: "center",
                                fontWeight: "bold",
                                color: "#FF0000",
                                fontSize: "15px",
                                cursor: "pointer"
                            }} className="remove-btn" onClick={() => setSelectedDocuments(selectedDocuments.filter((_, i) => i !== idx))}>
                                &times;
                            </span>
                        </div>
                    )
                    }
                </div>}

                {/* Select Files Button */}
                <label htmlFor="document-upload-input">
                    <button
                        style={{
                            height: "30px",
                            width: "140px",
                            borderRadius: "30px",
                            background: "rgb(17 32 86)",
                            color: "white",
                            cursor: "pointer",
                            marginTop: "20px"
                        }}
                        onClick={() => document.getElementById("document-upload-input").click()}
                    >
                        Select Documents
                    </button>
                </label>

                {/* Upload Button (only shown when files are selected) */}
                {selectedDocuments.length > 0 && (
                    <button
                        onClick={handleUpload}
                        disabled={uploading}
                        style={{
                            height: "30px",
                            width: "120px",
                            borderRadius: "30px",
                            background: uploading ? "gray" : "green",
                            color: "white",
                            cursor: uploading ? "not-allowed" : "pointer",
                            marginLeft: "10px",
                            marginTop: "20px"
                        }}
                    >
                        {uploading ? "Uploading..." : "Upload"}
                    </button>
                )}

            </>}
        </div>
    </div>
}