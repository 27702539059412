import { isAdmin } from "utils/util"
import { RecordManagerTab } from "./RecordManagerTab"
import { useEffect, useState, useContext } from "react";
import { InvestorManager } from "./InvestorManager";
import { PropertyManager } from "./PropertyManager";
import { HoldingCompanyManager } from "./HoldingCompanyManager";
import { FundManager } from "./FundManager";
import { InvestmentManager } from "./InvestmentManager";
import { isPhoneSize } from "utils/util";
import { AccountManager } from "./AccountManager";
import { GlobalContext } from 'GlobalContext';
import { PortfolioSumary } from "pages/PortfolioSumary/PortfolioSumary";

export function RecordManager(props) {
    const { manageRecordTab, setManageRecordTab } = useContext(GlobalContext)

    // const [tabSelection, setTabSelection] = useState("Investors")

    const content = <div style={isPhoneSize() ? {
        height: "calc(100vh - 230px)",
    } : {
        height: "100vh",
    }}>
        <div style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            // overflowX: "scroll",
            overflowY: "scroll",
            scrollbarWidth: "none"
        }}>
            <RecordManagerTab text={"Investors"} tabSelection={manageRecordTab} setTabSelection={setManageRecordTab} />
            <RecordManagerTab text={"Accounts"} tabSelection={manageRecordTab} setTabSelection={setManageRecordTab} />
            <RecordManagerTab text={"Positions"} tabSelection={manageRecordTab} setTabSelection={setManageRecordTab} />
            <RecordManagerTab text={"Entities/Funds"} tabSelection={manageRecordTab} setTabSelection={setManageRecordTab} />
            <RecordManagerTab text={"Holding Companies"} tabSelection={manageRecordTab} setTabSelection={setManageRecordTab} />
            <RecordManagerTab text={"Assets"} tabSelection={manageRecordTab} setTabSelection={setManageRecordTab} />
            <RecordManagerTab text={"Portfolio Summary"} tabSelection={manageRecordTab} setTabSelection={setManageRecordTab} />
        </div>
        <div style={isPhoneSize() ? {
            height: "calc(100vh - 310px)", overflowY: "scroll", scrollbarWidth: "none", overflowX: "hidden"
        } : {
            height: "calc(100vh - 50px)", overflowY: "scroll", scrollbarWidth: "none", overflowX: "hidden"
        }}>
            <div style={{}}>
                {manageRecordTab === "Portfolio Summary" && <PortfolioSumary />}
                {manageRecordTab === "Investors" && <InvestorManager />}
                {manageRecordTab === "Accounts" && <AccountManager />}
                {manageRecordTab === "Positions" && <InvestmentManager />}
                {manageRecordTab === "Entities/Funds" && <FundManager />}
                {manageRecordTab === "Holding Companies" && <HoldingCompanyManager />}
                {manageRecordTab === "Assets" && <PropertyManager />}
                {/* <div style={{ height: "150px" }}></div> */}
            </div>
        </div>
        {/* 
        
         */}
    </div>

    return <>
        {!isAdmin() && <>Not authorized</>}
        {isAdmin() && content}
    </>
}