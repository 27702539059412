import headerPerson from "../assets/img/headerPerson.png"
import { Divider, Select, message } from "antd"
import { isPhoneSize, isAdmin, isSale } from "utils/util"
import { useState, useEffect, useContext } from "react"
import api from 'api';
import { get, post } from 'utils/fetch';
import { GlobalContext } from 'GlobalContext';

export function AppHeader(props) {
    const { basicInfo, setEmail, setBasicInfo } = props
    const { globalEmail, investorIdToView, setInvestorIdToView, accessToken, setGlobalId } = useContext(GlobalContext)
    const [allUsers, setAllUsers] = useState([])
    const [messageApi, contextHolder] = message.useMessage();

    const displayErrorMessage = (message) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };
    // console.log(allUsers)
    async function changeBasicInfo(id) {
        await get(
            api.investor.getBasicInfoById(id),
            setBasicInfo,
            (errors) => errors.forEach(err => {
                displayErrorMessage(err.message);
            }), accessToken
        );
    }

    useEffect(() => {
        if (accessToken === "") return
        async function getAllUsers() {
            await get(
                api.investor.list,
                setAllUsers,
                (errors) => errors.forEach(err => {
                    displayErrorMessage(err.message);
                }), accessToken
            );
        }

        async function getAllCustomers(id) {
            await get(
                api.investor.customerList(id),
                setAllUsers,
                (errors) => errors.forEach(err => {
                    displayErrorMessage(err.message);
                }), accessToken
            );
        }

        // get all users with same email as the one used for login
        async function getAllUsersInvestor(globalEmail) {
            await get(
                api.investor.listByRole(globalEmail, "investor"),
                setAllUsers,
                (errors) => errors.forEach(err => {
                    displayErrorMessage(err.message);
                }), accessToken
            );
        }

        if (isAdmin()) {
            getAllUsers()
            return
        }
        if (isSale()) {
            getAllCustomers(basicInfo.id)
            return
        }

        if (globalEmail === "") return
        if (!isAdmin()) {
            getAllUsersInvestor(globalEmail)
            return
        }
    }, [globalEmail, accessToken])

    // console.log("all users", allUsers)
    const mainContent = (
        <div style={{ background: "transparent", width: "100%", display: "flex", alignItems: "center", textAlign: "left" }}>
            {/* <div>
                <img style={isPhoneSize() ? {
                    width: window.innerWidth / 2, marginBottom: "0"
                } : {
                    height: "100px", margin: "20px", marginBottom: "0"
                }} src={headerPerson}></img>
            </div> */}
            <div>
                {basicInfo.legal_name &&
                    <div style={{ marginTop: isAdmin() ? "" : "20px", fontSize: isPhoneSize() ? "20px" : "30px" }}>
                        {allUsers.length > 1 &&
                            <div>
                                <Select
                                    showSearch
                                    optionFilterProp="label"
                                    value={investorIdToView === -1 ? [] : [investorIdToView]}
                                    onChange={(e) => {
                                        setInvestorIdToView(e)
                                        changeBasicInfo(e)
                                    }}
                                    placeholder="Choose user"
                                    style={{ width: '150px', background: "transparent" }}
                                    filterOption={(input, option) =>
                                        option?.label.toLowerCase().includes(input.toLowerCase())
                                    }
                                    // optionFilterProp="children"
                                    options={
                                        allUsers.map((user) => {
                                            return {
                                                value: user.id,
                                                label: `${user.legal_name} (${user.email})`
                                            }
                                        })
                                    }
                                >
                                </Select>
                            </div>
                        }
                        {/* <span style={{ color: "rgb(17 32 86)" }}>
                            Welcome Back,
                        </span>
                        <br></br>
                        <span style={{ color: "rgb(73 69 255)" }}>
                            {basicInfo.legal_name}
                        </span> */}
                    </div>
                }
            </div>
        </div>
    )

    return <>
        {isPhoneSize() && <div>
            <div style={{ display: "flex", flexDirection: "column" }}>
                {mainContent}
                {/* <Divider style={{ margin: "0" }} /> */}
            </div>
        </div>}
        {!isPhoneSize() && <div>
            {mainContent}
            {/* <Divider style={{ margin: "0" }} /> */}
        </div>}
    </>
}