import headerPerson from "../assets/img/headerPerson.png"
import { Divider, Select, message } from "antd"
import { isPhoneSize, isAdmin } from "utils/util"
import { useState, useEffect } from "react"
import api from 'api';
import { get, post } from 'utils/fetch';

export function AppHeader(props) {
    const { basicInfo, setEmail } = props

    const [allUsers, setAllUsers] = useState([])
    const [messageApi, contextHolder] = message.useMessage();

    const displayErrorMessage = (message) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };

    useEffect(() => {
        async function getAllUsers() {
            await get(
                api.investor.list,
                setAllUsers,
                (errors) => errors.forEach(err => {
                    displayErrorMessage(err.message);
                })
            );
        }
        if (!isAdmin()) return
        getAllUsers()
    }, [])

    //console.log("all users", allUsers)
    const mainContent = (
        <div style={{ background: "transparent", width: "100%", display: "flex", alignItems: "center", textAlign: "left" }}>
            <div>
                <img style={isPhoneSize() ? {
                    width: window.innerWidth / 2, marginBottom: "0"
                } : {
                    height: "100px", margin: "20px", marginBottom: "0"
                }} src={headerPerson}></img>
            </div>
            <div>
                {basicInfo.legal_name &&
                    <div style={{ marginTop: isAdmin() ? "" : "20px", fontSize: isPhoneSize() ? "20px" : "30px" }}>
                        {isAdmin() &&
                            <div>
                                <Select
                                    showSearch
                                    optionFilterProp="label"
                                    onChange={(e) => setEmail(e)}
                                    placeholder="Choose user"
                                    style={{ width: '150px', background: "transparent" }}
                                    filterOption={(input, option) =>
                                        option?.label.toLowerCase().includes(input.toLowerCase())
                                    }
                                    // optionFilterProp="children"
                                    options={
                                        allUsers.map((user) => {
                                            return {
                                                value: user.email,
                                                label: `${user.legal_name} (${user.email})`
                                            }
                                        })
                                    }
                                >
                                </Select>
                            </div>
                        }
                        <span style={{ color: "rgb(17 32 86)" }}>
                            Welcome Back,
                        </span>
                        <br></br>
                        <span style={{ color: "rgb(73 69 255)" }}>
                            {basicInfo.legal_name}
                        </span>
                    </div>
                }
            </div>
        </div>
    )

    return <>
        {isPhoneSize() && <div>
            <div style={{ display: "flex", flexDirection: "column" }}>
                {mainContent}
                <Divider style={{ margin: "0" }} />
            </div>
        </div>}
        {!isPhoneSize() && <div>
            {mainContent}
            <Divider style={{ margin: "0" }} />
        </div>}
    </>
}