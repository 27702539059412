// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
body,
#root {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

p {
  margin: 8px auto;
}

.ant-menu-item-divider {
  margin: 10px 5px !important;
}

input[type="file"]::file-selector-button {
  height: 30px;
  width: 90px;
  border-radius: 30px;
  background: rgb(17 32 86);
  color: white;
  cursor: pointer;
}

.ant-spin-nested-loading.css-dev-only-do-not-override-15cx8ur {
  z-index: 0 !important;
}

canvas {
  touch-action: pan-y !important;
}

.ant-collapse-header-text {
  font-size: 20px;
  color: #121026;
  font-family: Arial;
  font-size: 20px;
  font-weight: bold;
  line-height: 32px;
  letter-spacing: 0em;
}

.ant-collapse-header {
  align-items: center !important;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;;AAEA;;;EAGE,WAAW;EACX,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,mBAAmB;EACnB,yBAAyB;EACzB,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,eAAe;EACf,cAAc;EACd,kBAAkB;EAClB,eAAe;EACf,iBAAiB;EACjB,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,8BAA8B;AAChC","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\nhtml,\nbody,\n#root {\n  width: 100%;\n  height: 100vh;\n  overflow: hidden;\n}\n\np {\n  margin: 8px auto;\n}\n\n.ant-menu-item-divider {\n  margin: 10px 5px !important;\n}\n\ninput[type=\"file\"]::file-selector-button {\n  height: 30px;\n  width: 90px;\n  border-radius: 30px;\n  background: rgb(17 32 86);\n  color: white;\n  cursor: pointer;\n}\n\n.ant-spin-nested-loading.css-dev-only-do-not-override-15cx8ur {\n  z-index: 0 !important;\n}\n\ncanvas {\n  touch-action: pan-y !important;\n}\n\n.ant-collapse-header-text {\n  font-size: 20px;\n  color: #121026;\n  font-family: Arial;\n  font-size: 20px;\n  font-weight: bold;\n  line-height: 32px;\n  letter-spacing: 0em;\n}\n\n.ant-collapse-header {\n  align-items: center !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
