import backArrow from "../../assets/img/backArrow.png"
import { Spin, message } from "antd"
import { useState, useEffect, useContext } from "react"
import { get } from "utils/fetch"
import api from "api"
import { USDollar } from "utils/format"
import { getTodayDateString, strToFloat, getTomorrowDateString } from "utils/util"
import { GlobalContext } from 'GlobalContext';
import { PrincipalUpdateConfirmation } from "components/PrincipalUpdateConfirmation"

export function PrincipalManager(props) {
    const { setView, investmentId, startDate, endDate, frequency, method, interest, amount } = props
    const { accessToken } = useContext(GlobalContext)
    // previously taken out
    const [principalTakenOut, setPrincipalTakenOut] = useState([])
    // will be taken out in current session
    const [principalTakeOut, setPrincipalTakeOut] = useState([])
    const [principalLeft, setPrincipalLeft] = useState([])
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(true)
    const [principalTakeoutIdToBeDeleted, setPrincipalTakeoutIdToBeDeleted] = useState(new Set())
    const [saveButtonText, setSaveButtonText] = useState("Save")
    const [saveButtonWidth, setSaveButtonWidth] = useState("50px")
    // id -> amount map
    const [principalTakeOutMap, setPrincipalTakeOutMap] = useState({})
    const [showUpdateBox, setShowUpdateBox] = useState(false)
    const [paidInterestAffected, setPaidInterestAffected] = useState([])

    const displayErrorMessage = (message) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };

    const entryStyle = {
        margin: "20px",
        fontSize: '20px'
    }

    const inputStyle = {
        border: "0",
        outline: "none",
        borderBottom: "1px solid black",
        fontSize: '20px',
        background: "transparent"
    }

    async function fetchPrincipalTakeout(id) {
        // fetch principal take out entry stored in database
        await get(api.manager.investmentPrincipalTakeOut(id), setPrincipalTakenOut, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            }), accessToken
        )
        // setLoading(false)
    }

    async function fetchPrincipalLeft(id) {
        // fetch principal take out entry stored in database
        await get(api.manager.investmentPrincipalLeft(id), setPrincipalLeft, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            }), accessToken
        )
        setLoading(false)
    }

    useEffect(() => {
        var principalTakeOutMap = {}
        for (const entry of principalTakenOut) {
            principalTakeOutMap[entry.id] = entry.amount
        }
        setPrincipalTakeOutMap(principalTakeOutMap)
        // console.log(principalTakeOutMap)
    }, [principalTakenOut])

    // console.log(principalTakeOutMap)
    useEffect(() => {
        fetchPrincipalTakeout(investmentId)
        fetchPrincipalLeft(investmentId)
    }, [investmentId])

    function addPrincipalTakeOutEntry() {
        setPrincipalTakeOut([...principalTakeOut, [getTomorrowDateString(), ""]])
    }

    // get the earlist date of change, deleted (on taken out) or added (take out) principal take out
    function getEarlistChangedDate() {
        var earlistDay = ""
        for (const entry of principalTakenOut) {
            const id = entry.id
            if (principalTakeoutIdToBeDeleted.has(id) || entry.amount !== principalTakeOutMap[id]) {
                const date = entry.timestamp
                if (earlistDay === "" || earlistDay > date) {
                    earlistDay = date
                }
            }
        }

        for (const entry of principalTakeOut) {
            if (entry[1] === "") continue
            const date = entry[0]
            if (earlistDay === "" || earlistDay > date) {
                earlistDay = date
            }
        }
        return earlistDay
    }
    // console.log(principalTakeOutMap)
    async function savePrincipalTakeoutCallback() {
        setLoading(true)
        setSaveButtonText("Saving")
        setSaveButtonWidth("80px")

        // task for front end:
        // exclude deleted and empty entries from "taken out" and empty amount from "take out"
        // aggregrate "taken out" and "take out" and get date -> amount map
        var date2amountMap = {}
        for (const entry of principalTakenOut) {
            if (principalTakeoutIdToBeDeleted.has(entry.id) || parseFloat(principalTakeOutMap[entry.id]) === 0) continue
            const date = entry.timestamp
            const amount = parseFloat(principalTakeOutMap[entry.id])
            if (!(date in date2amountMap)) {
                date2amountMap[date] = 0
            }
            date2amountMap[date] += amount
        }
        for (const entry of principalTakeOut) {
            if (entry[1] === "" || parseFloat(entry[1]) === 0) continue
            const date = entry[0]
            const amount = parseFloat(entry[1])
            if (!(date in date2amountMap)) {
                date2amountMap[date] = 0
            }
            date2amountMap[date] += amount
        }

        // console.log(date2amountMap)
        const body = JSON.stringify({
            startDate,
            endDate,
            frequency,
            method,
            interest,
            principalTakenOut: date2amountMap,
            amount,
            principalTakeoutIdToBeDeleted: Array.from(principalTakeoutIdToBeDeleted),
            earlistDate: getEarlistChangedDate(),
            principalTakeOutMap,
            principalTakeOut
        })

        const options = {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${accessToken}`,
            },
            body
        }
        await fetch(api.manager.updatePrincipalTakeout(investmentId), options); // new record
        fetchPrincipalTakeout(investmentId)
        fetchPrincipalLeft(investmentId)


        // will be taken out in current session
        setPrincipalTakeOut([])
        setPrincipalTakeoutIdToBeDeleted(new Set())
        setPaidInterestAffected([])

        setSaveButtonText("Save")
        setSaveButtonWidth("50px")
        setShowUpdateBox(false)
    }

    async function savePrincipalTakeOut() {
        if (saveButtonText === "Saving") {
            return
        }
        setPrincipalTakeOut(principalTakeOut.filter(entry => (entry[1] !== "" && parseFloat(entry[1]) !== 0)))
        const earlistDate = getEarlistChangedDate()
        if (earlistDate === "") return
        console.log(earlistDate)
        const response = await fetch(api.manager.paidInterestsSince(investmentId, earlistDate), {
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${accessToken}`,
            },
        });
        const data = (await response.json()).data
        console.log(data)
        if (data.length === 0) {
            savePrincipalTakeoutCallback()
        } else {
            setPaidInterestAffected(data)
            setShowUpdateBox(true)
        }
    }

    return <>
        <div>
            <div style={{ margin: "20px" }}>
                <img style={{ width: "15px", height: "20px", cursor: "pointer" }}
                    src={backArrow}
                    onClick={() => setView("investment")}
                ></img>
            </div>
        </div>
        {loading && <div style={{ marginTop: "30px", textAlign: "center" }}><Spin /></div>}

        {!loading &&
            <>
                {showUpdateBox &&
                    <PrincipalUpdateConfirmation
                        setShowUpdateBox={setShowUpdateBox}
                        updateCallback={savePrincipalTakeoutCallback}
                        paidInterestAffected={paidInterestAffected}
                    />
                }
                <div style={{ ...entryStyle, marginBottom: "0" }}>Principal Taken Out during Investment</div>
                <div style={{ ...entryStyle, marginTop: "0" }}>
                    {principalTakenOut
                        .sort((a, b) => {
                            if (a.timestamp < b.timestamp) {
                                return -1
                            }
                            return 1
                        })
                        .map((principalTakenOutEntry, idx) => {
                            return <div key={idx} style={{ display: "flex", flexDirection: "row", color: principalTakeoutIdToBeDeleted.has(principalTakenOutEntry.id) ? "red" : "black" }}>
                                <div style={{}}>
                                    {principalTakenOutEntry.timestamp}
                                </div>

                                {principalTakenOutEntry.status.toLowerCase() === "unpaid" && <div style={{ width: "100px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                    <input
                                        value={principalTakeOutMap[principalTakenOutEntry.id] || ""}
                                        style={{ ...inputStyle, width: "100px", textAlign: "center", color: principalTakeoutIdToBeDeleted.has(principalTakenOutEntry.id) ? "red" : "black" }}
                                        onChange={e => setPrincipalTakeOutMap({ ...principalTakeOutMap, [parseInt(principalTakenOutEntry.id)]: e.target.value || 0 })}>
                                    </input>
                                </div>}

                                {principalTakenOutEntry.status.toLowerCase() === "paid" && <div style={{ width: "100px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                    <div style={{ width: "100px" }}>
                                    </div>
                                </div>}

                                <div style={{ width: "100px", margin: "0 20px", display: "flex", justifyContent: "center" }}>
                                    {USDollar.format(principalTakeOutMap[principalTakenOutEntry.id])}
                                </div>

                                <div style={{}}>
                                    {principalTakenOutEntry.status[0].toUpperCase() + principalTakenOutEntry.status.slice(1)}
                                </div>

                                {principalTakenOutEntry.status.toLowerCase() === "unpaid" &&
                                    <button style={{
                                        height: "30px",
                                        width: "30px",
                                        borderRadius: "30px",
                                        background: "rgb(17 32 86)",
                                        color: "white",
                                        cursor: "pointer",
                                        fontSize: "20px",
                                        display: "flex",
                                        alignItems: "center",
                                        placeContent: "center"
                                    }}
                                        onClick={e => {
                                            if (principalTakeoutIdToBeDeleted.has(principalTakenOutEntry.id)) {
                                                // remove id from delete list
                                                setPrincipalTakeoutIdToBeDeleted(principalTakeoutIdToBeDeleted => {
                                                    const updatedSet = new Set(principalTakeoutIdToBeDeleted)
                                                    updatedSet.delete(principalTakenOutEntry.id)
                                                    return updatedSet
                                                })
                                            } else {
                                                setPrincipalTakeoutIdToBeDeleted(principalTakeoutIdToBeDeleted => new Set(principalTakeoutIdToBeDeleted).add(principalTakenOutEntry.id))
                                            }
                                        }}
                                    >
                                        {principalTakeoutIdToBeDeleted.has(principalTakenOutEntry.id) ? "√" : "X"}
                                    </button>
                                }
                            </div>
                        })}
                    <div>
                        {principalTakeOut.map((principalTakeOutEntry, idx) => {
                            return <div key={idx} style={{ display: "flex" }}>
                                <div>
                                    <input
                                        type="date"
                                        value={principalTakeOutEntry[0]}
                                        onChange={(e) => {
                                            // if (new Date(e.target.value + "T00:00:00-07:00") < new Date()) {
                                            //     alert("Please select a future date")
                                            //     return
                                            // }
                                            setPrincipalTakeOut([
                                                ...principalTakeOut.slice(0, idx),
                                                [e.target.value, principalTakeOutEntry[1]],
                                                ...principalTakeOut.slice(idx + 1),
                                            ])
                                        }}
                                    />

                                    <input
                                        value={principalTakeOutEntry[1]}
                                        style={inputStyle}
                                        onChange={(e) => {
                                            const val = e.target.value.trim()
                                            setPrincipalTakeOut([
                                                ...principalTakeOut.slice(0, idx),
                                                [principalTakeOutEntry[0], val],
                                                ...principalTakeOut.slice(idx + 1),
                                            ])
                                        }}
                                    ></input>

                                    {USDollar.format(principalTakeOutEntry[1] || 0)}
                                </div>

                                <button style={{
                                    height: "30px",
                                    width: "30px",
                                    borderRadius: "30px",
                                    background: "rgb(17 32 86)",
                                    color: "white",
                                    cursor: "pointer",
                                    fontSize: "20px",
                                    display: "flex",
                                    alignItems: "center",
                                    placeContent: "center"
                                }}
                                    onClick={e =>
                                        setPrincipalTakeOut(principalTakeOut.filter((_, i) => i !== idx))
                                    }
                                >
                                    {"X"}
                                </button>
                            </div>

                        })}
                    </div>
                    <div style={{}}>
                        <button style={{
                            height: "30px",
                            width: "30px",
                            borderRadius: "30px",
                            background: "rgb(17 32 86)",
                            color: "white",
                            cursor: "pointer",
                            fontSize: "30px",
                            display: "flex",
                            alignItems: "center",
                            placeContent: "center"
                        }}
                            onClick={addPrincipalTakeOutEntry}
                        >
                            +
                        </button>
                    </div>

                    <div style={{ marginTop: "20px" }}>

                        Principal Left
                        {principalLeft.map((principal, idx) => <div key={idx} style={{ display: "flex" }}>
                            <div style={{}}>
                                {principal.timestamp}
                            </div>

                            <div style={{ width: "100px", margin: "0 20px", display: "flex", justifyContent: "center" }}>
                                {USDollar.format(principal.amount)}
                            </div>

                            <div style={{}}>
                                {principal.status[0].toUpperCase() + principal.status.slice(1)}
                            </div>
                        </div>)}
                    </div>
                </div>



                <div style={{ margin: "20px" }}>
                    <button style={{
                        height: "30px",
                        width: saveButtonWidth,
                        borderRadius: "30px",
                        background: "rgb(17 32 86)",
                        color: "white",
                        cursor: "pointer",
                    }}
                        onClick={savePrincipalTakeOut}>
                        {saveButtonText}
                    </button>
                </div>
            </>
        }
    </>
}