import api from "api";
import { get } from "utils/fetch";
import { useState, useEffect, useContext } from "react";
import { message, Spin, Input } from "antd";
import { PropertyInsertion } from "pages/RecordInsertion/PropertyInsertion";
import { isPhoneSize } from "utils/util";
import { GlobalContext } from 'GlobalContext';

export function PropertyManager() {

    const { setManageRecordId, manageRecordId, accessToken } = useContext(GlobalContext)
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(true)
    const [propertyId, setPropertyId] = useState(0)
    const [properties, setProperties] = useState([])
    const [filteredText, setFilteredText] = useState("")

    const displayErrorMessage = (message) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };

    async function fetchProperties() {
        await get(api.manager.properties, setProperties, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            }), accessToken
        )
        setLoading(false)
    }

    useEffect(() => {
        if (manageRecordId !== 0) {
            setLoading(false)
        }
        //console.log(propertyId)
        setFilteredText("")
        if (manageRecordId !== 0) return
        fetchProperties()
    }, [manageRecordId])

    return <>
        {loading && <div style={{ marginTop: "30px", textAlign: "center" }}><Spin /></div>}
        {!loading && manageRecordId === 0 && <>
            <div style={{ width: "300px", marginTop: "30px", marginLeft: "20px" }}>
                <Input
                    onChange={e => setFilteredText(e.target.value.trim().toLowerCase())}
                    placeholder="Filter on address"
                />
            </div>

            <div style={{ margin: "20px" }}>
                <button style={{
                    height: "30px",
                    width: "50px",
                    borderRadius: "30px",
                    background: "rgb(17 32 86)",
                    color: "white",
                    cursor: "pointer",
                }}
                    onClick={() => setManageRecordId(-1)}>
                    Add
                </button>
            </div>

            {properties
                .filter(property => {
                    if (property.address2 !== null && property.address2.trim().length > 0) {
                        return `${property.address} ${property.address2}, ${property.city}, ${property.state} ${property.zip}`
                            .toLowerCase()
                            .includes(filteredText)
                    } else {
                        return `${property.address}, ${property.city}, ${property.state} ${property.zip}`
                            .toLowerCase()
                            .includes(filteredText)
                    }
                })
                .sort((a, b) => {
                    if (a.address < b.address) {
                        return -1
                    }
                    return 1
                })
                .map((property, idx) => {
                    return <div key={idx} style={isPhoneSize() ? {
                        fontSize: "20px",
                        margin: "20px",
                        width: "fit-content",
                        color: "blue",
                        textDecoration: "underline",
                        cursor: "pointer",
                    } : {
                        fontSize: "20px",
                        margin: "20px",
                        width: "fit-content",
                        color: "blue",
                        borderBottom: "1px solid blue",
                        cursor: "pointer",
                    }}
                        onClick={() => setManageRecordId(property.id)}
                    >
                        {property.address2 !== null && property.address2.trim().length > 0 &&
                            `${property.address} ${property.address2}, ${property.city}, ${property.state} ${property.zip}`}
                        {(property.address2 === null || property.address2.trim().length <= 0) &&
                            `${property.address}, ${property.city}, ${property.state} ${property.zip}`}
                    </div>
                })}

        </>}
        {!loading && manageRecordId > 0 && <>
            <PropertyInsertion
            // property={properties.filter(property => property.id === propertyId)[0]}
            // setPropertyId={setPropertyId}
            />
        </>}
        {!loading && manageRecordId < 0 && <>
            <PropertyInsertion
            // property={null}
            // setPropertyId={setPropertyId}
            />
        </>}
    </>
}