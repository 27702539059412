import api from "api";
import { get } from "utils/fetch";
import { isPhoneSize } from "utils/util";
import { GlobalContext } from "GlobalContext";
import { useEffect, useState, useContext } from "react"
import { Select, Space, message, Spin, Switch } from "antd"

export function CoverImage() {
    const { manageRecordId, accessToken } = useContext(GlobalContext)
    const [messageApi, contextHolder] = message.useMessage();
    const [imageSrc, setImageSrc] = useState("")
    const [loading, setLoading] = useState(true)
    const [uploading, setUploading] = useState(false);

    const displayErrorMessage = (message) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };

    async function fetchImageSrc(id) {
        await get(api.offering.getCoverImage(id), setImageSrc, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            }), accessToken
        )
        setLoading(false)
    }

    useEffect(() => {
        if (accessToken === "") return
        fetchImageSrc(manageRecordId)
    }, [accessToken])

    console.log(imageSrc)

    const handleFileChange = async (event) => {
        const file = event.target.files[0];  // Only one file allowed
        if (!file) return;

        setLoading(true)

        const formData = new FormData();
        formData.append("fundId", manageRecordId);
        formData.append("file", file);  // Append image file
        formData.append("imageSrc", imageSrc ? imageSrc : "");

        try {
            const response = await fetch(api.offering.uploadCoverImage(manageRecordId), {
                method: "POST",
                body: formData,
                headers: {
                    "Authorization": `Bearer ${accessToken}` // Ensure authentication
                }
            });

            if (!response.ok) throw new Error("Upload failed");

            const data = await response.json();
            // setImageSrc(data.newImageUrl);  // Update new image URL from response
            setImageSrc(`${data.newImageUrl}`);
            message.success("Image uploaded successfully!");
        } catch (error) {
            displayErrorMessage("Failed to upload image.");
        }

        setLoading(false)
    };



    return <>
        <h1 style={isPhoneSize() ? {
            width: window.innerWidth - 20,
            justifySelf: "center"
        } : {
            width: "560px",
            justifySelf: "center"
        }}>
            Cover Image
        </h1>
        {loading && <div style={{ marginTop: "30px", textAlign: "center" }}><Spin /></div>}
        {!loading && <>
            {imageSrc &&
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <img style={isPhoneSize() ? {
                        width: window.innerWidth - 20
                    } : {
                        maxWidth: "560px"
                    }} src={imageSrc}></img>
                </div>
            }
            <input
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                disabled={uploading}
                style={{ display: "none" }}
                id="upload-input"
            />
            <div style={isPhoneSize() ? {
                width: window.innerWidth - 20,
                justifySelf: "center",
                marginTop: "20px"
            } : {
                width: "560px",
                justifySelf: "center",
                marginTop: "20px"
            }}>
                <label htmlFor="upload-input">
                    <button style={{
                        height: "30px",
                        width: "100px",
                        borderRadius: "30px",
                        background: "rgb(17 32 86)",
                        color: "white",
                        cursor: "pointer",
                    }}
                        onClick={() => document.getElementById("upload-input").click()}
                    >{imageSrc ? "Update Image" : "Upload Image"}</button>
                </label>
            </div>
        </>}
    </>
}