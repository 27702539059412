import { Divider } from "antd"
import close from "../assets/img/close.png"
import { useState } from "react"
import { isPhoneSize } from "utils/util"
import { Spin } from "antd"
import { USDollar } from "utils/format"

export function PrincipalUpdateConfirmation(props) {
    const { setShowUpdateBox, updateCallback, paidInterestAffected } = props

    const [updateText, setUpdateText] = useState("")
    const [deletionButtonText, setDeletionButtonText] = useState("Update")

    function resetUpdateVariables() {
        setShowUpdateBox(false)
    }

    // console.log(updateText)
    return <div style={{
        position: 'fixed',
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        height: "100vh",
        width: "100vw",
        backgroundColor: "rgba(255, 255, 255, 0.5)",
        backdropFilter: "blur(10px)",
        zIndex: 1,
    }}>
        <div style={{
            width: isPhoneSize() ? "100%" : "500px",
            minHeight: "250px",
            position: 'fixed',
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            background: "white",
            border: "1px solid",
            borderRadius: "5px"
        }}>

            <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', height: "50px" }}>
                <div style={{ fontSize: "16px", fontWeight: "bold", marginLeft: "10px" }}>Update Confirmation</div>
                <img src={close} style={{ width: "20px", marginRight: "10px", cursor: "pointer" }}
                    onClick={e => resetUpdateVariables()}
                >
                </img>
            </div>
            <Divider style={{ margin: "0" }} />
            {/* {<div><Spin /></div>} */}

            {<>
                <div style={{ fontSize: "18px", marginLeft: "10px", minHeight: "140px", display: "flex", flexDirection: "column", justifyContent: "space-around", whiteSpace: 'pre-line' }}>

                    {/* {text} */}
                    <div style={{}}>
                        {paidInterestAffected.length > 0 && <div>
                            Following paid interests will be set to unpaid
                        </div>}
                        {paidInterestAffected.map((interest, idx) => <div key={idx}
                            style={{ display: "flex" }}
                        >
                            <div style={{ width: "100px", textAlign: "center" }}>
                                {USDollar.format(interest.amount)}
                            </div>
                            <div style={{ width: "100px", textAlign: "center" }}>
                                {interest.type}
                            </div>
                            <div style={{ width: "100px", textAlign: "center" }}>
                                {interest.timestamp}
                            </div>

                        </div>)}
                    </div>
                    <div>
                        Please type "Update" to confirm update.
                    </div>
                    <div>
                        <input style={{ border: "1px solid" }} onChange={e => setUpdateText(e.target.value)}></input>
                    </div>
                </div>
            </>}
            <Divider style={{ margin: "0" }} />
            <div style={{ display: "flex", justifyContent: "space-between", height: "60px", alignItems: "center", margin: "0 30px" }}>
                <button style={{
                    height: "30px",
                    width: "60px",
                    borderRadius: "30px",
                    background: "rgb(17 32 86)",
                    color: "white",
                    cursor: "pointer",
                }}
                    onClick={e => resetUpdateVariables()}
                >
                    Cancel
                </button>

                <button style={{
                    height: "30px",
                    width: "70px",
                    borderRadius: "30px",
                    background: "red",
                    color: "white",
                    cursor: "pointer",
                }}
                    onClick={e => {
                        if (updateText.trim().toLowerCase() === "update") {
                            setDeletionButtonText("Updating")
                            updateCallback()
                        }
                    }}
                >
                    {deletionButtonText}
                </button>
            </div>
        </div>
    </div>
}