// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.investment-status-switch {
    height: 30px;
    /* Adjust to your desired height */
    font-size: 16px;
    /* Adjust the text size */
}

.investment-status-switch .ant-switch-inner {
    display: flex;
    align-items: center;
    font-size: 16px;
    /* Same size as specified in JSX */
    padding: 0 8px;
    /* Adjust padding as necessary */
}

.investment-status-switch .ant-switch-handle {
    height: 28px !important;
    /* Slightly smaller than the switch height */
    width: 28px !important;
    /* Make sure handle width aligns with height */
    border-radius: 50% !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/RecordInsertion/RecordInsertion.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,kCAAkC;IAClC,eAAe;IACf,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,kCAAkC;IAClC,cAAc;IACd,gCAAgC;AACpC;;AAEA;IACI,uBAAuB;IACvB,4CAA4C;IAC5C,sBAAsB;IACtB,8CAA8C;IAC9C,6BAA6B;AACjC","sourcesContent":[".investment-status-switch {\n    height: 30px;\n    /* Adjust to your desired height */\n    font-size: 16px;\n    /* Adjust the text size */\n}\n\n.investment-status-switch .ant-switch-inner {\n    display: flex;\n    align-items: center;\n    font-size: 16px;\n    /* Same size as specified in JSX */\n    padding: 0 8px;\n    /* Adjust padding as necessary */\n}\n\n.investment-status-switch .ant-switch-handle {\n    height: 28px !important;\n    /* Slightly smaller than the switch height */\n    width: 28px !important;\n    /* Make sure handle width aligns with height */\n    border-radius: 50% !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
