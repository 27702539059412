import { useState, useEffect, useContext } from "react"
import { message, Spin, Input } from "antd";
import api from "api";
import { get } from "utils/fetch";
import { AccountInsertion } from "pages/RecordInsertion/AccountInsertion";
import { GlobalContext } from 'GlobalContext';

export function AccountManager() {

    const { setManageRecordId, manageRecordId, accessToken } = useContext(GlobalContext)
    const [accountId, setAccountId] = useState(0)
    const [accounts, setAccounts] = useState([])
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(true)
    const [filteredText, setFilteredText] = useState("")
    // const { globalEmail } = useContext(GlobalContext)

    const displayErrorMessage = (message) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };

    async function fetchAccounts() {
        await get(api.manager.accounts, setAccounts, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            }), accessToken
        )
        setLoading(false)
    }


    useEffect(() => {
        if (manageRecordId !== 0) {
            setLoading(false)
        }
        setFilteredText("")
        if (manageRecordId !== 0) return
        fetchAccounts()
    }, [manageRecordId])

    // console.log("manageRecordId", manageRecordId)

    return <>
        {loading && <div style={{ marginTop: "30px", textAlign: "center" }}><Spin /></div>}
        {!loading && manageRecordId === 0 && <>
            <div style={{ width: "300px", marginTop: "30px", marginLeft: "20px" }}>
                <Input
                    onChange={e => setFilteredText(e.target.value.trim().toLowerCase())}
                    placeholder="Filter on name"
                />
            </div>
            <div style={{ margin: "20px" }}>
                <button style={{
                    height: "30px",
                    width: "50px",
                    borderRadius: "30px",
                    background: "rgb(17 32 86)",
                    color: "white",
                    cursor: "pointer",
                }}
                    onClick={() => setManageRecordId(-1)}>
                    Add
                </button>
            </div>
            {accounts
                .filter(account =>
                    account.account_name.toLowerCase().includes(filteredText))
                .map((account, idx) => {
                    return <div key={idx} style={{
                        fontSize: "20px",
                        margin: "20px",
                        width: "fit-content",
                        color: "blue",
                        textDecoration: "underline",
                        cursor: "pointer"
                    }}
                        onClick={() => setManageRecordId(account.id)}
                    >
                        {`${account.account_name}`}
                    </div>
                })}

        </>
        }
        {!loading && manageRecordId > 0 && <>
            <AccountInsertion
            // account={accounts.filter(account => account.id === accountId)[0]}
            // setAccountId={setAccountId}
            />
        </>}
        {!loading && manageRecordId < 0 && <>
            <AccountInsertion
            // account={null}
            // setAccountId={setAccountId}
            />
        </>}
    </>
}