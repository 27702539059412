import { useState, useEffect } from "react"
import { isPhoneSize } from "utils/util"
import { Select } from "antd"
import { get } from "utils/fetch"
import api from "api"

export function SummaryFilter(props) {
    const { setFilterIsOpen, filters, setFilters, funds } = props

    const filterNameStyle = {
        fontSize: "13px",
        margin: "10px"
    }

    const selectedOption = {
        background: "#e7f4f5",
        border: "0.1px solid #c7e4e8",
        color: "#007185",
        padding: "7px 9px",
        borderRadius: "7px",
        display: "inline",
        fontSize: "12px",
        minHeight: "33px",
        alignContent: "center",
        cursor: "pointer",
        // whiteSpace: "nowrap",
        boxSizing: "border-box",
        width: "fit-content",
        margin: "5px"
    }

    const unselectedOption = {
        background: "rgb(244, 244, 244)",
        padding: "7px 9px",
        borderRadius: "7px",
        display: "inline",
        fontSize: "12px",
        minHeight: "33px",
        alignContent: "center",
        cursor: "pointer",
        width: "fit-content",
        margin: "5px"
    }

    return <div style={{
        position: "fixed",
        top: isPhoneSize() ? "180px" : "30px", /* distance from the top of the screen */
        left: isPhoneSize() ? "10px" : "220px", /* distance from the left side of the screen */
        /* Adjust as needed for desired coordinates */
        background: "white",
        // padding: "0 10px 10px 10px",
        width: "300px",
        border: "1px solid black",
        // borderRadius: "5px",
        // zIndex: "1000",
        display: "flex",
        flexDirection: "column"
    }}
        onClick={e => e.stopPropagation()}
    >
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <div style={filterNameStyle}>
                Start Date
            </div>

            <input
                type="date"
                value={filters.startDate}
                onChange={e => setFilters(prevFilters => ({
                    ...prevFilters,
                    startDate: e.target.value
                }))}
                style={{
                    marginLeft: "5px", width: "100px"
                }}
            />
        </div>

        <div style={filters.useStartDate ? selectedOption : unselectedOption}
            onClick={() => setFilters(prevFilters => ({
                ...prevFilters,
                useStartDate: !filters.useStartDate
            }))}>
            Use Start Date
        </div>

        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <div style={filterNameStyle}>
                End Date
            </div>

            <input
                type="date"
                value={filters.endDate}
                onChange={e => setFilters(prevFilters => ({
                    ...prevFilters,
                    endDate: e.target.value
                }))}
                style={{
                    marginLeft: "5px", width: "100px"
                }}
            />
        </div>

        <div style={filters.useEndDate ? selectedOption : unselectedOption}
            onClick={() => setFilters(prevFilters => ({
                ...prevFilters,
                useEndDate: !filters.useEndDate
            }))}>
            Use End Date
        </div>

        <div style={filterNameStyle}>
            Fund
        </div>

        <Select
            showSearch
            optionFilterProp="label"
            value={filters.fundFilter === -1 ? "All Funds" : funds.find(fund => fund.id === filters.fundFilter)?.name}
            onChange={(e) => {
                setFilters(prevFilters => ({
                    ...prevFilters,
                    fundFilter: e
                }))
            }}
            placeholder="Choose user"
            style={{ width: '150px', background: "transparent", marginLeft: "5px" }}
            filterOption={(input, option) =>
                option?.label.toLowerCase().includes(input.toLowerCase())
            }
            // optionFilterProp="children"
            options={[
                {
                    value: -1,
                    label: "All Funds"
                },
                ...funds.map((fund) => {
                    return {
                        value: fund.id,
                        label: fund.name
                    }
                })
            ]
            }
        >
        </Select>

        <div style={filterNameStyle}>
            Closing Status in Period
        </div>
        <div style={filters.closedWithinPeriod.active ? selectedOption : unselectedOption}
            onClick={() => setFilters(prevFilters => ({
                ...prevFilters,
                closedWithinPeriod: {
                    active: !prevFilters.closedWithinPeriod.active
                }
            }))}>
            Contract Matured Within This Period
        </div>

        <div style={filterNameStyle}>
            Type
        </div>

        <div style={{ display: "flex", flexWrap: "wrap" }}>
            <div style={filters.type.principal ? selectedOption : unselectedOption}
                onClick={() => setFilters(prevFilters => ({
                    ...prevFilters,
                    type: {
                        ...prevFilters.type,
                        principal: !prevFilters.type.principal
                    }
                }))}
            >
                Principal
            </div>

            <div style={filters.type["principal Taken Out"] ? selectedOption : unselectedOption}
                onClick={() => setFilters(prevFilters => ({
                    ...prevFilters,
                    type: {
                        ...prevFilters.type,
                        ["principal Taken Out"]: !prevFilters.type["principal Taken Out"]
                    }
                }))}
            >
                Principal Taken Out
            </div>

            <div style={filters.type.interest ? selectedOption : unselectedOption}
                onClick={() => setFilters(prevFilters => ({
                    ...prevFilters,
                    type: {
                        ...prevFilters.type,
                        interest: !prevFilters.type.interest
                    }
                }))}
            >
                Interest
            </div>

            <div style={filters.type["final Payout"] ? selectedOption : unselectedOption}
                onClick={() => setFilters(prevFilters => ({
                    ...prevFilters,
                    type: {
                        ...prevFilters.type,
                        ["final Payout"]: !prevFilters.type["final Payout"]
                    }
                }))}
            >
                Final Payout
            </div>

        </div>
    </div>
}