import { useEffect, useState, useContext } from "react"
import { Col, Divider, Layout, Row, Spin, Table, message, Typography, Collapse, Tooltip, Select } from 'antd';
import { CompassOutlined } from '@ant-design/icons';
import api from 'api';
import { get, post } from 'utils/fetch';
import backArrow from "../../assets/img/backArrow.png"
import PdfIcon from "../../assets/img/PdfIcon.png"
import { FundPhotoGallery } from "components/FundPhotoGallery";
import { OfferingDetailedTab } from "components/OfferingDetailedTab";
import Map from "components/Map";
import { isPhoneSize } from "utils/util";
import { CompanyInfo } from "components/CompanyInfo";
import { propertyToAddress } from "utils/util";
import { GlobalContext } from 'GlobalContext';

export function OfferingDetailed(props) {
    const { email, id, isHolding } = props
    const { accessToken } = useContext(GlobalContext)
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(true);
    const [fund, setFund] = useState(null);
    const [photos, setPhotos] = useState([]);
    const [progressPhotos, setProgressPhotos] = useState([]);
    const [pdfs, setPdfs] = useState([]);
    const [properties, setProperties] = useState([]);
    const [curProperty, setCurProperties] = useState({})
    const [position, setPosition] = useState([51.505, -0.09]);
    const [address, setAddress] = useState("")
    const sections = ["Overview", "Video", "Photos", "ProgressPhotos", "Documents", "Locations"];
    const [activeSection, setActiveSection] = useState("Overview");

    const displayErrorMessage = (message) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };

    async function fetchFund(id) {
        await get(api.fund.getBasicInfo(id), setFund, (errors) => errors.forEach(err => {
            displayErrorMessage(err.message);
        }), accessToken);
    };

    async function fetchPhotos(id) {
        await get(api.offering.getPhotoSources(id), setPhotos, (errors) => errors.forEach(err => {
            displayErrorMessage(err.message);
        }), accessToken);
    };

    async function fetchProgressPhotos(id) {
        await get(api.offering.getProgressPhotoSources(id), setProgressPhotos, (errors) => errors.forEach(err => {
            displayErrorMessage(err.message);
        }), accessToken);
        setLoading(false);
    };

    async function fetchPdfs(id) {
        await get(api.offering.getDocumentSources(id), setPdfs, (errors) => errors.forEach(err => {
            displayErrorMessage(err.message);
        }), accessToken);
    };

    async function fetchProperties(id) {
        await get(api.fund.getFundProperty(id), setProperties, (errors) => errors.forEach(err => {
            displayErrorMessage(err.message);
        }), accessToken);
    };

    async function fetchData(id) {
        setLoading(true);
        fetchFund(id);
        fetchPhotos(id);
        fetchPdfs(id);
        fetchProperties(id);
        fetchProgressPhotos(id)
    }

    //console.log("properties", properties)

    useEffect(() => {
        if (properties.length == 0) return
        //console.log("cur property", properties[0])
        setPosition([properties[0].latitude, properties[0].longitude])
        setAddress(`${properties[0].address}, ${properties[0].city}, ${properties[0].state} ${properties[0].zip}`)
    }, [properties])

    useEffect(() => {
        if (accessToken === "") return
        fetchData(id);
    }, [id, accessToken])

    function getTypeBackgroundColor(type) {
        if (type.toLowerCase() === "debt") {
            return "blue"
        } else if (type.toLowerCase() === "equity") {
            return "green"
        }
        return "darkcyan"
    }

    function getTypeTagWidth(type) {
        if (type.toLowerCase() === "debt" || type.toLowerCase() === "equity") {
            return "50px"
        }
        return "80px"
    }

    //console.log("fund,", fund)

    const handleScroll = () => {

        let minDist = Infinity;
        let minDistElement = null;

        sections.forEach((id) => {
            let targetNode = document.getElementById(id);
            if (targetNode) {
                // Get the section's position relative to the container
                let rect = targetNode.getBoundingClientRect();
                let centerY = rect.top + rect.height / 2;
                // Calculate the distance between the center of the screen and the section
                let distance = Math.abs(document.getElementById("detailed-fund-name").getBoundingClientRect().height - centerY);
                if (distance < minDist && distance < 50 && distance > 0) {
                    minDist = distance;
                    minDistElement = targetNode;
                }
            }
        });

        if (minDistElement) {
            setActiveSection(minDistElement.id);
        }
    };
    console.log(activeSection)
    return <div style={isPhoneSize() ? {
        width: window.innerWidth - 20,
        position: "fixed"
    } : {
        width: "100%"
    }}>
        {loading && <div style={{ textAlign: "center", height: "100vh", marginTop: "30px" }}><Spin /></div>}
        {!loading && fund && <div style={isPhoneSize() ? {} : { marginTop: "20px" }}>
            <div style={{ justifyItems: "center" }}>
                <div style={{}}>
                    <div style={{}}>
                        {/* tabs */}
                        {isPhoneSize() && <div style={isPhoneSize() ? {
                            display: "flex"
                        } : {
                            paddingLeft: "30px",
                            display: 'flex',
                            justifyContent: "space-around",
                            alignItems: "center",
                            // position: "sticky",
                            // top: "25px",
                            height: "20px",
                            background: "transparent"
                        }}>
                            <OfferingDetailedTab text={"Overview"} />
                            <OfferingDetailedTab text={"Video"} />
                            <OfferingDetailedTab text={"Photos"} />
                            <OfferingDetailedTab text={"Documents"} />
                            <OfferingDetailedTab text={"Locations"} />
                        </div>}
                        {/* back arrow, topic, fund tag */}
                        <div style={{
                            // position: "sticky",
                            // top: "45px",
                            background: "rgb(245 245 245)",
                            display: "flex",
                            alignItems: "center",
                            // justifyContent: "space-between",
                            padding: "20px",
                            background: "center"
                        }}
                            id="detailed-fund-name">
                            <div style={{}}>
                                <a href={isHolding ? "holdings" : "offerings"}>
                                    <img style={{ width: "15px", height: "20px", cursor: "pointer" }} src={backArrow}></img>
                                </a>
                            </div>
                            <div style={{ textAlign: "center", fontSize: "30px", fontWeight: "bold", display: "flex", flexDirection: "column", marginLeft: "20px" }}>
                                <div style={{ paddingBottom: "6px" }}>
                                    {fund.name}
                                </div>
                            </div>

                            <div style={{ marginLeft: "20px" }}>
                                {fund.type && <div style={{ display: "flex", flexDirection: "row", paddingLeft: "5px" }}>
                                    {fund.type.split('/').map((curType, idx) => <div key={idx} style={{
                                        width: "45px",
                                        height: "20px",
                                        fontFamily: "PingFang SC",
                                        fontSize: "12px",
                                        fontWeight: "600",
                                        lineHeight: "18px",
                                        letterSpacing: "0em",
                                        color: "#FFFFFF",
                                        background: getTypeBackgroundColor(curType),
                                        borderRadius: "4px",
                                        alignContent: "center",
                                        textAlign: "center",
                                        marginRight: "10px"
                                    }}>
                                        {curType}
                                    </div>)}
                                </div>
                                }

                                {isPhoneSize() &&
                                    <a target="_blank" rel="noopener noreferrer" href='https://calendly.com/alphax-investment/30min' style={{ color: "red" }}>
                                        <button style={{
                                            background: "rgb(17 32 86)",
                                            color: "white",
                                            width: "50px",
                                            fontSize: "12px",
                                            borderRadius: "15px",
                                            cursor: "pointer",
                                            fontWeight: 'bold',
                                            padding: "0"
                                        }}>
                                            Invest
                                        </button>
                                    </a>}
                            </div>

                        </div>
                    </div>

                    <div style={{ display: "flex" }}>
                        {!isPhoneSize() && <div style={{ minWidth: "180px" }}>
                            <div style={{
                                paddingLeft: "30px",
                                display: 'flex',
                                justifyContent: "space-around",
                                height: "20px",
                                background: "transparent",
                                flexDirection: "column"
                            }}>
                                {sections.map((section, id) => {
                                    return <OfferingDetailedTab text={section} key={id} activeSection={activeSection} onClick={() => setActiveSection(section)} />
                                })}
                            </div>
                        </div>}
                        <div style={isPhoneSize() ? {
                            overflowY: "scroll", height: "calc(100vh - 264px)", scrollbarWidth: "none"
                        } : {
                            overflowY: "scroll", height: "calc(100vh - 124px)", scrollbarWidth: "none", maxWidth: "560px"
                        }}
                            onScroll={handleScroll}
                        >
                            {/* top image */}
                            <div>
                                {fund.img_src && fund.img_src.length > 0 &&
                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                        <img style={isPhoneSize() ? {
                                            width: window.innerWidth - 20
                                        } : {
                                            maxWidth: "100%"
                                        }} src={fund.img_src}></img>
                                    </div>
                                }

                                {/* Overview */}
                                <div style={{ background: "#F7F7F9", borderRadius: "12px", padding: "10px", marginTop: "10px" }}>
                                    <h1 id="Overview" style={{ marginTop: "0px" }}>
                                        Overview
                                    </h1>
                                    {fund.overview && fund.overview.length > 0 &&
                                        <div style={{ display: "flex", justifyContent: "center" }}>
                                            <div style={{ maxWidth: "560px", fontSize: "16px" }}>
                                                {fund.overview.split('\n')
                                                    .map((e, i) => <p key={i}>{e}</p>)}
                                            </div>
                                        </div>
                                    }
                                </div>

                                {/* Video */}
                                <div style={{ background: "#F7F7F9", borderRadius: "12px", padding: "10px", marginTop: "10px" }}>
                                    <h1 id="Video" style={{ marginTop: "0px" }}>
                                        Video
                                    </h1>
                                    {fund.video_src && fund.video_src.length > 0 && fund.video_src.includes("www.youtube.com") &&
                                        <div style={{ textAlign: "center" }}>
                                            <iframe
                                                width={isPhoneSize() ? window.innerWidth - 20 : "100%"}
                                                height={isPhoneSize() ? (window.innerWidth - 20) / 560 * 315 : "315"}
                                                src={fund.video_src}
                                                title="YouTube video player"
                                                frameBorder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen; web-share"
                                                referrerPolicy="strict-origin-when-cross-origin"
                                            // allowfullscreen
                                            >

                                            </iframe>
                                        </div>
                                    }

                                    {fund.video_src && fund.video_src.includes("alphax-dashboard-demo.s3") && (
                                        <div style={{ display: "flex", justifyContent: "center" }}>
                                            <video
                                                style={isPhoneSize() ? { width: window.innerWidth - 20 } : { maxWidth: "560px" }}
                                                controls
                                            >
                                                <source src={fund.video_src} type="video/mp4" />
                                                Your browser does not support the video tag.
                                            </video>
                                        </div>
                                    )}
                                </div>

                                {/* Photo Gallery */}
                                <div style={{ background: "#F7F7F9", borderRadius: "12px", padding: "10px", marginTop: "10px" }}>
                                    <h1 id="Photos" style={{ marginTop: "0px" }}>
                                        {photos.length > 1 ? "Photos" : "Photo"}
                                    </h1>
                                    {photos.length > 0 &&
                                        <FundPhotoGallery photos={photos} />
                                    }
                                </div>

                                {/* Progress Photo Gallery */}
                                <div style={{ background: "#F7F7F9", borderRadius: "12px", padding: "10px", marginTop: "10px" }}>
                                    <h1 id="ProgressPhotos" style={{ marginTop: "0px" }}>Progress Photos</h1>
                                    {progressPhotos.length > 0 &&
                                        <FundPhotoGallery photos={progressPhotos} />
                                    }
                                </div>

                                {/* Document */}
                                <div style={{ background: "#F7F7F9", borderRadius: "12px", padding: "10px", marginTop: "10px" }}>
                                    <h1 id="Documents" style={{ marginTop: "0px" }}>
                                        {pdfs.length > 1 ? "Documents" : "Document"}
                                    </h1>

                                    {pdfs.length > 0 && //1 === 2 &&
                                        <div style={{ display: "flex" }}>
                                            <div style={{ maxWidth: "560px" }}>
                                                {pdfs.map((pdf, id) => {
                                                    return <a href={pdf.url} key={id} target="_blank" rel="noreferrer noopener">
                                                        <div key={id} style={{ display: "flex", alignItems: "center", fontSize: "15px", color: "black" }}>
                                                            <div style={{ padding: "5px", paddingBottom: "0" }}>
                                                                <img src={PdfIcon} style={{ width: "20px" }}></img>
                                                            </div>
                                                            {pdf.url.substring(pdf.url.lastIndexOf('/') + 1)}
                                                        </div>
                                                    </a>
                                                })}
                                            </div>
                                        </div>
                                    }
                                </div>

                                {/* Location */}
                                <div style={{ background: "#F7F7F9", borderRadius: "12px", padding: "10px", marginTop: "10px" }}>
                                    <h1 id="Locations" style={{ marginTop: "0px" }}>
                                        {properties.length > 1 ? "Locations" : "Location"}
                                    </h1>
                                    {address.length > 0 && // 1 === 2 &&
                                        <div style={{ display: "flex", width: "100%", alignItems: "center", flexDirection: "column", paddingBottom: isPhoneSize() ? "50px" : "" }}>
                                            <div style={isPhoneSize() ? {
                                                width: window.innerWidth - 80, height: window.innerWidth - 80, display: "flex"
                                            } : {
                                                width: "100%", maxWidth: "400px", aspectRatio: "1 / 1", display: "flex"
                                            }}
                                            >
                                                <Map position={position}
                                                    setPosition={setPosition}
                                                    properties={properties}
                                                    setAddress={setAddress}
                                                />
                                            </div>

                                            <div>
                                                {properties.map((property, idx) => (
                                                    <div key={idx} style={{ display: "flex", flexDirection: "row", alignItems: "center", minHeight: "30px" }}>

                                                        {/* Address literal */}
                                                        <div style={propertyToAddress(property) === address ? {
                                                            fontSize: "17px", cursor: "pointer", fontWeight: "bold", title: "hi"
                                                        } : {
                                                            fontSize: "17px", cursor: "pointer", title: "hi"
                                                        }}
                                                            onClick={e => {
                                                                setPosition([property.latitude, property.longitude])
                                                                setAddress(propertyToAddress(property))
                                                            }}
                                                        >
                                                            {propertyToAddress(property)}
                                                        </div>

                                                        {/* Google map sign */}
                                                        <a href={`http://maps.google.com/maps?q=${encodeURIComponent(propertyToAddress(property))}`} target='_blank' style={{ marginLeft: "auto" }}>
                                                            <CompassOutlined style={{ fontSize: "20px", margin: "0 10px", cursor: "pointer", title: "open google map" }} />
                                                        </a>
                                                    </div>
                                                ))}
                                            </div>

                                            {/* <a href={`http://maps.google.com/maps?q=${encodeURIComponent(address)}`} target='_blank'>
                                    <div style={{ color: "black" }}>{address}</div>
                                </a> */}
                                        </div>
                                    }
                                </div>

                                <div style={isPhoneSize() ? {
                                    height: "250px", display: "flex"
                                } : {
                                    height: "50px", display: "flex"
                                }}></div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        }
    </div >
}