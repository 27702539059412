import api from "api";
import { get } from "utils/fetch";
import { isPhoneSize } from "utils/util";
import { GlobalContext } from "GlobalContext";
import { useEffect, useState, useContext, useRef } from "react"
import { Select, Space, message, Spin, Switch } from "antd"
import { FundPhotoGallery } from "components/FundPhotoGallery";
import { DeletePhotoComfirmation } from "ConfirmationPages/DeletePhotoComfirmation";

export function Photos() {
    const { manageRecordId, accessToken } = useContext(GlobalContext)
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(true)
    const [photoSources, setPhotoSources] = useState([])
    const [uploading, setUploading] = useState(false);
    const [selectedPhotos, setSelectedPhotos] = useState([])
    const [imagePreviews, setImagePreviews] = useState([]);
    const [photoURLToDelete, setPhotoURLToDelete] = useState("")
    const [photoIdToDelete, setPhotoIdToDelete] = useState(0)

    const displayErrorMessage = (message) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };

    async function fetchPhotoSources(id) {
        await get(api.offering.getPhotoSources(id), setPhotoSources, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            }), accessToken
        );
        setLoading(false);
    }

    const handlePhotoChange = (event) => {
        const files = Array.from(event.target.files);
        setSelectedPhotos(files);

        // Generate image previews
        const previews = files.map(file => URL.createObjectURL(file));
        setImagePreviews(previews);
    };

    useEffect(() => {
        // if (accessToken === "") return;
        fetchPhotoSources(manageRecordId);
    }, [accessToken]);

    console.log(photoSources)

    const handleUpload = async () => {
        if (selectedPhotos.length === 0) {
            alert("Please select files first!");
            return;
        }

        setLoading(true)
        const formData = new FormData();

        for (let i = 0; i < selectedPhotos.length; i++) {
            formData.append("photos", selectedPhotos[i]);
        }

        formData.append("fundId", manageRecordId);

        try {
            const response = await fetch(api.offering.uploadPhotos(manageRecordId), {
                method: "POST",
                body: formData,
            });

            if (!response.ok) {
                throw new Error("Upload failed");
            }

            const data = await response.json();
            // setUploadedImages(data.uploaded_files);
        } catch (error) {
            console.error("Error uploading files:", error);
        }
        setSelectedPhotos([]);
        setImagePreviews([]);
        fetchPhotoSources(manageRecordId)
    };

    async function deletionCallback() {
        setLoading(true)
        const body = JSON.stringify({
            photoURLToDelete
        })

        const options = {
            method: "DELETE", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${accessToken}`,
            },
            body
        }
        const result = await fetch(api.offering.deletePhoto(photoIdToDelete), options);
        const data = await result.json()
        setPhotoURLToDelete("")
        fetchPhotoSources(manageRecordId)
    }

    return <div style={{ justifyItems: "center" }}>
        <div style={isPhoneSize() ? {
            width: window.innerWidth - 20,
        } : {
            width: "560px",
        }}>
            <h1>
                Photos
            </h1>

            {loading && <div style={{ marginTop: "30px", textAlign: "center" }}><Spin /></div>}

            {!loading && <>
                {photoURLToDelete.length > 0 && <>
                    <DeletePhotoComfirmation photoURLToDelete={photoURLToDelete} setPhotoURLToDelete={setPhotoURLToDelete} deletionCallback={deletionCallback} />
                </>}

                {/* Hidden File Input */}
                <input
                    type="file"
                    multiple
                    accept="image/*"
                    onChange={handlePhotoChange}
                    style={{ display: "none" }}
                    id="photo-upload-input"
                />

                <div style={{ display: "flex", placeContent: "center" }}>
                    <div style={{ maxWidth: "700px", display: "flex", flexFlow: "wrap", placeContent: "center" }}>
                        {photoSources.map((photo, idx) => (
                            <div key={idx} style={{ position: "relative" }}>
                                {/* Cross button for deletion */}
                                <button
                                    onClick={() => {
                                        setPhotoURLToDelete(photo.url)
                                        setPhotoIdToDelete(photo.id)
                                    }}
                                    style={{
                                        position: "absolute",
                                        top: "-5px",
                                        right: "-5px",
                                        background: "red",
                                        color: "white",
                                        border: "none",
                                        borderRadius: "50%",
                                        width: "20px",
                                        height: "20px",
                                        cursor: "pointer",
                                        fontSize: "14px",
                                        lineHeight: "18px",
                                        textAlign: "center",
                                    }}
                                >
                                    x
                                </button>
                                <img
                                    src={photo.url}
                                    style={{ height: "100px", margin: "5px", cursor: "pointer" }}
                                ></img>
                            </div>
                        ))}
                    </div>
                </div>

                {/* Image Previews */}
                {imagePreviews.length > 0 && (
                    <div style={{ marginTop: "20px", display: "flex", flexWrap: "wrap", gap: "10px", justifyContent: "center" }}>
                        {imagePreviews.map((src, index) => (
                            <div key={index} style={{ position: "relative" }}>
                                {/* Cross button for deletion */}
                                <button
                                    onClick={() => {
                                        setSelectedPhotos(selectedPhotos => selectedPhotos.filter((_, i) => i !== index))
                                        setImagePreviews(imagePreviews => imagePreviews.filter((_, i) => i !== index))
                                    }}
                                    style={{
                                        position: "absolute",
                                        top: "-5px",
                                        right: "-5px",
                                        background: "red",
                                        color: "white",
                                        border: "none",
                                        borderRadius: "50%",
                                        width: "20px",
                                        height: "20px",
                                        cursor: "pointer",
                                        fontSize: "14px",
                                        lineHeight: "18px",
                                        textAlign: "center",
                                    }}
                                >
                                    x
                                </button>
                                <img src={src} alt={`preview-${index}`} style={{ width: "100px", height: "100px", objectFit: "cover", borderRadius: "5px" }} />
                            </div>
                        ))}
                    </div>
                )}

                {/* Select Files Button */}
                <label htmlFor="photo-upload-input">
                    <button
                        style={{
                            height: "30px",
                            width: "120px",
                            borderRadius: "30px",
                            background: "rgb(17 32 86)",
                            color: "white",
                            cursor: "pointer",
                            marginTop: "20px"
                        }}
                        onClick={() => document.getElementById("photo-upload-input").click()}
                    >
                        Select Photos
                    </button>
                </label>

                {/* Upload Button (only shown when files are selected) */}
                {selectedPhotos.length > 0 && (
                    <button
                        onClick={handleUpload}
                        disabled={uploading}
                        style={{
                            height: "30px",
                            width: "120px",
                            borderRadius: "30px",
                            background: uploading ? "gray" : "green",
                            color: "white",
                            cursor: uploading ? "not-allowed" : "pointer",
                            marginLeft: "10px",
                            marginTop: "20px"
                        }}
                    >
                        {uploading ? "Uploading..." : "Upload"}
                    </button>
                )}

            </>}
        </div>
    </div>
}