import { USDollar } from "utils/format";
import arrowLeft from "../assets/img/arrowLeft.png";
import arrowRight from "../assets/img/arrowRight.png";
import { useEffect, useState } from "react";
import { isAdmin, isInvestor } from "utils/util";
import api from "api";
import { sendEmail } from "utils/util";

export function TransactionGallery(props) {
    const { transactions: initialTransactions, basicInfo } = props;
    const timezoneOffset = "T00:00:00-07:00"
    const [curPageIdx, setCurPageIdx] = useState(0);
    const [transactions, setTransactions] = useState(isAdmin() ? initialTransactions :
        initialTransactions.filter(transaction => new Date(transaction.timestamp + timezoneOffset) <= new Date()));
    //console.log(new Date(`2024-08-31` + "T00:00:00-07:00"))
    //console.log(new Date())
    //console.log("new Date('2024-08-31') <= new Date()", new Date("2024-08-31") <= new Date())
    function isFirstPage() {
        return curPageIdx === 0;
    }

    function isLastPage() {
        return (curPageIdx + 1) * 10 + 1 > transactions.length;
    }

    const curTransaction = transactions.slice(curPageIdx * 10, curPageIdx * 10 + 10);

    const handleStatusChange = (transaction, idx, email, name, type) => {
        const updatedStatus = transaction.transaction_status === "unpaid" ? "paid" : "unpaid";
        fetch(api.transaction.setStatus(transaction.id), {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                status: updatedStatus,
            }),
        });

        const updatedTransactions = [...transactions];
        updatedTransactions[idx + curPageIdx * 10].transaction_status = updatedStatus;
        setTransactions(updatedTransactions);
        // buttonText, amount, email, name, type
        sendEmail(updatedStatus === "paid" ? "Pay" : "Unpay", transaction.amount, email, name, type);
    };
    //console.log(transactions)
    return (
        <div>
            <div style={{ display: 'flex', alignItems: "center" }}>
                <div style={{
                    width: "30px",
                    height: "30px",
                    display: "flex",
                    position: "relative",
                    left: '15px',
                    zIndex: isFirstPage() ? "0" : "1",
                    cursor: isFirstPage() ? "auto" : 'pointer'
                }}
                    onClick={() => !isFirstPage() && setCurPageIdx(curPageIdx - 1)}
                >
                    <img style={{ display: isFirstPage() ? "none" : "flex" }} src={arrowLeft}></img>
                </div>
                <div style={{ display: 'flex', flexDirection: "column" }}>
                    {curTransaction.map((transaction, idx) => (
                        <li style={{ margin: 0, display: "flex" }} key={transaction.id}>
                            <div style={{ width: "100px", textAlign: "center" }}>{transaction.timestamp}</div>
                            <div style={{ width: "100px", textAlign: "center" }}>{USDollar.format(transaction.amount)}</div>
                            <div style={{ width: "100px", textAlign: "center" }}>{transaction.type.charAt(0).toUpperCase() + transaction.type.slice(1)}</div>

                            <div style={{ width: "100px", textAlign: "center" }}>
                                {transaction.transaction_status.charAt(0).toUpperCase() + transaction.transaction_status.slice(1)}
                            </div>


                            {/* ----------------------------------------------------------------- */}
                            {/* for admin: cashout: later than today: "pay" */}
                            {/* {isAdmin() && transaction.transaction_status.toLowerCase() !== "reinvest" && new Date(transaction.timestamp + timezoneOffset) > new Date() &&
                                <div style={{ width: "100px", textAlign: "center" }}>{transaction.transaction_status.charAt(0).toUpperCase() + transaction.transaction_status.slice(1)}</div>
                            } */}
                            {/* for admin: cashout: earlier than today: <button>pay/unpay<button> */}
                            {isAdmin() && transaction.transaction_status.toLowerCase() !== "reinvest" && new Date(transaction.timestamp + timezoneOffset) <= new Date() &&
                                <div style={{ width: "100px", textAlign: "center" }}>
                                    <button
                                        style={{ background: "rgb(17 32 86)", color: "white", borderRadius: "5px" }}
                                        onClick={() => handleStatusChange(transaction, idx, basicInfo.email, basicInfo.legal_name, transaction.type.toLowerCase())}
                                    >
                                        {transaction.transaction_status === "unpaid" ? "Pay" : "Unpay"}
                                    </button>
                                </div>
                            }
                            {/* for investor: cashout: "pay/paid" */}
                            {/* {isInvestor() && transaction.transaction_status.toLowerCase() !== "reinvest" && <div style={{ width: "100px", textAlign: "center" }}>
                                {transaction.transaction_status.charAt(0).toUpperCase() + transaction.transaction_status.slice(1)}
                            </div>} */}

                            {/* universal: reinvest: "reinvest" */}
                            {/* {transaction.transaction_status.toLowerCase() === "reinvest" &&
                                <div style={{ width: "100px", textAlign: "center" }}>
                                    {transaction.transaction_status.charAt(0).toUpperCase() + transaction.transaction_status.slice(1)}
                                </div>
                            } */}
                        </li>
                    ))}
                </div>


                <div style={{
                    width: "30px",
                    height: "30px",
                    display: isLastPage() ? "none" : "flex",
                    position: "relative",
                    right: '15px',
                    zIndex: isLastPage() ? "0" : "1",
                    cursor: isLastPage() ? "auto" : 'pointer'
                }}
                    onClick={() => !isLastPage() && setCurPageIdx(curPageIdx + 1)}
                >
                    <img src={arrowRight}></img>
                </div>
            </div>
        </div>
    );
}
