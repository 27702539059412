import React, { useState, useEffect, useContext } from 'react';
import api from "api";
import { GlobalContext } from 'GlobalContext';
import { sendEmail } from "utils/util";
import { USDollar } from 'utils/format';

export function HintPopup() {
    const [showPopup, setShowPopup] = useState(false);
    const [transactions, setTransactions] = useState([]);
    const { accessToken } = useContext(GlobalContext);
    const [isLoading, setIsLoading] = useState(true)
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [hoveredType, setHoveredType] = useState(null);
    const [selectedTransaction, setSelectedTransaction] = useState(null)
    const lastPopup = 'Last Popup';
    const firstVisit = 'First Visit';

    // Previous functions remain the same...
    const showPopupOffTime = (lastShowTime) => {
        const lastShown = new Date(lastShowTime);
        const now = new Date();
        const isNewDay = lastShown.getDate() !== now.getDate() ||
            lastShown.getMonth() !== now.getMonth() ||
            lastShown.getFullYear() !== now.getFullYear();
        return isNewDay;
    };

    const checkAndShowPopup = () => {
        const lastShowTime = localStorage.getItem(lastPopup);
        const visitStatus = localStorage.getItem(firstVisit);

        if (visitStatus === true || visitStatus === '') {
            localStorage.setItem(firstVisit, "false");
            localStorage.setItem(lastPopup, new Date().toISOString());
            setShowPopup(true);
            return;
        }

        if (showPopupOffTime(lastShowTime)) {
            localStorage.setItem(lastPopup, new Date().toISOString());
            setShowPopup(true);
            return;
        }
    };

    async function fetchAllUpcomingAndDue() {
        try {
            const response = await fetch(api.manager.upcomingAndPastDue, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch transactions');
            }

            const data = await response.json();

            // Need to handle arrays that might be returned as strings
            const processedData = data.data.map(transaction => ({
                ...transaction,
                account_names: Array.isArray(transaction.account_names)
                    ? transaction.account_names
                    : JSON.parse(transaction.account_names || '[]'),
                investor_names: Array.isArray(transaction.investor_names)
                    ? transaction.investor_names
                    : JSON.parse(transaction.investor_names || '[]'),
                investor_emails: Array.isArray(transaction.investor_emails)
                    ? transaction.investor_emails
                    : JSON.parse(transaction.investor_emails || '[]')
            }));

            setTransactions(processedData || []);
        } catch (error) {
            console.error('Error fetching transactions:', error);
        } finally {
            setIsLoading(false);
        }
    }

    const updateLocalTransaction = (transactionId, newStatus) => {
        setTransactions(prevTransactions =>
            prevTransactions.map(transaction =>
                transaction.id === transactionId
                    ? { ...transaction, status: newStatus }
                    : transaction
            )
        );
    };

    async function getInvestorNameEmail(investment_id) {
        try {
            const response = await fetch(api.manager.investorEmail(investment_id), {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();

            // Check if the response contains an error
            if (data.Error) {
                console.error('Error:', data.Error);
                return;
            }
            // console.log(data);

            const result = data.data || []

            const emails = result.map(item => item.email);
            const names = result.map(item => item.name);

            return { emails, names };

        } catch (error) {
            console.error('Error getting email:', error);
        }
    }

    async function setPaymentStatus(transaction_id, status) {
        try {
            const response = await fetch(api.manager.updatePaymentStatus(transaction_id), {
                method: 'PATCH',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    status: status
                })
            });

            if (!response.ok) {
                throw new Error('Failed to update transaction status');
            }

            const data = await response.json();
            console.log('Transaction status updated:', data);

            // Update both the transactions list and selected transaction
            setTransactions(prevTransactions =>
                prevTransactions.map(transaction =>
                    transaction.id === transaction_id
                        ? { ...transaction, status: status }
                        : transaction
                )
            );

            setSelectedTransaction(prevTransaction => {
                if (prevTransaction && prevTransaction.id === transaction_id) {
                    return { ...prevTransaction, status: status };
                }
                return prevTransaction;
            });

            return status;
        } catch (error) {
            console.error('Error setting payment status', error);
        }
    }


    checkAndShowPopup();

    useEffect(() => {
        if (showPopup && accessToken) {
            fetchAllUpcomingAndDue();
            const styleSheet = document.createElement('style');
            styleSheet.type = 'text/css';
            styleSheet.innerText = `
                @keyframes popupFadeIn {
                    to {
                        transform: scale(1);
                        opacity: 1;
                    }
                }
            `;
            document.head.appendChild(styleSheet);

            return () => {
                document.head.removeChild(styleSheet);
            };
        }
    }, [showPopup, accessToken]);

    const popupStyles = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backdropFilter: 'blur(8px)',
            backgroundColor: 'rgba(80, 53, 53, 0.3)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
            fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
        },
        popupContent: {
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '8px',
            width: '500px',
            maxHeight: '80vh',
            overflowY: 'auto',
            textAlign: 'left',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            transform: 'scale(0.9)',
            opacity: 0,
            animation: 'popupFadeIn 0.3s forwards',
        },
        closeButton: {
            margin: '10px',
            padding: '10px 20px',
            fontSize: '16px',
            cursor: 'pointer',
            border: 'none',
            borderRadius: '5px',
            transition: 'background-color 0.3s ease',
            backgroundColor: 'rgb(73, 69, 255)',
            color: 'white',
            display: 'flex',
            justifyContent: 'center'
        },
        closeButtonPaymentPage: {
            position: 'absolute',
            bottom: '20px',
            right: '20px',
            padding: '10px 20px',
            fontSize: '16px',
            cursor: 'pointer',
            border: 'none',
            borderRadius: '5px',
            backgroundColor: 'rgb(73, 69, 255)',
            color: 'white',
        },
        backButton: {
            position: 'absolute',
            bottom: '20px',
            left: '20px',
            padding: '10px 20px',
            fontSize: '16px',
            cursor: 'pointer',
            border: 'none',
            borderRadius: '5px',
            backgroundColor: 'rgb(73, 69, 255)',
            color: 'white',
            transition: 'background-color 0.3s ease',
        },
        sectionTitle: {
            fontSize: '18px',
            fontWeight: 'bold',
            marginTop: '16px',
            marginBottom: '12px',
            color: '#333',
        },
        amount: {
            fontSize: '16px',
            fontWeight: 'bold',
            color: '#333',
        },
        date: {
            fontSize: '14px',
            color: '#666',
            marginTop: '4px',
        },
        status: {
            display: 'inline-block',
            padding: '4px 8px',
            borderRadius: '12px',
            fontSize: '12px',
            fontWeight: 'bold',
            marginTop: '4px',
        },
        paymentDetailsContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
        },
        payButton: {
            marginTop: '20px',
            padding: '12px 24px',
            fontSize: '18px',
            fontWeight: 'bold',
            cursor: 'pointer',
            border: 'none',
            borderRadius: '5px',
            backgroundColor: '#28a745', // Green for Pay Now button
            color: 'white',
            transition: 'background-color 0.3s ease',
        },
        accountInfo: {
            display: 'block',
            fontSize: '14px',
            color: '#666',
            marginTop: '2px',
        },
        transactionItem: {
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            padding: '12px',
            borderRadius: '6px',
            backgroundColor: '#f8f9fa',
            marginBottom: '12px',
            border: 'none',
            cursor: 'pointer',
            transition: 'background-color 0.3s ease',
        },
        transitionHover: {
            background: '#e9ecef',
        },
        transactionLeft: {
            textAlign: 'left',
        },
        transactionRight: {
            textAlign: 'right',
            minWidth: '200px',
            marginLeft: '16px',
        },
        accountList: {
            fontSize: '14px',
            fontWeight: 'bold',
            color: '#000',
            marginTop: '2px',
            textAlign: 'right',
        },
        accountItem: {
            display: 'block',
            marginBottom: '2px',
        },
    };

    const formatDate = (timestamp) => {
        return new Date(timestamp).toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
            timeZone: 'UTC'
        });
    };

    const isUpcoming = (timestamp, status) => {
        const date = new Date(timestamp);
        const now = new Date();
        const sevenDaysFromNow = new Date();
        sevenDaysFromNow.setDate(sevenDaysFromNow.getDate() + 7);
        return (date >= now) && (date <= sevenDaysFromNow) && (status === 'unpaid');
    };

    const isPastDue = (timestamp, status) => {
        const date = new Date(timestamp);
        const now = new Date();
        return date < now && status === 'unpaid';
    };

    // Separate transactions into past due and upcoming
    const pastDueTransactions = Array.isArray(transactions) ?
        transactions.filter(t => t.amount !== 0).filter(t => isPastDue(t.timestamp, t.status)) : [];

    const upcomingTransactions = Array.isArray(transactions) ?
        transactions.filter(t => t.amount !== 0).filter(t => isUpcoming(t.timestamp, t.status)) : [];

    const handleTransactionClick = (transaction) => {
        setSelectedTransaction(transaction);
    }

    const handleBackClick = () => {
        setSelectedTransaction(null);
        fetchAllUpcomingAndDue()
    }

    const TransactionList = ({ transactions, type }) => (
        <>
            <div style={popupStyles.sectionTitle}>
                {type === 'past-due' ? 'Past Due Payments' : 'Upcoming Payments'}
            </div>
            {transactions.length === 0 ? (
                <p className="text-gray-500 text-sm ml-2">No {type === 'past-due' ? 'past due' : 'upcoming'} payments</p>
            ) : (
                transactions.map((transaction, index) => (
                    <button
                        key={index}
                        style={{
                            ...popupStyles.transactionItem,
                            backgroundColor: (hoveredIndex === index) && (hoveredType === type) ? '#e9ecef' : popupStyles.transactionItem.backgroundColor,
                        }}
                        onMouseEnter={() => {
                            setHoveredIndex(index);
                            setHoveredType(type);
                        }}
                        onMouseLeave={() => {
                            setHoveredIndex(null);
                            setHoveredType(null);
                        }}
                        onClick={() => handleTransactionClick(transaction)}
                    >
                        <div style={popupStyles.transactionLeft}>
                            <div style={popupStyles.amount}>
                                {`${USDollar.format(transaction.amount)} (${transaction.type})`}
                            </div>
                            <div style={popupStyles.date}>
                                Due: {formatDate(transaction.timestamp)}
                            </div>
                            <div style={{
                                ...popupStyles.status,
                                backgroundColor: type === 'past-due' ? '#ffebee' : '#e8f5e9',
                                color: type === 'past-due' ? '#c62828' : '#2e7d32',
                            }}>
                                {type === 'past-due' ? 'Past Due' : 'Upcoming'}
                            </div>
                        </div>
                        <div style={popupStyles.transactionRight}>
                            <div style={popupStyles.accountList}>
                                {transaction.account_names.map((account, i) => (
                                    <span key={i} style={popupStyles.accountItem}>
                                        {account}
                                    </span>
                                ))}
                            </div>
                            <div style={popupStyles.investorList}>
                                {transaction.investor_info.map((investor, i) => (
                                    <span key={i} style={popupStyles.accountInfo}>
                                        {investor.name} - {investor.email}
                                    </span>
                                ))}
                            </div>
                        </div>
                    </button>
                ))
            )}
        </>
    );

    const PaymentPage = ({ transaction }) => {
        const [localStatus, setLocalStatus] = useState(transaction.status);
        const [isUpdating, setIsUpdating] = useState(false);


        useEffect(() => {
            setLocalStatus(transaction.status);
        }, [transaction.status]);

        const handlePaynowButton = async () => {
            if (isUpdating) return; // Prevent multiple clicks while updating

            setIsUpdating(true);
            const newStatus = localStatus === 'paid' ? 'unpaid' : 'paid';

            try {
                const updatedStatus = await setPaymentStatus(transaction.id, newStatus);
                setLocalStatus(updatedStatus);
                const { emails, names } = await getInvestorNameEmail(transaction.investment_id);
                await sendEmail(updatedStatus === "paid" ? "Pay" : "Unpay",
                    transaction.amount, emails, names, transaction.type);
            } catch (error) {
                console.error('Failed to toggle payment status:', error);
                // Optionally show an error message to the user
            } finally {
                setIsUpdating(false);
            }
        };

        return (
            <div style={popupStyles.paymentDetailsContainer}>
                <button
                    style={popupStyles.backButton}
                    onClick={handleBackClick}
                >
                    Back
                </button>
                <h3>Payment Details</h3>
                <div style={popupStyles.amount}>
                    Amount: {`${USDollar.format(transaction.amount)} (${transaction.type})`}
                </div>
                <div style={popupStyles.date}>
                    Due: {formatDate(transaction.timestamp)}
                </div>
                <div style={popupStyles.status}>
                    Status: {localStatus === 'unpaid' ? 'Unpaid' : 'Paid'}
                </div>
                <div>
                    <button
                        style={popupStyles.payButton}
                        onClick={handlePaynowButton}
                    >
                        {localStatus === 'paid' ? 'Unpay' : 'Pay Now'}
                    </button>
                </div>
                <div>
                    <button
                        style={popupStyles.closeButtonPaymentPage}
                        onClick={() => setShowPopup(false)}
                    >
                        Close
                    </button>
                </div>
            </div>
        );
    };

    return showPopup ? (
        <div style={popupStyles.overlay}>
            <div style={popupStyles.popupContent}>
                <h2 className="text-xl font-bold mb-4">
                    Payment Reminders
                </h2>

                {isLoading ? (
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '160px'
                    }}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 50 50"
                            style={{ width: '40px', height: '40px' }}
                        >
                            <circle
                                cx="25"
                                cy="25"
                                r="20"
                                stroke="black"
                                strokeWidth="5"
                                strokeLinecap="round"
                                fill="none"
                                strokeDasharray="80, 100"
                            >
                                <animateTransform
                                    attributeName="transform"
                                    type="rotate"
                                    from="0 25 25"
                                    to="360 25 25"
                                    dur="0.8s"
                                    repeatCount="indefinite"
                                />
                            </circle>
                        </svg>
                    </div>
                ) : selectedTransaction ? (
                    <PaymentPage transaction={selectedTransaction} />
                ) : (
                    <>
                        <TransactionList
                            transactions={pastDueTransactions}
                            type="past-due"
                        />
                        <TransactionList
                            transactions={upcomingTransactions}
                            type="upcoming"
                        />
                    </>
                )}
                {!selectedTransaction &&
                    <div className="text-center mt-4">
                        <button
                            style={popupStyles.closeButton}
                            onClick={() => setShowPopup(false)}
                        >
                            Close
                        </button>
                    </div>
                }
            </div>
        </div>
    ) : null;
}

export default HintPopup;