import { useEffect, useState, useContext } from "react"
import api from "api"
import { get } from "utils/fetch";
import { message, Spin, Input, Switch } from "antd";
import { InvestmentInsertion } from "pages/RecordInsertion/InvestmentInsertion";
import { investmentStructToName, larkInvestmentStructToName } from "utils/util";
import { isPhoneSize, isAdmin, isSale } from "utils/util";
import { GlobalContext } from 'GlobalContext';
import { LarkInvestmentInsertion } from "pages/RecordInsertion/LarkInvestmentInsertion";

export function InvestmentManager() {
    const { setManageRecordId, manageRecordId, accessToken, globalId } = useContext(GlobalContext)
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(true)
    const [investments, setInvestments] = useState([])
    const [funds, setFunds] = useState([])
    // const [holdingCompanies, setHoldingCompanies] = useState([])
    const [investmentId, setInvestmentId] = useState(0) // 0: main page
    const [filteredText, setFilteredText] = useState("")
    const [unsyncedTransactionRequests, setUnsyncedTransactionRequests] = useState([])
    const [showAllSources, setShowAllSources] = useState(true) // lark and database | database
    const [source, setSource] = useState("Database") // Database: default source; Lark: detail of unsynced investment in approval -> lark table
    console.log(source)
    const displayErrorMessage = (message) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };

    async function fetchInvestments() {
        await get(api.manager.investments + `?sale_id=${globalId}`, setInvestments, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            }), accessToken
        )
        if (isSale()) {
            setLoading(false)
        }
        setLoading(false)
    }

    async function fetchUnsyncedTransactionRequests() {
        await get(api.lark.unsyncedTransactionRequests, setUnsyncedTransactionRequests, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            }), accessToken
        )
        setLoading(false)
    }

    async function fetchFunds() {
        await get(api.manager.funds, setFunds, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            }), accessToken
        )
        setLoading(false)
    }

    function handleSourcesSwitchChange(state) {
        setShowAllSources(state)
    }

    useEffect(() => {
        if (manageRecordId !== 0) {
            setLoading(false)
        }
        setFilteredText("")
        if (manageRecordId !== 0) return
        //console.log("loading")
        setLoading(true)
        // if (isAdmin()) {
        //     fetchUnsyncedTransactionRequests()
        // }
        fetchInvestments()
        // fetchFunds()
    }, [manageRecordId])

    // console.log(unsyncedTransactionRequests)

    return <>
        {loading && <div style={{ marginTop: "30px", textAlign: "center" }}><Spin /></div>}
        {!loading && manageRecordId === 0 && <>
            <div style={{ width: "300px", marginTop: "30px", marginLeft: "20px" }}>
                <Input
                    onChange={e => setFilteredText(e.target.value.trim().toLowerCase())}
                    placeholder="Filter on investment"
                />
            </div>

            {isAdmin() && <div style={{ margin: "20px" }}>
                <button style={{
                    height: "30px",
                    width: "50px",
                    borderRadius: "30px",
                    background: "rgb(17 32 86)",
                    color: "white",
                    cursor: "pointer",
                }}
                    onClick={() => setManageRecordId(-1)}>
                    Add
                </button>
            </div>}

            <div style={{ fontSize: "20px", margin: "20px" }}>
                Data Source: <Switch
                    // className="investment-status-switch"
                    checkedChildren="All"
                    unCheckedChildren="Database"
                    onChange={handleSourcesSwitchChange}
                    checked={showAllSources} />
            </div>

            {unsyncedTransactionRequests.length > 0 && showAllSources && <div style={isPhoneSize() ? {
                fontSize: "20px",
                margin: "20px",
                width: "fit-content",
            } : {
                fontSize: "20px",
                margin: "20px",
                width: "fit-content",
            }}
            // onClick={() => setManageRecordId(investment.investment_id)}
            >
                From Lark
                {unsyncedTransactionRequests
                    .sort((a, b) => {
                        if (larkInvestmentStructToName(a) < larkInvestmentStructToName(b)) {
                            return -1;
                        }
                        return 1
                    })
                    .map((investment, idx) => {
                        return <div key={idx} style={isPhoneSize() ? {
                            fontSize: "20px",
                            margin: "20px 0",
                            width: "fit-content",
                            color: "gray",
                            textDecoration: "underline",
                            cursor: "pointer",
                        } : {
                            fontSize: "20px",
                            margin: "20px 0",
                            width: "fit-content",
                            color: "gray",
                            textDecoration: "underline",
                            cursor: "pointer",
                        }}
                            onClick={() => {
                                setManageRecordId(investment.fields['Request No.'].text)
                                setSource("Lark")
                            }}
                        >
                            {larkInvestmentStructToName(investment)}
                        </div>
                    })}
            </div>}

            {investments.length > 0 &&
                <div style={isPhoneSize() ? {
                    fontSize: "20px",
                    margin: "20px",
                    width: "fit-content",
                } : {
                    fontSize: "20px",
                    margin: "20px",
                    width: "fit-content",
                }}
                >
                    From Database
                    {investments
                        .filter(investment =>
                            investmentStructToName(investment) && investmentStructToName(investment).toLowerCase().includes(filteredText))
                        .sort((a, b) => {
                            if (investmentStructToName(a) < investmentStructToName(b)) {
                                return -1;
                            }
                            return 1
                        })
                        .map((investment, idx) => {
                            return <div key={idx} style={isPhoneSize() ? {
                                fontSize: "20px",
                                margin: "20px 0",
                                width: "fit-content",
                                color: "blue",
                                textDecoration: "underline",
                                cursor: "pointer",
                            } : {
                                fontSize: "20px",
                                margin: "20px 0",
                                width: "fit-content",
                                color: "blue",
                                textDecoration: "underline",
                                cursor: "pointer",
                            }}
                                onClick={() => {
                                    setManageRecordId(investment.investment_id)
                                    setSource("Database")
                                }}
                            >
                                {investmentStructToName(investment)}
                            </div>
                        })}
                </div>
            }

        </>
        }
        {!loading && source === "Lark" && manageRecordId !== 0 && <LarkInvestmentInsertion setSource={setSource} />}
        {!loading && source === "Database" && manageRecordId !== 0 && <InvestmentInsertion />}
    </>
}