import React, { createContext, useState } from 'react';

// Create the context
export const GlobalContext = createContext();

// Create a provider component
export const GlobalProvider = ({ children }) => {
    const [globalEmail, setGlobalEmail] = useState("");
    const [manageRecordTab, setManageRecordTab] = useState("Investors")
    const [manageRecordId, setManageRecordId] = useState(0)

    return (
        <GlobalContext.Provider value={{
            globalEmail,
            setGlobalEmail,
            manageRecordTab,
            setManageRecordTab,
            manageRecordId,
            setManageRecordId
        }}>
            {children}
        </GlobalContext.Provider>
    );
};
