import { Button, Card } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import React from 'react';
import { getTypeBackgroundColor } from 'utils/util';
import viewMoreShape from "../../assets/img/viewMoreShape.png"

type OfferingTileProps = {
    title: string
    description: string
    link: string
    imgSrc: string
    type: string
    disabled?: boolean
    amount?: string
    fraction?: string
    openStatus?: boolean
}

function getTypeTagWidth(type: string) {
    if (type.toLowerCase() === "debt" || type.toLowerCase() === "equity") {
        return "50px"
    }
    return "80px"
}

const OfferingTile: React.FC<OfferingTileProps> = ({
    title,
    type,
    description,
    link,
    imgSrc,
    disabled,
    amount,
    fraction,
    openStatus
}) => {
    console.log("openStatus", openStatus)
    return <div>
        {openStatus !== undefined && openStatus !== null && <div style={{ display: 'flex', justifyContent: "right", marginRight: "7px" }}>
            {
                type && <div style={{
                    background: openStatus ? "#28a745" : "#dc3545",
                    color: "white",
                    display: "flex",
                    width: openStatus ? "50px" : "60px",
                    justifyContent: "center",
                    fontWeight: 'bold',
                    borderRadius: "5px 5px 0 0"
                }}>
                    {openStatus ? "Open" : "Closed"}
                </div>
            }
        </div>}
        <Card style={{
            width: 200,
            cursor: "default",
            background: "#F7F7F9"
        }} styles={{
            title: {
                color: disabled ? 'gray' : 'auto',
            },
            body: {
                height: amount ? 280 : 250,
                display: 'flex',
                flexDirection: 'column',
                cursor: disabled ? 'not-allowed' : 'default',
            }
        }}
            // title={title + (disabled ? ' (Inactive)' : '')}
            hoverable={!disabled} bordered={false}>

            <div style={{
                // backgroundImage: 'url(' + imgSrc + ')' + (disabled ? ', linear-gradient(to bottom, grey, grey)' : ''),
                // backgroundPosition: 'center',
                // backgroundRepeat: 'no-repeat',
                width: '100%',
                height: 100,
            }} >
                <img style={{ width: "100%", height: '100%' }} src={imgSrc}></img>
            </div>
            {/* <Paragraph ellipsis={{ rows: 3, expandable: false, symbol: 'more' }}
                style={{ width: '100%', color: disabled ? 'gray' : 'inherit' }}>
                {description}
            </Paragraph> */}

            <div style={{ fontFamily: "Arial", fontSize: "17px", fontWeight: "normal", lineHeight: "24px", display: "flex", letterSpacing: "0em", padding: "5px" }}>
                {title}
            </div>

            <div style={{ display: "flex", flexDirection: "row", paddingLeft: "5px" }}>
                {type.split('/').map((curType, idx) => <div key={idx} style={{
                    width: "45px",
                    height: "20px",
                    fontFamily: "PingFang SC",
                    fontSize: "12px",
                    fontWeight: "600",
                    lineHeight: "18px",
                    letterSpacing: "0em",
                    color: "#FFFFFF",
                    background: getTypeBackgroundColor(curType),
                    borderRadius: "4px",
                    alignContent: "center",
                    textAlign: "center",
                    marginRight: "10px"
                }}>
                    {curType}
                </div>)}
            </div>

            {/* <Button style={{ width: '100%', margin: 'auto' }}
                type="primary"
                disabled={disabled}
                onClick={() => window.open(link, '_blank', 'noopener,noreferrer')}
            >
                View More
            </Button> */}

            <div style={{ placeItems: "center", padding: "12px 10px" }}>
                <div style={{ display: "flex", alignItems: "center", width: "166px", height: "25px", background: "white", placeContent: "center", borderRadius: "8px", cursor: "pointer" }}
                    onClick={() => window.open(link, '_blank', 'noopener,noreferrer')}
                >
                    <div style={{ fontFamily: "Arial", fontSize: "14px", fontWeight: "normal", lineHeight: "18px", letterSpacing: "0em", color: "#1777FF" }}>
                        {"View More"}
                    </div>
                    <img style={{ height: "12px", width: "6.6px", marginLeft: "10px" }} src={viewMoreShape} alt="" />
                </div>
            </div>
            {/* {openStatus === true ? "Open" : openStatus === false ? "Closed" : null} */}
            {amount && <div style={{paddingLeft: "5px"}}>{`${amount} (${fraction})`}</div>}
            {/* {fraction && <div>{fraction}</div>} */}
        </Card>
    </div>
}

export default OfferingTile