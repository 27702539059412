import _ from 'lodash';
import React, { useEffect, useState, useContext } from 'react';
import { Divider, message, Tabs, AutoComplete, Input, Layout } from "antd";
import { InvestorData } from 'types/investor';

import api from 'api';
import './investor.css';
import InvestedProperties from './InvestedProperties';
import BasicInfo from './BasicInfo';
import InvestmentHistory from './InvestmentHistory';
import InvestmentBreakdown from './InvestmentBreakdown';
import DebtPayouts from './DebtPayouts';
import { get } from 'utils/fetch';
import { useSearchParams } from 'react-router-dom';
import { GlobalContext } from 'GlobalContext';

const Investor: React.FC = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { accessToken } = useContext(GlobalContext)
    const [investors, setInvestors] = useState<InvestorData[]>([]);
    const [investorNameIDInputValue, onInvestorNameIDInputValueChange] = useState<string>('');
    const [investorNameID, setInvestorNameID] = useState<string>('');
    const [fundID, setFundID] = useState<number>(0);
    const [activeTabKey, setActiveTabKey] = React.useState('basic_info');
    const [messageApi, contextHolder] = message.useMessage();

    const displayErrorMessage = (message: string) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };

    async function fetchInvestors() {
        await get(api.investor.list, setInvestors, (errors: any[]) => errors.forEach(err => {
            displayErrorMessage(err.message);
        }), accessToken);
    }

    const tabItems = [
        {
            key: 'basic_info',
            label: 'Basic Info',
            children: <BasicInfo fund_id={fundID} nameid={investorNameID} />,
        },
        {
            key: 'investment_breakdown',
            label: 'Current Investments (Active) with PnL',
            children: <InvestmentBreakdown fund_id={fundID} nameid={investorNameID} />,
        },
        {
            key: 'investment_history',
            label: 'Investment History',
            children: <InvestmentHistory fund_id={fundID} nameid={investorNameID} />,
        },
        {
            key: 'invest_property',
            label: 'Invested Properties',
            children: <InvestedProperties fund_id={fundID} nameid={investorNameID} />,
        },
        {
            key: 'debt_record',
            label: 'Debt Payout Record',
            children: <DebtPayouts fund_id={fundID} nameid={investorNameID} />,
        },
    ]

    useEffect(() => {
        fetchInvestors();
    }, []);

    useEffect(() => {
        if (searchParams.get('nameid')) {
            if (investors.find(i => i.nameid === searchParams.get('nameid'))) {
                if (tabItems.find(i => i.key === searchParams.get('tabKey'))) {
                    setActiveTabKey(searchParams.get('tabKey') || 'basic_info');
                } else {
                    setActiveTabKey('basic_info');
                }
                setInvestorNameID(searchParams.get('nameid') || '');
                onInvestorNameIDInputValueChange(searchParams.get('nameid') || '');
            } else if (investors.length > 0) {
                messageApi.error("Please enter a valid investor name ID");
            }
        }
    }, [searchParams, investors]);

    const onNameIDSearch = (value: string) => {
        if (!investors.find(x => x.nameid === value)) {
            messageApi.error("Please enter a valid investor name ID");
            return;
        }
        setInvestorNameID(value);
    }

    return <Layout>
        {contextHolder}
        <h3>Select an investor to view</h3>
        <div className="investor-choose">
            <AutoComplete
                value={investorNameIDInputValue}
                onChange={onInvestorNameIDInputValueChange}
                options={investors.map((x) => ({
                    value: x.nameid,
                    label: `${x.legal_name} (${x.nameid})`,
                })).filter((x) => x.label.toLowerCase().includes(
                    investorNameIDInputValue.trim().toLowerCase())
                )}
                size="large"
            >
                <Input.Search onSearch={onNameIDSearch} size="large" placeholder="Search investor name ID" />
            </AutoComplete>
        </div>
        <Divider />
        <div className='tabs'>
            <Tabs
                tabBarStyle={{ width: '100%' }}
                defaultActiveKey="basic_info"
                tabPosition="top"
                activeKey={activeTabKey}
                onChange={(val) => setActiveTabKey(val)}
                items={tabItems}
            />
        </div>
    </Layout>
}

export default Investor;
