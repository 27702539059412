import backArrow from "../../assets/img/backArrow.png"
import { getFileIcon, convertAndFormatDatetime } from "utils/util"

export function EmailDetailed(props) {
    const { setEmailId, email } = props

    const entryStyle = {
        margin: "20px",
        fontSize: '20px'
    }
    // console.log(email)
    return <><div style={{ margin: "20px", display: "flex", justifyContent: "space-between" }}>
        <img style={{ width: "15px", height: "20px", cursor: "pointer" }}
            src={backArrow}
            onClick={() => setEmailId(0)}
        ></img>

    </div>
        <div style={{}}>
            <div style={{ display: "flex", flexDirection: "row", ...entryStyle }}>
                To: {
                    <div style={{ marginLeft: "5px" }}>
                        {
                            email.recipients.map((recipient, id) =>
                                <div key={id}>
                                    {id > 0 && <div>,</div>}
                                    {" " + recipient.recipient_email}
                                    {" " + recipient.recipient_name}
                                </div>)
                        }
                    </div>
                }
            </div>

            <div style={entryStyle}>
                {email.subject}
            </div>

            {/* <div style={entryStyle} dangerouslySetInnerHTML={{ __html: contentWithBr }}>
                {email.content}
            </div> */}

            <div style={entryStyle} dangerouslySetInnerHTML={{ __html: email.content }} />

            <div style={entryStyle}>
                On {convertAndFormatDatetime(email.created_at + "Z")}
            </div>

            <div style={entryStyle}>
                {
                    email.attachments.map((attachment, id) => {
                        return <a key={id} href={attachment.file_path} target="_blank" rel="noreferrer noopener">
                            <div key={id} style={{ display: "flex", alignItems: "center", fontSize: "15px", paddingLeft: "50px" }}>
                                <div style={{ padding: "5px", paddingBottom: "0" }}>
                                    {getFileIcon(attachment.file_path)}
                                    {/* <img src={PdfIcon} style={{ width: "15px" }}></img> */}
                                </div>
                                {attachment.file_path.substring(attachment.file_path.lastIndexOf('/') + 1)}
                            </div>
                        </a>
                    })
                }
            </div>
        </div>
    </>
}