import { Button, Layout, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useFiefAuth, useFiefIsAuthenticated, useFiefUserinfo } from '@fief/fief/react';
import React, { useCallback, useState } from 'react';
import { LogoutConfirmation } from './LogoutConfirmation';

export function MenuLogout() {
    const [showLogoutBox, setShowLogoutBox] = useState(false)
    const navigate = useNavigate();
    const onLogoutButtonClicked = () => {
        localStorage.removeItem('token');
        navigate('/login')
    }
    const fiefAuth = useFiefAuth();
    const isAuthenticated = useFiefIsAuthenticated();
    const userinfo = useFiefUserinfo();
    const logout = useCallback(() => {
        //console.log(`${window.location.protocol}//${window.location.host}`)
        fiefAuth.logout(`${window.location.protocol}//${window.location.host}`);
    }, [fiefAuth]);

    return <>
        {showLogoutBox &&
            <LogoutConfirmation
                title={`Are you sure you want to logout?`}
                setShowLogoutBox={setShowLogoutBox}
                logoutCallback={logout}
            />
        }
        <Layout>
            <Row style={{ placeContent: "center", background: "white" }}>
                {isAuthenticated && userinfo && (
                    <Button style={{ background: "red" }} type="primary" onClick={() => {
                        setShowLogoutBox(true)
                    }}>Logout</Button>
                )}
            </Row>
        </Layout>
    </>
}