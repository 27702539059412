import { useEffect, useState, useContext } from "react"
import { isAdmin, isPhoneSize, getFileIcon, truncateText, convertAndFormatDatetime } from "utils/util"
import { Spin, message, Select } from "antd";
import api from "api";
import { get } from "utils/fetch";
import PdfIcon from "../../assets/img/PdfIcon.png"
import forwardArrow from "../../assets/img/forwardArrow.png"
import backArrow from "../../assets/img/backArrow.png"
import forwardArrowGray from "../../assets/img/forwardArrowGray.png"
import backArrowGray from "../../assets/img/backArrowGray.png"
import { EmailDetailed } from "./EmailDetailed";
import { GlobalContext } from 'GlobalContext';

export function EmailHistory(props) {

    const defaultUserEmail = "Choose User"
    const { accessToken } = useContext(GlobalContext)
    const [emails, setEmails] = useState([])
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(true)
    const [loadingEmail, setLoadingEmail] = useState(false)
    const [allUsers, setAllUsers] = useState([])
    const [userEmail, setUserEmail] = useState(defaultUserEmail)
    // 0 to view all emails, >= 0 to view email where email.id = email.id
    const [emailId, setEmailId] = useState(0)
    // display 10 email histories at a time
    const pageSize = 10
    const [curPage, setCurPage] = useState(0)
    const [totalEmailCount, setTotalEmailCount] = useState(0)
    const displayErrorMessage = (message) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };

    useEffect(() => {
        async function getAllUsers() {
            await get(
                api.investor.list,
                setAllUsers,
                (errors) => errors.forEach(err => {
                    displayErrorMessage(err.message);
                }), accessToken
            );
        }
        if (!isAdmin()) return
        getAllUsers()
    }, [])

    async function fetchTotalEmailCount(userEmail) {
        await get(api.email.totalEmailCount(userEmail), setTotalEmailCount, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            }), accessToken
        )
    }

    async function fetchEmailsPaged(pageSize, curPage, userEmail) {
        await get(api.email.emailsPaged(pageSize, curPage, userEmail), setEmails, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            }), accessToken
        )
        setLoading(false)
        setLoadingEmail(false)
    }

    useEffect(() => {
        // console.log("userEmail", userEmail)
        fetchTotalEmailCount(userEmail)
        fetchEmailsPaged(pageSize, curPage, userEmail)
    }, [pageSize, curPage, userEmail])

    function shortenFileNameForDisplay(filename) {
        filename.split(".")
        return filename
    }
    // console.log(emails)
    return <>
        {!isAdmin() && <div>Not Authorized</div>}
        {loading && isAdmin() && <div style={{ marginTop: "30px", textAlign: "center", height: "100vh" }}><Spin /></div>}
        {emailId > 0 && <EmailDetailed setEmailId={setEmailId} email={emails.filter(email => email.id === emailId)[0]} />}
        {!loading && isAdmin() && emailId === 0 &&
            <div style={{
                width: "400px",
                height: "100vh",
                // overflowY: "scroll",
                // scrollbarWidth: "none"
            }}>
                <div style={{ margin: "50px 0" }}>
                    <div style={{
                        height: "30px",
                        border: "1px solid black",
                        display: "flex",
                        alignItems: "center"
                    }}>
                        <div style={{ flex: "1", display: "flex", flexDirection: "row" }}>
                            <div style={{ marginLeft: "10px" }}>
                                {`${emails.length == 0 ? 0 : curPage * pageSize + 1} - ${Math.min(curPage * pageSize + pageSize, totalEmailCount)} / ${totalEmailCount}`}
                            </div>

                            {curPage === 0 && <div style={{ alignSelf: "center", marginLeft: "10px" }}>
                                <img style={{ width: "10px", height: "10px" }}
                                    src={backArrowGray}
                                ></img>
                            </div>}

                            {curPage > 0 && <div style={{ alignSelf: "center", marginLeft: "10px" }}>
                                <img style={{ width: "10px", height: "10px", cursor: "pointer" }}
                                    src={backArrow}
                                    onClick={() => {
                                        setLoadingEmail(true)
                                        setCurPage(curPage - 1)
                                    }}
                                ></img>
                            </div>}

                            {curPage * pageSize + emails.length >= totalEmailCount &&
                                <div style={{ alignSelf: "center", marginLeft: "10px" }}>
                                    <img style={{ width: "10px", height: "10px" }}
                                        src={forwardArrowGray}
                                    ></img>
                                </div>}

                            {curPage * pageSize + emails.length < totalEmailCount &&
                                <div style={{ alignSelf: "center", marginLeft: "10px" }}>
                                    <img style={{ width: "10px", height: "10px", cursor: "pointer" }}
                                        src={forwardArrow}
                                        onClick={() => {
                                            setLoadingEmail(true)
                                            setCurPage(curPage + 1)
                                        }}
                                    ></img>
                                </div>}
                        </div>

                        <div style={{ marginRight: "10px" }}>
                            <Select
                                showSearch
                                optionFilterProp="label"
                                onChange={(e) => {
                                    setLoading(true)
                                    setCurPage(0)
                                    setUserEmail(e)
                                }}
                                // placeholder="Choose user"
                                value={userEmail}
                                style={{ width: '150px', background: "transparent" }}
                                filterOption={(input, option) =>
                                    option?.label.toLowerCase().includes(input.toLowerCase())
                                }
                                // optionFilterProp="children"
                                options={[
                                    {
                                        value: defaultUserEmail,
                                        label: defaultUserEmail
                                    },
                                    ...allUsers.map((user) => {
                                        return {
                                            value: user.email,
                                            label: `${user.legal_name} (${user.email})`
                                        }
                                    })
                                ]}
                            >
                            </Select>
                        </div>

                    </div>
                    {loadingEmail && <div style={{ textAlign: "center", marginTop: "20px" }}><Spin /></div>}
                    {!loadingEmail && <div style={{
                        overflowY: "scroll",
                        scrollbarWidth: "none",
                        maxHeight: "calc(100vh - 110px)",
                        borderBottom: "1px solid black"
                    }}>
                        {emails.map((email, id) => <div key={id} style={{
                            border: "1px solid black",
                            fontSize: "15px",
                            borderTop: "",
                            borderBottom: id === emails.length - 1 ? "" : "1px solid black",
                            padding: "10px 20px",
                            display: "flex",
                            flexDirection: "row"
                        }}>
                            <div style={{ width: "300px" }}>
                                <div style={{ display: "flex", flexDirection: "row", margin: "5px 0" }}>
                                    To: {
                                        <div style={{ marginLeft: "5px" }}>
                                            {
                                                email.recipients.map((recipient, id) =>
                                                    <div key={id}>
                                                        {id > 0 && <div>,</div>}
                                                        {" " + recipient.recipient_email}
                                                        {" " + recipient.recipient_name}
                                                    </div>)
                                            }
                                        </div>
                                    }
                                </div>

                                <div style={{ margin: "5px 0" }}>
                                    Subject: {truncateText(email.subject, 35)}
                                </div>

                                <div style={{ margin: "5px 0" }}>
                                    Content: {truncateText(email.content.replace(/<br>/g, ""), 38)}
                                </div>

                                {/* <div style={{ margin: "5px 0" }} dangerouslySetInnerHTML={{ __html: email.content }} /> */}

                                <div style={{ margin: "5px 0" }}>
                                    Sent At: {convertAndFormatDatetime(email.created_at + "Z")}
                                </div>

                                <div style={{ margin: "5px 0" }}>
                                    Attachments: {
                                        email.attachments.map((attachment, id) => {
                                            return <a key={id} href={attachment.file_path} target="_blank" rel="noreferrer noopener">
                                                <div key={id} style={{ display: "flex", alignItems: "center", fontSize: "15px", paddingLeft: "50px" }}>
                                                    <div style={{ padding: "5px", paddingBottom: "0" }}>
                                                        {getFileIcon(attachment.file_path)}
                                                        {/* <img src={PdfIcon} style={{ width: "15px" }}></img> */}
                                                    </div>
                                                    {truncateText(attachment.file_path.substring(attachment.file_path.lastIndexOf('/') + 1), 32)}
                                                </div>
                                            </a>
                                        })
                                    }
                                </div>
                            </div>

                            <div style={{ alignSelf: "center", marginLeft: "30px" }}>
                                <img style={{ width: "15px", height: "20px", cursor: "pointer" }}
                                    src={forwardArrow}
                                    onClick={() => setEmailId(email.id)}
                                ></img>
                            </div>
                        </div>)}
                    </div>}
                </div>
            </div>
        }
    </>
}