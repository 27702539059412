import { useEffect, useState, useContext } from "react"
import api from "api"
import { get } from "utils/fetch";
import { message, Spin, Input } from "antd";
import { InvestmentInsertion } from "pages/RecordInsertion/InvestmentInsertion";
import { investmentStructToName } from "utils/util";
import { isPhoneSize } from "utils/util";
import { GlobalContext } from 'GlobalContext';

export function InvestmentManager() {
    const { setManageRecordId, manageRecordId } = useContext(GlobalContext)
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(true)
    const [investments, setInvestments] = useState([])
    const [funds, setFunds] = useState([])
    // const [holdingCompanies, setHoldingCompanies] = useState([])
    const [investmentId, setInvestmentId] = useState(0) // 0: main page
    const [filteredText, setFilteredText] = useState("")

    const displayErrorMessage = (message) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };

    async function fetchInvestments() {
        await get(api.manager.investments, setInvestments, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            })
        )
        setLoading(false)
    }

    async function fetchFunds() {
        await get(api.manager.funds, setFunds, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            })
        )
        setLoading(false)
    }

    useEffect(() => {
        if (manageRecordId !== 0) {
            setLoading(false)
        }
        setFilteredText("")
        if (manageRecordId !== 0) return
        //console.log("loading")
        setLoading(true)
        fetchInvestments()
        fetchFunds()
    }, [manageRecordId])
    return <>
        {loading && <div style={{ marginTop: "30px", textAlign: "center" }}><Spin /></div>}
        {!loading && manageRecordId === 0 && <>
            <div style={{ width: "300px", marginTop: "30px", marginLeft: "20px" }}>
                <Input
                    onChange={e => setFilteredText(e.target.value.trim().toLowerCase())}
                    placeholder="Filter on investment"
                />
            </div>

            <div style={{ margin: "20px" }}>
                <button style={{
                    height: "30px",
                    width: "50px",
                    borderRadius: "30px",
                    background: "rgb(17 32 86)",
                    color: "white",
                    cursor: "pointer",
                }}
                    onClick={() => setManageRecordId(-1)}>
                    Add
                </button>
            </div>

            {investments
                .filter(investment =>
                    investmentStructToName(investment) && investmentStructToName(investment).toLowerCase().includes(filteredText))
                .sort((a, b) => {
                    if (investmentStructToName(a) < investmentStructToName(b)) {
                        return -1;
                    }
                    return 1
                })
                .map((investment, idx) => {
                    return <div key={idx} style={isPhoneSize() ? {
                        fontSize: "20px",
                        margin: "20px",
                        width: "fit-content",
                        color: "blue",
                        textDecoration: "underline",
                        cursor: "pointer",
                    } : {
                        fontSize: "20px",
                        margin: "20px",
                        width: "fit-content",
                        color: "blue",
                        borderBottom: "1px solid blue",
                        cursor: "pointer",
                    }}
                        onClick={() => setManageRecordId(investment.investment_id)}
                    >
                        {investmentStructToName(investment)}
                    </div>
                })}

        </>
        }
        {!loading && manageRecordId > 0 && <>
            <InvestmentInsertion
            // investment={investments.filter(investment => investment.investment_id === investmentId)[0]}
            // setInvestmentId={setInvestmentId}
            // funds={funds}
            />
        </>}
        {!loading && manageRecordId < 0 && <>
            <InvestmentInsertion
            // investment={null}
            // setInvestmentId={setInvestmentId}
            // funds={funds}
            />
        </>}
    </>
}