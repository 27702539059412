import backArrow from "../../assets/img/backArrow.png"
import { useEffect, useState } from "react";
import { getMimeType, getFileIcon } from "utils/util";
import api from "api";
import { Spin, message } from "antd";
import { get } from "utils/fetch";
import { DeleteConfirmation } from "components/DeleteConfirmation";

export function InvestmentFileManager(props) {
    const { setView, investmentId } = props
    const [messageApi, contextHolder] = message.useMessage();
    const [existingFiles, setExistingFiles] = useState([]) // files already in S3
    const [files, setFiles] = useState([]); // files newly uploaded
    const [loading, setLoading] = useState(true)
    const [fileNameToDelete, setFileNameToDelete] = useState("")
    const [showDeletionBox, setShowDeletionBox] = useState(false)

    const displayErrorMessage = (message) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };

    async function fetchFiles() {
        await get(api.file.fetchInvestmentFiles(investmentId), setExistingFiles, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            })
        )
        setLoading(false)
    }

    useEffect(() => {
        fetchFiles()
    }, [])


    const triggerFileInput = () => {
        document.getElementById('fileInput').click();  // Trigger file input click
    };
    console.log(files)
    const handleFileChange = (e) => {
        var newFiles = []
        for (var i = 0; i < e.target.files.length; i++) {
            const curFile = e.target.files[i]
            if (curFile) {
                // Generate a temporary URL for the file
                const tempUrl = URL.createObjectURL(curFile);
                newFiles.push({
                    filename: curFile.name,
                    url: tempUrl,
                    blob: curFile
                }); // Allow multiple files to be uploaded
            }
        }
        setFiles([...files, ...newFiles])
    };

    async function saveFiles() {
        if (files.length === 0) {
            return
        }
        setLoading(true)
        // Create a FormData object
        const formData = new FormData();
        // formData.append('files', files.map(file => new File([file.blob], file.filename, { type: 'application/pdf' })))
        files.map(file => formData.append('files', new File([file.blob], file.filename, { type: getMimeType(file.filename) })))

        formData.append('investmentId', investmentId);
        setFiles([])
        // Send the files
        const emailResponse = await fetch(api.file.addInvestmentFiles, {
            method: 'POST',
            body: formData
        });
        fetchFiles()
        const emailResult = await emailResponse.json();
    }

    async function deletionCallback() {
        const options = {
            method: "DELETE", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ investmentId, fileNameToDelete })
        }
        const result = await fetch(api.file.deleteInvestmentFiles, options);
        // const data = await result.json()
        setLoading(true)
        setShowDeletionBox(false)
        fetchFiles()
    }

    return <>
        <div>
            <div style={{ margin: "20px" }}>
                <img style={{ width: "15px", height: "20px", cursor: "pointer" }}
                    src={backArrow}
                    onClick={() => setView("investment")}
                ></img>
            </div>
        </div>
        {loading && <div style={{ marginTop: "30px", textAlign: "center" }}><Spin /></div>}
        {!loading && <>

            {showDeletionBox &&
                <DeleteConfirmation
                    title={`File: ${fileNameToDelete}`}
                    setShowDeletionBox={setShowDeletionBox}
                    deletionCallback={deletionCallback}
                />
            }

            <div style={{ alignSelf: "center", marginLeft: "10px" }}>
                <button style={{
                    height: "30px",
                    width: "70px",
                    borderRadius: "30px",
                    background: "rgb(17 32 86)",
                    color: "white",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    placeContent: "center"
                }}
                    onClick={triggerFileInput}
                >
                    Add File
                </button>
            </div>
            {existingFiles.map((url, id) => {
                return <div key={id} style={{ display: "flex", flexDirection: "row" }}>
                    <a href={url} key={id} target="_blank" rel="noreferrer noopener">
                        <div key={id} style={{ display: "flex", alignItems: "center", fontSize: "15px", color: "black" }}>
                            <div style={{ padding: "5px", paddingBottom: "0" }}>
                                {getFileIcon(url)}
                            </div>
                            {url.substring(url.lastIndexOf('/') + 1)}
                        </div>
                    </a>
                    <span style={{
                        marginLeft: "10px",
                        alignContent: "center",
                        fontWeight: "bold",
                        color: "#FF0000",
                        fontSize: "15px",
                        cursor: "pointer"
                    }} className="remove-btn" onClick={() => {
                        setFileNameToDelete(url.substring(url.lastIndexOf('/') + 1))
                        setShowDeletionBox(true)
                    }}>
                        &times;
                    </span>
                </div>
            })}
            {files && <div>
                {files.map((file, idx) =>
                    <div key={idx} style={{ display: "flex", flexDirection: "row" }}>
                        <a href={file.url} target="_blank" rel="noreferrer noopener">
                            {/* <iframe src={pdfUrl} width="100%" height="600px" title="PDF Preview" /> */}
                            <div style={{ display: "flex", alignItems: "center", fontSize: "15px", color: "black" }}>
                                <div style={{ padding: "5px", paddingBottom: "0", fontSize: "20px" }}>
                                    {getFileIcon(file.filename)}
                                    {/* <img src={PdfIcon} style={{ width: "14px" }}></img> */}
                                </div>
                                {file.filename}
                            </div>
                        </a>
                        <span style={{
                            marginLeft: "10px",
                            alignContent: "center",
                            fontWeight: "bold",
                            color: "#FF0000",
                            fontSize: "15px",
                            cursor: "pointer"
                        }} className="remove-btn" onClick={() => setFiles(files.filter((_, i) => i !== idx))}>
                            &times;
                        </span>
                    </div>
                )
                }
            </div>}

            <div style={{ margin: "20px", display: "flex", justifyContent: "space-between" }}>
                <button style={{
                    height: "30px",
                    width: "50px",
                    borderRadius: "30px",
                    background: "rgb(17 32 86)",
                    color: "white",
                    cursor: "pointer",
                }}
                    onClick={saveFiles}
                >
                    Save
                </button>
            </div>

            <input
                id="fileInput"
                type="file"
                multiple
                onChange={handleFileChange}
                style={{ display: "none" }}  // Hide the default file input
            />
        </>
        }
    </>
}