// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.email-input-container {
    width: 400px;
    /* Fixed width of the overall container */
    border: 1px solid #ccc;
    padding: 8px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    border-radius: 4px;
}

.emails {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.email-chip {
    display: flex;
    align-items: center;
    background-color: #f0f0f0;
    border-radius: 16px;
    padding: 0px 10px;
    margin: 5px 0px 3px 0;
    font-size: 14px;
    color: #333;
}

.email-chip .remove-btn {
    cursor: pointer;
    margin-left: 10px;
    font-weight: bold;
    color: #ff0000;
}

.email-input {
    border: none;
    outline: none;
    font-size: 14px;
    min-width: 20px;
    padding: 5px;
    flex-grow: 1;
    max-width: 100%;
    background: transparent;
    /* Ensure the input doesn't overflow */
}

.email-input::placeholder {
    color: #ccc;
}`, "",{"version":3,"sources":["webpack://./src/pages/Email/EmailInput.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,yCAAyC;IACzC,sBAAsB;IACtB,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,yBAAyB;IACzB,mBAAmB;IACnB,iBAAiB;IACjB,qBAAqB;IACrB,eAAe;IACf,WAAW;AACf;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,eAAe;IACf,eAAe;IACf,YAAY;IACZ,YAAY;IACZ,eAAe;IACf,uBAAuB;IACvB,sCAAsC;AAC1C;;AAEA;IACI,WAAW;AACf","sourcesContent":[".email-input-container {\n    width: 400px;\n    /* Fixed width of the overall container */\n    border: 1px solid #ccc;\n    padding: 8px;\n    display: flex;\n    align-items: center;\n    flex-wrap: wrap;\n    border-radius: 4px;\n}\n\n.emails {\n    display: flex;\n    align-items: center;\n    flex-wrap: wrap;\n}\n\n.email-chip {\n    display: flex;\n    align-items: center;\n    background-color: #f0f0f0;\n    border-radius: 16px;\n    padding: 0px 10px;\n    margin: 5px 0px 3px 0;\n    font-size: 14px;\n    color: #333;\n}\n\n.email-chip .remove-btn {\n    cursor: pointer;\n    margin-left: 10px;\n    font-weight: bold;\n    color: #ff0000;\n}\n\n.email-input {\n    border: none;\n    outline: none;\n    font-size: 14px;\n    min-width: 20px;\n    padding: 5px;\n    flex-grow: 1;\n    max-width: 100%;\n    background: transparent;\n    /* Ensure the input doesn't overflow */\n}\n\n.email-input::placeholder {\n    color: #ccc;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
