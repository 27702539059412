
export function IndexIndicator(props) {
    const { curIdx, size } = props
    console.log("IndexIndicator")
    return <div style={{ display: "flex", placeContent: "center", marginTop: "15px" }}>
        {Array(size)
            .fill()
            .map((_, index) =>
                <span key={index} style={{
                    width: "8px",
                    height: "8px",
                    margin: "0 7px",
                    borderRadius: "50%",
                    backgroundColor: index === curIdx ? "black" : "#ccc",
                    transition: "background-color 0.3s ease"
                }}>
                </span>
                // <div>
                //     {index}
                // </div>
            )}
    </div>
}