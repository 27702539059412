import React, { ReactNode } from 'react';
import { Card, Tooltip } from 'antd';
import { USDollar } from 'utils/format';

export type ContributionCardProps = {
    title: string
    amount: number
    activeAmount: number
    closedAmount: number
    isDollarFormat?: boolean
    tooltip?: string
    children?: ReactNode
}

export default ({
    title,
    amount,
    activeAmount,
    closedAmount,
    tooltip,
    children,
    isDollarFormat = true
}: ContributionCardProps) => {
    let amountFormatted = USDollar.format(amount);
    let fontSize = 16;
    // if (amountFormatted.length > 14) {
    //     fontSize = 20;
    // } else if (amountFormatted.length > 20) {
    //     fontSize = 16
    // }
    return <Card hoverable bordered={false} style={{ width: 220, height: 100 }}>
        <Tooltip title={tooltip}>
            <div>{title}</div>
            {isDollarFormat ? <div style={{ fontSize }}>{amountFormatted}</div> : <div style={{ fontSize }}>{amount}</div>}
            {children}
            <div>Active Contributions: </div>
            <div>{USDollar.format(activeAmount)}</div>
        </Tooltip>
    </Card>
}