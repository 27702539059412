import { Divider } from 'antd';
import { getFileIcon, convertAndFormatDatetime, isPhoneSize } from 'utils/util';
import "./Documents.css"
import expandFileIcon from "../../assets/img/expandFileIcon.png"
import { useState, useRef, useEffect } from 'react';

export function DocumentSubView(props) {
    const { titleName, files } = props
    const [isRotated, setIsRotated] = useState(true);
    const secondDivRef = useRef(null);
    const [maxHeight, setMaxHeight] = useState("0px");

    const handleClick = () => {
        setIsRotated(!isRotated); // Toggle rotation state
    };

    const dividerStyle = {
        margin: "0px"
    }

    const categoryStyle = {
        fontSize: "16px",
        marginLeft: "20px",
        padding: "10px 0",
        color: "#2683FF"
    }

    // Update maxHeight dynamically after render
    useEffect(() => {
        if (!isRotated) {
            setMaxHeight(`${secondDivRef.current?.scrollHeight}px`);
            console.log('false', `${secondDivRef.current?.scrollHeight}px`)
        } else {
            setMaxHeight("0px");
            console.log('true', `0px`)
        }
    }, [isRotated]);

    var documentCategories = new Set()
    for (const file of files) {
        if (!file.type) continue
        documentCategories.add(file.type)
    }

    return <>
        {files.length > 0 && <>
            <div>
                <div style={{ margin: "10px 0", display: "flex" }}>
                    <div onClick={handleClick} style={{ cursor: "pointer", cursor: "hover" }}>
                        <img style={{
                            marginRight: "10px", transform: isRotated ? "rotate(-90deg)" : "rotate(0deg)",
                            transition: "transform 0.3s ease", // Smooth rotation
                            marginBottom: "2px"
                        }} src={expandFileIcon} alt="" />
                    </div>
                    <div>
                        {titleName}
                    </div>
                </div>
                {/* <Divider style={dividerStyle} /> */}
            </div>
            <div
                ref={secondDivRef}
                style={{ background: "#F7F7F9", borderRadius: "12px", maxHeight: maxHeight, transition: "max-height 0.5s ease", overflow: "hidden" }}>
                <div style={{ overflowX: "auto", whiteSpace: "nowrap", width: "100%" }}>
                    {Array.from(documentCategories)
                        .map((category, cid) => <div key={cid}>
                            <div style={categoryStyle}>
                                {category}
                            </div>
                            {/* <Divider style={dividerStyle} /> */}
                            {/* get file of category category */}
                            {files.filter(file => file.type && file.type === category)
                                .map((file, id) => {
                                    return <div key={id}>
                                        <div key={id} style={isPhoneSize() ? {
                                            display: "flex", alignItems: "center", fontSize: "15px",
                                        } : {
                                            display: "flex", alignItems: "center", fontSize: "15px", paddingLeft: "0px", marginLeft: "10px"
                                        }}>
                                            <a key={id} className="hoverable-link" style={{
                                                alignItems: "center",
                                                width: "100%",
                                                display: "flex",
                                                flexDirection: "row",
                                                padding: "10px",
                                            }} href={file.url} target="_blank" rel="noreferrer noopener">
                                                {/* <div className="hoverable-link" style={{ width: "100%", margin: "5px 0" }}> */}
                                                <div style={{ fontSize: "20px" }}>
                                                    {getFileIcon(file.url)}
                                                    {/* <img src={PdfIcon} style={{ width: "15px" }}></img> */}
                                                </div>
                                                <div style={isPhoneSize() ? {
                                                    marginLeft: "10px", color: "black", marginRight: "20px", maxWidth: "200px", minWidth: "fit-content", textWrap: "auto"
                                                } : {
                                                    marginLeft: "10px", color: "black", marginRight: "20px"
                                                }}>
                                                    {file.url.substring(file.url.lastIndexOf('/') + 1)}
                                                </div>

                                                {/* <div style={{ marginLeft: "auto", color: "black", minWidth: "150px", paddingLeft: "10px" }}>
                                        {file.type}
                                    </div> */}

                                                {<div style={{ color: "#4D4D4D", minWidth: "100px" }}>
                                                    {`${convertAndFormatDatetime(file.timestamp)}`}
                                                </div>}
                                                {/* </div> */}
                                            </a>

                                        </div>
                                        {/* <Divider style={dividerStyle} /> */}
                                    </div>
                                })}
                        </div>)}
                    {files.filter(file => !file.type).length > 0 &&
                        <div>
                            <div style={categoryStyle}>
                                {"Others"}
                            </div>
                            {/* <Divider style={dividerStyle} /> */}
                            {/* get file of category category */}
                            {files.filter(file => !file.type)
                                .map((file, id) => {
                                    return <div key={id}>
                                        <div key={id} style={isPhoneSize() ? {
                                            display: "flex", alignItems: "center", fontSize: "15px",
                                        } : {
                                            display: "flex", alignItems: "center", fontSize: "15px", paddingLeft: "0px", marginLeft: "10px"
                                        }}>
                                            <a key={id} className="hoverable-link" style={{
                                                alignItems: "center",
                                                width: "100%",
                                                display: "flex",
                                                flexDirection: "row",
                                                padding: "10px",
                                            }} href={file.url} target="_blank" rel="noreferrer noopener">
                                                {/* <div className="hoverable-link" style={{ width: "100%", margin: "5px 0" }}> */}
                                                <div style={{ fontSize: "20px" }}>
                                                    {getFileIcon(file.url)}
                                                    {/* <img src={PdfIcon} style={{ width: "15px" }}></img> */}
                                                </div>
                                                <div style={isPhoneSize() ? {
                                                    marginLeft: "10px", color: "black", marginRight: "20px", maxWidth: "200px", minWidth: "fit-content", textWrap: "auto"
                                                } : {
                                                    marginLeft: "10px", color: "black", marginRight: "20px"
                                                }}>
                                                    {file.url.substring(file.url.lastIndexOf('/') + 1)}
                                                </div>

                                                {/* <div style={{ marginLeft: "auto", color: "black", minWidth: "150px", paddingLeft: "10px" }}>
                                        {file.type}
                                    </div> */}

                                                {<div style={{ color: "#4D4D4D", minWidth: "100px" }}>
                                                    {`${convertAndFormatDatetime(file.timestamp)}`}
                                                </div>}
                                                {/* </div> */}
                                            </a>

                                        </div>
                                        {/* <Divider style={dividerStyle} /> */}
                                    </div>
                                })}
                        </div>}
                </div>
            </div>
        </>
        }
    </>
}