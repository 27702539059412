import backArrow from "../../assets/img/backArrow.png"
import { useEffect, useState, useContext } from "react"
import api from "api"
import { get } from "utils/fetch"
import { DeleteConfirmation } from "components/DeleteConfirmation"
import { message, Spin } from "antd";
import { propertyToAddress } from "utils/util"
import { isPhoneSize } from "utils/util"
import { GlobalContext } from 'GlobalContext';

export function PropertyInsertion(props) {
    // const { property, setPropertyId } = props

    const { setManageRecordId, manageRecordId, setManageRecordTab } = useContext(GlobalContext)
    const [dirty, setDirty] = useState(false)
    const [creationErrorMessages, setCreationErrorMessages] = useState([])
    // holding companies holding the current property
    const [holdingCompanies, setHoldingCompanies] = useState([])
    const [saving, setSaving] = useState(false)
    const [address1, setAddress1] = useState("")
    const [address2, setAddress2] = useState("")
    const [city, setCity] = useState("")
    const [state, setState] = useState("")
    const [zip, setZip] = useState("")
    const [messageApi, contextHolder] = message.useMessage();
    const [deleteMessage, setDeleteMessage] = useState("") // need to be delete to delete
    const [showDeletionBox, setShowDeletionBox] = useState(false)
    const [loading, setLoading] = useState(true)
    const [fundsByProperty, setFundsByProperty] = useState([])
    const [companiesByProperty, setCompaniesByProperty] = useState([])
    const [investorsByProperty, setInvestorsByProperty] = useState([])
    const [accountsByProperty, setAccountsByProperty] = useState([])
    const [property, setProperty] = useState(null)

    const entryStyle = {
        margin: "20px",
        fontSize: '20px'
    }

    const inputStyle = {
        border: "0",
        outline: "none",
        borderBottom: "1px solid black",
        fontSize: '20px',
        background: "transparent"
    }

    const displayErrorMessage = (message) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };

    async function fetchCompaniesByProperty(id) {
        // retrieve holding companies associate with property
        await get(api.manager.holdingCompaniesByProperty(id), setCompaniesByProperty, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            })
        )
        // setLoading(false)
    }

    async function fetchFundsByProperty(id) {
        // retrieve holding companies associate with property
        await get(api.manager.fundsByProperty(id), setFundsByProperty, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            })
        )
        // setLoading(false)
    }

    async function fetchInvestorsByProperty(id) {
        // retrieve holding companies associate with property
        await get(api.manager.investorsByProperty(id), setInvestorsByProperty, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            })
        )
        // setLoading(false)
    }

    async function fetchAccountsByProperty(id) {
        // retrieve holding companies associate with property
        await get(api.manager.accountsByProperty(id), setAccountsByProperty, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            })
        )
        // setLoading(false)
    }

    // console.log(fundsByProperty)
    async function fetchHoldingCompanies(id) {
        // retrieve holding companies associate with property
        await get(api.manager.holdingCompaniesByProperty(id), setHoldingCompanies, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            })
        )
        setLoading(false)
    }

    useEffect(() => {
        if (manageRecordId < 0) {
            setLoading(false)
            return
        }
        if (property === null) {
            return
        }
        // if property is not null
        if (property !== null) {
            fetchCompaniesByProperty(property.id)
            fetchFundsByProperty(property.id)
            fetchInvestorsByProperty(property.id)
            fetchAccountsByProperty(property.id)
            // retrieve holding companies associate with it
            fetchHoldingCompanies(property.id)

            setAddress1(property === null || property.address === null ? "" : property.address)
            setAddress2(property === null || property.address2 === null ? "" : property.address2)
            setCity(property === null || property.city === null ? "" : property.city)
            setState(property === null || property.state === null ? "" : property.state)
            setZip(property === null || property.zip === null ? "" : property.zip)
        }

    }, [property])

    async function fetchProperty(id) {
        // retrieve holding companies associate with property
        await get(api.manager.property(id), setProperty, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            })
        )
        // setLoading(false)
    }

    useEffect(() => {
        if (manageRecordId > 0) {
            fetchProperty(manageRecordId)
        }
    }, [])

    // console.log(holdingCompanies)
    function canSubmit() {
        var invalidFields = []
        if (address1.trim().length === 0) {
            invalidFields.push("Please fill in address line 1.")
        }
        if (city.trim().length === 0) {
            invalidFields.push("Please fill in city.")
        }
        if (state.trim().length === 0) {
            invalidFields.push("Please fill in state.")
        }
        if (zip.trim().length === 0) {
            invalidFields.push("Please fill in zip.")
        }

        setCreationErrorMessages(invalidFields)
        return invalidFields.length === 0;
    }
    async function saveProperty() {
        if (canSubmit() === false) {
            return
        }
        if (dirty === false) {
            setManageRecordId(0)
            return
        }
        setSaving(true)
        setAddress1(address1.trim())
        setAddress2(address2.trim())
        setCity(city.trim())
        setState(state.trim())
        setZip(zip.trim())
        const fullAddress = address2 !== null && address2.trim().length > 0 ?
            `${address1} ${address2}, ${city}, ${state} ${zip}` :
            `${address1}, ${city}, ${state} ${zip}`
        const response = await fetch(`https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(fullAddress)}`);
        const data = await response.json()
        var latitude = 37.32550095925802
        var longitude = -121.92818162476449
        if (data.length > 0) {
            latitude = parseFloat(data[0].lat)
            longitude = parseFloat(data[0].lon)
        }
        const body = JSON.stringify({
            address1,
            address2,
            city,
            state,
            zip,
            fullAddress,
            latitude,
            longitude
        })

        const options = {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            headers: {
                "Content-Type": "application/json"
            },
            body
        }
        if (property === null) {
            fetch(api.manager.properties, options);
        } else {
            fetch(api.manager.property(property.id), options);
        }
        setManageRecordId(0)
    }

    // called by DeleteConfirmation component to delete current record
    async function deletionCallback() {
        const options = {
            method: "DELETE", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            headers: {
                "Content-Type": "application/json"
            },
            // body
        }
        const result = await fetch(api.manager.property(property.id), options);
        const data = await result.json()
        setManageRecordId(0)
    }

    async function deleteProperty() {
        if (holdingCompanies.length > 0) {
            setCreationErrorMessages(holdingCompanies.map(holdingCompany => {
                return `Current property is held by ${holdingCompany.name}`
            }))
            return
        }
        setShowDeletionBox(true)
    }

    return <>
        {loading && <div style={{ marginTop: "30px", textAlign: "center" }}><Spin /></div>}
        {!loading &&
            <div>
                {property !== null && showDeletionBox &&
                    <DeleteConfirmation
                        title={`Address: ${propertyToAddress(property)}`}
                        setShowDeletionBox={setShowDeletionBox}
                        deletionCallback={deletionCallback}
                    />
                }
                <div style={{ margin: "20px" }}>
                    <img style={{ width: "15px", height: "20px", cursor: "pointer" }}
                        src={backArrow}
                        onClick={() => setManageRecordId(0)}
                    ></img>
                </div>

                {creationErrorMessages.map((message, idx) => {
                    return <div key={idx} style={{ color: "red", marginLeft: "20px" }}>{message}</div>
                })}

                <div style={isPhoneSize() ? entryStyle : { ...entryStyle, display: "flex" }}>
                    <div>Address Line 1<span style={{ color: "red" }}>*</span>: </div><input style={inputStyle}
                        value={address1}
                        onInput={(e) => {
                            setAddress1(e.target.value)
                            setDirty(true)
                        }}></input>
                </div>

                <div style={isPhoneSize() ? entryStyle : { ...entryStyle, display: "flex" }}>
                    <div>Address Line 2:</div> <input style={inputStyle}
                        value={address2}
                        onInput={(e) => {
                            setAddress2(e.target.value)
                            setDirty(true)
                        }}></input>
                </div>

                <div style={isPhoneSize() ? entryStyle : { ...entryStyle, display: "flex" }}>
                    <div>City<span style={{ color: "red" }}>*</span>:</div> <input style={inputStyle}
                        value={city}
                        onInput={(e) => {
                            setCity(e.target.value)
                            setDirty(true)
                        }}></input>
                </div>

                <div style={isPhoneSize() ? entryStyle : { ...entryStyle, display: "flex" }}>
                    <div>State<span style={{ color: "red" }}>*</span>:</div> <input style={inputStyle}
                        value={state}
                        onInput={(e) => {
                            setState(e.target.value)
                            setDirty(true)
                        }}></input>
                </div>

                <div style={isPhoneSize() ? entryStyle : { ...entryStyle, display: "flex" }}>
                    <div>Zipcode<span style={{ color: "red" }}>*</span>:</div> <input style={inputStyle}
                        value={zip}
                        onInput={(e) => {
                            setZip(e.target.value)
                            setDirty(true)
                        }}></input>
                </div>

                <div style={entryStyle}>
                    Investors:
                    {investorsByProperty
                        .sort((a, b) => {
                            if (a.legal_name < b.legal_name) {
                                return -1
                            }
                            return 1
                        })
                        .map((investor, idx) => {
                            return <div key={idx} style={{ display: "flex", flexDirection: "row" }}>
                                <div style={{
                                    // fontSize: "20px",
                                    // margin: "20px",
                                    width: "fit-content",
                                    color: "blue",
                                    textDecoration: "underline",
                                    cursor: "pointer"
                                }}
                                    onClick={() => {
                                        setManageRecordId(investor.id)
                                        setManageRecordTab("Investors")
                                    }}>
                                    {investor.legal_name}
                                </div>
                            </div>
                        })}
                </div>

                <div style={entryStyle}>
                    Accounts:
                    {accountsByProperty
                        .sort((a, b) => {
                            if (a.account_name < b.account_name) {
                                return -1
                            }
                            return 1
                        })
                        .map((account, idx) => {
                            return <div key={idx} style={{ display: "flex", flexDirection: "row" }}>
                                <div style={{
                                    // fontSize: "20px",
                                    // margin: "20px",
                                    width: "fit-content",
                                    color: "blue",
                                    textDecoration: "underline",
                                    cursor: "pointer"
                                }}
                                    onClick={() => {
                                        setManageRecordId(account.id)
                                        setManageRecordTab("Accounts")
                                    }}>
                                    {account.account_name}
                                </div>
                            </div>
                        })}
                </div>

                <div style={entryStyle}>
                    Entities:
                    {fundsByProperty
                        .sort((a, b) => {
                            if (a.name < b.name) {
                                return -1
                            }
                            return 1
                        })
                        .map((fund, idx) => {
                            return <div key={idx} style={{ display: "flex", flexDirection: "row" }}>
                                <div style={{
                                    // fontSize: "20px",
                                    // margin: "20px",
                                    width: "fit-content",
                                    color: "blue",
                                    textDecoration: "underline",
                                    cursor: "pointer"
                                }}
                                    onClick={() => {
                                        setManageRecordId(fund.id)
                                        setManageRecordTab("Entities/Funds")
                                    }}>
                                    {fund.name}
                                </div>
                            </div>
                        })}
                </div>

                <div style={entryStyle}>
                    Holding Companies:
                    {companiesByProperty
                        .sort((a, b) => {
                            if (a.name < b.name) {
                                return -1
                            }
                            return 1
                        })
                        .map((company, idx) => {
                            return <div key={idx} style={{ display: "flex", flexDirection: "row" }}>
                                <div style={{
                                    // fontSize: "20px",
                                    // margin: "20px",
                                    width: "fit-content",
                                    color: "blue",
                                    textDecoration: "underline",
                                    cursor: "pointer"
                                }}
                                    onClick={() => {
                                        setManageRecordId(company.id)
                                        setManageRecordTab("Holding Companies")
                                    }}>
                                    {company.name}
                                </div>
                            </div>
                        })}
                </div>

                {!saving && <div style={{ margin: "20px", display: "flex", justifyContent: "space-between" }}>
                    <button style={{
                        height: "30px",
                        width: "50px",
                        borderRadius: "30px",
                        background: "rgb(17 32 86)",
                        color: "white",
                        cursor: "pointer",
                    }}
                        onClick={saveProperty}>
                        Save
                    </button>

                    {property !== null && <button style={{
                        height: "30px",
                        width: "70px",
                        borderRadius: "30px",
                        background: "red",
                        color: "white",
                        cursor: "pointer",
                    }}
                        onClick={deleteProperty}
                    >
                        Delete
                    </button>}
                </div>}
                {saving && <div style={{ margin: "20px" }}>
                    <button style={{
                        height: "30px",
                        width: "70px",
                        borderRadius: "30px",
                        background: "rgb(17 32 86)",
                        color: "white",
                        cursor: "pointer",
                    }}>
                        Saving
                    </button>
                </div>}
            </div>
        }
    </>
}