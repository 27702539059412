import { useState } from "react"

export function SummaryFilter(props) {
    const { setFilterIsOpen } = props

    const [filters, setFilters] = useState({
        closedWithinPeriod: {
            active: false
        }
    })

    const filterNameStyle = {
        fontSize: "13px",
        margin: "10px 0"
    }

    const selectedOption = {
        background: "rgb(244, 244, 244)",
        padding: "7px 9px",
        borderRadius: "7px",
        display: "inline",
        fontSize: "12px",
        minHeight: "33px",
        alignContent: "center",
        cursor: "pointer"
    }

    const unselectedOption = {
        background: "rgb(244, 244, 244)",
        padding: "7px 9px",
        borderRadius: "7px",
        display: "inline",
        fontSize: "12px",
        minHeight: "33px",
        alignContent: "center",
        cursor: "pointer"
    }

    return <div style={{
        position: "fixed",
        top: "30px", /* distance from the top of the screen */
        left: "220px", /* distance from the left side of the screen */
        /* Adjust as needed for desired coordinates */
        background: "white",
        padding: "0 10px 10px 10px",
        width: "200px",
        border: "1px solid black",
        // borderRadius: "5px",
        // zIndex: "1000",
        display: "flex",
        flexDirection: "column"
    }}>
        <div style={filterNameStyle}>
            Closing Status in Period
        </div>
        <div style={filters.closedWithinPeriod.active ? selectedOption : unselectedOption}>
            Closed Within Selected Period
        </div>
    </div>
}