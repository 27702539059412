import { AppHeader } from 'components/AppHeader';
import { CompanyInfo } from 'components/CompanyInfo';
import { useEffect, useState, useContext } from 'react';
import { message, Spin, Divider } from 'antd';
import { get } from 'utils/fetch';
import api from 'api';
import { getFileIcon, convertAndFormatDatetime, isPhoneSize } from 'utils/util';
import "./Documents.css"
import { GlobalContext } from 'GlobalContext';

export function Documents(props) {
    const { email, basicInfo, setEmail, setBasicInfo } = props
    const { accessToken } = useContext(GlobalContext)
    const [emailAttachments, setEmailAttachments] = useState([])
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(true)
    const [investmentDocuments, setInvestmentDocumments] = useState({})

    const displayErrorMessage = (message) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };

    async function fetchEmailAttachments(email) {
        await get(api.email.emailAttachments(email), setEmailAttachments, (errors) => errors.forEach(err => {
            displayErrorMessage(err.message);
        }), accessToken);
    };

    async function fetchInvestmentFiles(id) {
        await get(api.investor.getInvestmentFiles(id), setInvestmentDocumments, (errors) => errors.forEach(err => {
            displayErrorMessage(err.message);
        }), accessToken);
        setLoading(false)
    };

    const entryStyle = {
        margin: "20px",
        fontSize: '20px',
    }

    const dividerStyle = {
        margin: "0px"
    }

    // console.log(basicInfo)
    // console.log(email)
    async function fetchDocuments(id) {
        setLoading(true)
        // console.log("fetchEmailAttachments")
        await fetchEmailAttachments(basicInfo.email)
        // console.log("fetchInvestmentFiles")
        fetchInvestmentFiles(id)
    }
    useEffect(() => {
        if (accessToken === "" || Object.keys(basicInfo).length === 0) {
            return
        }
        fetchDocuments(basicInfo.id)
    }, [basicInfo, accessToken])
    // console.log(investmentDocuments)
    const content = loading ? <div style={{ width: "100%", marginTop: "20px", textAlign: "center" }}>
        <Spin />
    </div>
        :
        <div style={{ overflowY: "scroll", scrollbarWidth: "none" }}>
            <div style={{ ...entryStyle, height: "calc(100vh - 200px)" }}>
                {emailAttachments.length > 0 && <div>
                    <div style={{ margin: "10px 0" }}>
                        {"Attachment"}
                    </div>
                    <Divider style={dividerStyle} />
                </div>}
                {
                    // emailAttachments.map((attachment, id) => {
                    //     return <div key={id}><div key={id} style={isPhoneSize() ? {
                    //         display: "flex", alignItems: "center", fontSize: "15px",
                    //     } : {
                    //         display: "flex", alignItems: "center", fontSize: "15px", paddingLeft: "0px"
                    //     }}>
                    //         <div className="hoverable-link" style={{ width: "100%" }}>
                    //             <span>
                    //                 <a key={id} style={{ alignItems: "center", width: "100%" }} href={attachment.file_path} target="_blank" rel="noreferrer noopener">
                    //                     <span style={{ padding: "5px", paddingBottom: "0" }}>
                    //                         {getFileIcon(attachment.file_path)}
                    //                         {/* <img src={PdfIcon} style={{ width: "15px" }}></img> */}
                    //                     </span>
                    //                     <span>
                    //                         {attachment.file_path.substring(attachment.file_path.lastIndexOf('/') + 1)}
                    //                     </span>
                    //                 </a>
                    //             </span>
                    //             <span style={{ float: "right", color: "black" }}>
                    //                 {`- ${convertAndFormatDatetime(attachment.created_at + "Z")}`}
                    //             </span>
                    //         </div>

                    //     </div>
                    //         <Divider style={dividerStyle} />
                    //     </div>
                    // })

                }
                {
                    emailAttachments.map((attachment, id) => {
                        return <div key={id}>
                            <div key={id} style={isPhoneSize() ? {
                                display: "flex", alignItems: "center", fontSize: "15px",
                            } : {
                                display: "flex", alignItems: "center", fontSize: "15px", paddingLeft: "0px"
                            }}>
                                <a key={id} className="hoverable-link" style={{
                                    alignItems: "center",
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "row",
                                    padding: "10px",
                                }} href={attachment.file_path} target="_blank" rel="noreferrer noopener">
                                    {/* <div className="hoverable-link" style={{ width: "100%", margin: "5px 0" }}> */}
                                    <div style={{ fontSize: "20px" }}>
                                        {getFileIcon(attachment.file_path)}
                                        {/* <img src={PdfIcon} style={{ width: "15px" }}></img> */}
                                    </div>
                                    <div style={{ marginLeft: "10px", color: "black" }}>
                                        {attachment.file_path.substring(attachment.file_path.lastIndexOf('/') + 1)}
                                    </div>
                                    {!isPhoneSize() && <div style={{ marginLeft: "auto", color: "black", minWidth: "100px" }}>
                                        {`- ${convertAndFormatDatetime(attachment.created_at + "Z")}`}
                                    </div>}
                                    {/* </div> */}
                                </a>

                            </div>
                            <Divider style={dividerStyle} />
                        </div>
                    })

                }
                {
                    // Object.keys(investmentDocuments).map((fundName, id) => {
                    //     return <div key={id}>
                    //         <div>
                    //             {fundName}
                    //         </div>
                    //         {investmentDocuments[fundName].map((attachment, id) => {
                    //             return <div key={id} style={isPhoneSize() ? {
                    //                 display: "flex", alignItems: "center", fontSize: "15px",
                    //             } : {
                    //                 display: "flex", alignItems: "center", fontSize: "15px", paddingLeft: "50px"
                    //             }}>
                    //                 <div className="hoverable-link" style={{ width: "100%" }}>
                    //                     <span>
                    //                         <a key={id} style={{ alignItems: "center", width: "100%" }} href={attachment.url} target="_blank" rel="noreferrer noopener">
                    //                             <span style={{ padding: "5px", paddingBottom: "0" }}>
                    //                                 {getFileIcon(attachment.url)}
                    //                                 {/* <img src={PdfIcon} style={{ width: "15px" }}></img> */}
                    //                             </span>
                    //                             <span>
                    //                                 {attachment.url.substring(attachment.url.lastIndexOf('/') + 1)}
                    //                             </span>
                    //                         </a>
                    //                     </span>
                    //                     <span style={{ float: "right", color: "black" }}>
                    //                         {`- ${convertAndFormatDatetime(attachment.date + "Z")}`}
                    //                     </span>
                    //                 </div>

                    //             </div>
                    //         })}
                    //     </div>
                    // })
                }
                {Object.keys(investmentDocuments).map((fundName, id) => {
                    return <div key={id}>
                        <div>
                            <div style={{ margin: "10px 0" }}>
                                {fundName}
                            </div>
                            <Divider style={dividerStyle} />
                        </div>
                        {investmentDocuments[fundName].map((attachment, id) => {
                            return <div key={id}>
                                <div key={id} style={isPhoneSize() ? {
                                    display: "flex", alignItems: "center", fontSize: "15px",
                                } : {
                                    display: "flex", alignItems: "center", fontSize: "15px", paddingLeft: "0px"
                                }}>

                                    <a key={id} className="hoverable-link" style={{
                                        alignItems: "center",
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "row",
                                        padding: "10px",
                                    }} href={attachment.url} target="_blank" rel="noreferrer noopener">
                                        {/* <div className="hoverable-link" style={{ width: "100%", margin: "5px 0" }}> */}
                                        <div style={{ fontSize: "20px" }}>
                                            {getFileIcon(attachment.url)}
                                            {/* <img src={PdfIcon} style={{ width: "15px" }}></img> */}
                                        </div>
                                        <div style={{ marginLeft: "10px", color: "black" }}>
                                            {attachment.url.substring(attachment.url.lastIndexOf('/') + 1)}
                                        </div>
                                        {!isPhoneSize() && <div style={{ marginLeft: "auto", color: "black", minWidth: "100px" }}>
                                            {`- ${convertAndFormatDatetime(attachment.date + "Z")}`}
                                        </div>}
                                        {/* </div> */}
                                    </a>

                                </div>
                                <Divider style={dividerStyle} />
                            </div>
                        })}
                    </div>
                })}
                {isPhoneSize() && <div style={{
                    fontSize: "12px",
                    textAlign: "left",
                }}>

                    <CompanyInfo />
                </div>}
                {isPhoneSize() && <div style={{
                    display: "flex",
                    height: "50px"
                }}>
                </div>}
            </div>
        </div>
    return <>
        {!isPhoneSize() && <div style={{ display: "flex", flexDirection: "row" }}>

            <div style={{ width: "100%" }}>
                <AppHeader setBasicInfo={setBasicInfo} basicInfo={basicInfo} setEmail={setEmail} />
                {content}
            </div>
            <div style={{
                width: '380px',
                textAlign: "left",
                background: 'transparent',
                padding: 12,
                overflowY: 'scroll',
                overflowX: "hidden",
                height: "100vh",
                scrollbarWidth: "none",
                // height: 'calc(100vh - 144px)',
                height: "100vh"
            }}>

                <CompanyInfo />
            </div>
        </div >}
        {isPhoneSize() && <div style={{ display: "flex", flexDirection: "column" }}>

            <div style={{ width: "100%" }}>
                {content}
            </div>

        </div >}
    </>
}