import { useEffect, useState } from "react"
import { USDollar } from "utils/format"

export function FundSubtypeTableView(props) {
    const { type, investments } = props
    const [subtypeSet, setSubtypeSet] = useState(new Set())

    useEffect(() => {
        var tmpSubtypeSet = new Set()
        for (const investment of investments) {
            if (!investment.subtype) continue
            tmpSubtypeSet.add(investment.subtype)
        }
        setSubtypeSet(tmpSubtypeSet)
    }, [])


    return <>
        <div>
            {type}
        </div>

        <div style={{
            overflowX: "scroll",
            overflow: "auto",
            scrollbarWidth: "auto"
        }}>
            <div style={{ display: "flex", flexDirection: "row", fontSize: "17px", width: "870px", borderBottom: "1px solid black" }}>
                <div style={{ width: "240px", textAlign: "center" }}>
                    {"Subtype"}
                </div>

                <div style={{ width: "180px", textAlign: "center" }}>
                    Commitment Number
                </div>

                <div style={{ width: "150px", textAlign: "center" }}>
                    Equity Balance
                </div>

                <div style={{ width: "150px", textAlign: "center" }}>
                    Distribute Interest
                </div>

                <div style={{ width: "150px", textAlign: "center" }}>
                    Principal Return
                </div>
            </div>

            {[...subtypeSet].map((subtype, id) => <div key={id} style={{ display: "flex", flexDirection: "row", border: "1px solid black", borderTop: "0px", width: "870px" }}>
                <div style={{ width: "240px", textAlign: "center" }}>
                    {subtype}
                </div>

                <div style={{ width: "180px", textAlign: "center" }}>
                    {USDollar.format(investments
                        .filter(investment => investment.subtype && investment.subtype.toLowerCase() === subtype.toLowerCase())
                        .reduce((acc, cur) => {
                            return acc + cur.amount
                        }, 0))}
                </div>

                <div style={{ width: "150px", textAlign: "center" }}>
                    {USDollar.format(investments
                        .filter(investment => investment.subtype && investment.subtype.toLowerCase() === subtype.toLowerCase())
                        .reduce((acc, cur) => {
                            return acc + cur.account_balance
                        }, 0))}
                </div>

                <div style={{ width: "150px", textAlign: "center" }}>
                    {USDollar.format(investments
                        .filter(investment => investment.subtype && investment.subtype.toLowerCase() === subtype.toLowerCase())
                        .reduce((acc, cur) => {
                            return acc + cur.interest_received
                        }, 0))}
                </div>

                <div style={{ width: "150px", textAlign: "center" }}>
                    {USDollar.format(investments
                        .filter(investment => investment.subtype && investment.subtype.toLowerCase() === subtype.toLowerCase())
                        .reduce((acc, cur) => {
                            return acc + cur.principal_returned
                        }, 0))}
                </div>
            </div>)}

            {investments.filter(investment => !investment.subtype).length > 0 && <div style={{ display: "flex", flexDirection: "row", border: "1px solid black", borderTop: "0px", width: "870px" }}>
                <div style={{ width: "240px", textAlign: "center" }}>
                    {"Others"}
                </div>

                <div style={{ width: "180px", textAlign: "center" }}>
                    {USDollar.format(investments
                        .filter(investment => !investment.subtype)
                        .reduce((acc, cur) => {
                            return acc + cur.amount
                        }, 0))}
                </div>

                <div style={{ width: "150px", textAlign: "center" }}>
                    {USDollar.format(investments
                        .filter(investment => !investment.subtype)
                        .reduce((acc, cur) => {
                            return acc + cur.account_balance
                        }, 0))}
                </div>

                <div style={{ width: "150px", textAlign: "center" }}>
                    {USDollar.format(investments
                        .filter(investment => !investment.subtype)
                        .reduce((acc, cur) => {
                            return acc + cur.interest_received
                        }, 0))}
                </div>

                <div style={{ width: "150px", textAlign: "center" }}>
                    {USDollar.format(investments
                        .filter(investment => !investment.subtype)
                        .reduce((acc, cur) => {
                            return acc + cur.principal_returned
                        }, 0))}
                </div>
            </div>}

            <div style={{ display: "flex", flexDirection: "row", border: "1px solid black", borderTop: "0px", width: "870px" }}>
                <div style={{ width: "240px", textAlign: "center" }}>
                    {"Summary"}
                </div>

                <div style={{ width: "180px", textAlign: "center" }}>
                    {USDollar.format(investments
                        .reduce((acc, cur) => {
                            return acc + cur.amount
                        }, 0))}
                </div>

                <div style={{ width: "150px", textAlign: "center" }}>
                    {USDollar.format(investments
                        .reduce((acc, cur) => {
                            return acc + cur.account_balance
                        }, 0))}
                </div>

                <div style={{ width: "150px", textAlign: "center" }}>
                    {USDollar.format(investments
                        .reduce((acc, cur) => {
                            return acc + cur.interest_received
                        }, 0))}
                </div>

                <div style={{ width: "150px", textAlign: "center" }}>
                    {USDollar.format(investments
                        .reduce((acc, cur) => {
                            return acc + cur.principal_returned
                        }, 0))}
                </div>
            </div>
        </div>
    </>
}