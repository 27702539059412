import { useEffect, useState, useRef, useContext } from "react"
import api from "api"
import { get } from "utils/fetch";
import { message, Spin, Input } from "antd";
import { InvestorInsertion } from "pages/RecordInsertion/InvestorInsertion";
import { GlobalContext } from 'GlobalContext';

export function InvestorManager() {
    const [messageApi, contextHolder] = message.useMessage();
    const { setManageRecordId, manageRecordId } = useContext(GlobalContext)
    const fileInputRef = useRef(null);  // Reference to the file input element
    const [loading, setLoading] = useState(true)
    const [investors, setInvestors] = useState([])
    const [investorId, setInvestorId] = useState(0) // 0: main page
    const [filteredText, setFilteredText] = useState("")
    // const [accounts, setAccounts] = useState([])
    const [file, setFile] = useState(null);
    const [fileContent, setFileContent] = useState(null);
    const [fileSubmitButtonText, setFileSubmitButtonText] = useState("Submit")
    const [fileSubmitButtonWidth, setFileSubmitButtonWidth] = useState("70px")

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];

        // Check if the selected file is a CSV file
        if (selectedFile && selectedFile.type === "text/csv") {
            setFile(selectedFile);
            readFile(selectedFile);
        } else {
            alert("Please select a valid CSV file.");
            e.target.value = null;  // Reset the input if it's not a CSV file
        }
    };

    const readFile = (file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const content = e.target.result;
            validateCSV(content);
        };
        reader.readAsText(file);
    };

    const validateCSV = (content) => {
        const rows = content.split('\n');

        // Example validation: Check that there are at least two rows (header + 1 data row)
        if (rows.length < 2) {
            fileInputRef.current.value = '';
            alert('CSV file must contain at least one row of data.');
            setFile(null);
            return;
        }

        // Example: Validate CSV headers (assuming the first row contains headers)
        const headers = rows[0].split(/[,\t\r]+/);
        // console.log(headers)
        const expectedHeaders = ['first name', 'last name', 'email', 'phone', 'date', 'event']; // Replace with actual expected headers
        const missingHeaders = expectedHeaders
            .filter(header => !headers.includes(header))
        const isHeaderValid = headers.every((header, index) => header.trim() === expectedHeaders[index]);
        // console.log(missingHeaders)

        if (missingHeaders.length > 0) {
            fileInputRef.current.value = '';
            alert(`Missing columns: ${missingHeaders.join(", ")}`);
            setFile(null);
            return;
        }

        // Optionally, set file content if validation passes
        setFileContent(content);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (fileSubmitButtonText === "Submitting") {
            return
        }
        setSubmittingStatus()

        if (!file || !fileContent) {
            alert("Please select a valid file!");
            return;
        }

        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await fetch(api.file.investorBulkUpload, {
                method: 'POST',
                body: formData
            });

            if (response.ok) {
                console.log('File uploaded successfully');
            } else {
                console.error('File upload failed');
            }
        } catch (error) {
            console.error('Error during file upload:', error);
        }
        unsetSubmittingStatus()
    };

    function setSubmittingStatus() {
        setFileSubmitButtonText("Submitting")
        setFileSubmitButtonWidth("100px")
    }

    function unsetSubmittingStatus() {
        fetchInvestors()
        setFileSubmitButtonText("Submit")
        setFileSubmitButtonWidth("70px")
        setFile(null);
        fileInputRef.current.value = '';
    }

    const displayErrorMessage = (message) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };

    // async function fetchAccounts() {
    //     await get(api.manager.accounts, setAccounts, (errors) =>
    //         errors.forEach(err => {
    //             displayErrorMessage(err.message);
    //         })
    //     )
    //     setLoading(false)
    // }

    async function fetchInvestors() {
        await get(api.manager.investors, setInvestors, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            })
        )
        setLoading(false)
    }

    useEffect(() => {
        if (manageRecordId !== 0) {
            setLoading(false)
        }
        setFilteredText("")
        if (investorId !== 0) return
        fetchInvestors()
        // fetchAccounts()
    }, [investorId])

    const downloadCSV = () => {
        fetch(api.manager.downloadInvestors, {
            method: 'GET',
        })
            .then(response => {
                if (response.ok) {
                    return response.blob(); // Convert response to blob
                }
                throw new Error('Error while downloading CSV');
            })
            .then(blob => {
                // Create a link element to trigger download
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'investors.csv'); // Specify file name
                document.body.appendChild(link);
                link.click(); // Simulate click to download
                link.remove(); // Remove link from DOM after downloading
            })
            .catch(error => {
                console.error('Error downloading the CSV file:', error);
            });
    };

    return <>
        {loading && <div style={{ marginTop: "30px", textAlign: "center" }}><Spin /></div>}
        {!loading && manageRecordId === 0 && <>
            <div style={{ width: "300px", marginTop: "30px", marginLeft: "20px" }}>
                <Input
                    onChange={e => setFilteredText(e.target.value.trim().toLowerCase())}
                    placeholder="Filter on name or email"
                />
            </div>
            <div style={{ margin: "20px", display: "flex", justifyContent: "space-between" }}>
                <button style={{
                    height: "30px",
                    width: "50px",
                    borderRadius: "30px",
                    background: "rgb(17 32 86)",
                    color: "white",
                    cursor: "pointer",
                }}
                    onClick={() => setManageRecordId(-1)}>
                    Add
                </button>

                <form onSubmit={handleSubmit}>
                    {/* <label> */}
                    <input
                        ref={fileInputRef}
                        type="file"
                        accept=".csv"
                        onChange={handleFileChange}
                        style={{ width: "200px" }}
                    />
                    {/* </label> */}
                    <button type="submit" style={{
                        height: "30px",
                        width: fileSubmitButtonWidth,
                        borderRadius: "30px",
                        background: "rgb(17 32 86)",
                        color: "white",
                        cursor: "pointer",
                    }}>{fileSubmitButtonText}</button>
                </form>

                <button style={{
                    height: "30px",
                    width: "130px",
                    borderRadius: "30px",
                    background: "rgb(17 32 86)",
                    color: "white",
                    cursor: "pointer",
                }}
                    onClick={downloadCSV}>Export Investors</button>
            </div>
            {investors
                .filter(investor =>
                    investor.legal_name.toLowerCase().includes(filteredText) || investor.email.toLowerCase().includes(filteredText))
                .map((investor, idx) => {
                    return <div key={idx} style={{
                        fontSize: "20px",
                        margin: "20px",
                        width: "fit-content",
                        color: "blue",
                        textDecoration: "underline",
                        cursor: "pointer"
                    }}
                        onClick={() => setManageRecordId(investor.id)}
                    >
                        {`${investor.legal_name} (${investor.email})`}
                    </div>
                })}

        </>
        }
        {!loading && manageRecordId > 0 && <>
            <InvestorInsertion
            // investor={investors.filter(investor => investor.id === investorId)[0]} 
            // setInvestorId={setInvestorId} 
            // accounts={accounts} 
            />
        </>}
        {!loading && manageRecordId < 0 && <>
            <InvestorInsertion
            // investor={null} 
            // setInvestorId={setInvestorId} 
            // accounts={accounts} 
            />
        </>}
    </>
}