import { isAdmin } from "utils/util"
import { useState, useEffect } from "react"
import { Select, Space, message, Spin } from "antd"
import { get } from "utils/fetch";
import api from "api";
import { USDollar } from "utils/format";
import { set } from "lodash";

export function PortfolioSumary(props) {

    const [messageApi, contextHolder] = message.useMessage();
    const [positions, setPositions] = useState([])
    const [uniqueFundIds, setUniqueFundIds] = useState([])
    const [fundIdToName, setFundIdToName] = useState({})
    const [loading, setLoading] = useState(true)

    const displayErrorMessage = (message) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };

    async function fetchPositions() {
        await get(api.manager.positions, setPositions, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            })
        )
        setLoading(false)
    }

    const entryStyle = {
        margin: "20px",
        fontSize: '20px'
    }

    console.log(positions)

    useEffect(() => {
        const uniqueFundIds = [
            ...new Set(positions
                .map(position => position.fund_id))
        ]
        const idToNameMapping = positions.reduce((acc, cur) => {
            acc[cur.fund_id] = cur.fund_name
            return acc
        }, {})
        setUniqueFundIds(uniqueFundIds)
        setFundIdToName(idToNameMapping)
    }, [positions])

    useEffect(() => {
        fetchPositions()
    }, [])
    return <>
        {!isAdmin() && <div>Not Authorized</div>}
        {isAdmin() && loading && <div style={{ marginTop: "30px", textAlign: "center" }}><Spin /></div>}
        {isAdmin() && !loading && <div>
            <div style={entryStyle}>
                {/* head */}
                <div style={{ overflowX: "scroll", scrollbarWidth: "none" }}>
                    <div style={{ width: "900px" }}>
                        <div style={{ display: "flex", fontSize: "17px" }}>
                            <div style={{ width: "270px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                Fund
                            </div>

                            <div style={{ width: "180px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                Commitment Number
                            </div>

                            <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                Equity Balance
                            </div>

                            <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                Distribute Interest
                            </div>

                            <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                Principal Return
                            </div>
                        </div>
                        {positions.length > 0 && <div style={{ border: "1px solid black", width: "900px" }}>
                            {
                                uniqueFundIds
                                    // .sort((a, b) => {
                                    //     if (a.legal_name < b.legal_name) {
                                    //         return -1
                                    //     }
                                    //     return 1
                                    // })
                                    .map((fund_id, idx) => {
                                        return <div key={idx} style={{ display: "flex", flexDirection: "row", borderTop: idx > 0 ? "1px solid black" : "" }}>
                                            <div style={{ width: "270px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center", flexDirection: 'column' }}>
                                                {fundIdToName[fund_id]}
                                            </div>

                                            <div style={{ width: "180px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                                {USDollar.format(positions.reduce((acc, cur) => {
                                                    if (cur.fund_id !== fund_id) {
                                                        return acc
                                                    }
                                                    return acc + cur.amount
                                                }, 0))}
                                            </div>

                                            <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                                {USDollar.format(positions.reduce((acc, cur) => {
                                                    if (cur.fund_id !== fund_id) {
                                                        return acc
                                                    }
                                                    return acc + cur.account_balance
                                                }, 0))}
                                            </div>

                                            <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                                {USDollar.format(positions.reduce((acc, cur) => {
                                                    if (cur.fund_id !== fund_id) {
                                                        return acc
                                                    }
                                                    return acc + cur.interest_received
                                                }, 0))}
                                            </div>

                                            <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                                {USDollar.format(positions.reduce((acc, cur) => {
                                                    if (cur.fund_id !== fund_id) {
                                                        return acc
                                                    }
                                                    return acc + cur.principal_returned
                                                }, 0))}
                                            </div>
                                        </div>
                                    }
                                    )
                            }

                            <div style={{ display: "flex", borderTop: "1px solid black" }}>
                                <div style={{ width: "270px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                    Summary
                                </div>

                                <div style={{ width: "180px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                    {USDollar.format(
                                        positions.reduce((acc, cur) => {
                                            return acc + cur.amount
                                        }, 0)
                                    )}
                                </div>

                                <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                    {USDollar.format(
                                        positions.reduce((acc, cur) => {
                                            return acc + cur.account_balance
                                        }, 0)
                                    )}
                                </div>

                                <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                    {USDollar.format(
                                        positions.reduce((acc, cur) => {
                                            return acc + cur.interest_received
                                        }, 0)
                                    )}
                                </div>

                                <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                    {USDollar.format(
                                        positions.reduce((acc, cur) => {
                                            return acc + cur.principal_returned
                                        }, 0)
                                    )}
                                </div>
                            </div>
                        </div>
                        }
                    </div>
                </div>
            </div>
        </div>}
    </>
}