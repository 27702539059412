import { Divider } from "antd"
import close from "../assets/img/close.png"
import { useState } from "react"
import { isPhoneSize } from "utils/util"
export function DeleteDocumentComfirmation(props) {
    const { documentURLToDelete, setDocumentURLToDelete, deletionCallback } = props

    const [deletionText, setDeletionText] = useState("")
    const [deletionButtonText, setDeletionButtonText] = useState("Delete")

    // console.log(deletionText)
    return <div style={{
        position: 'fixed',
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        height: "100vh",
        width: "100vw",
        backgroundColor: "rgba(255, 255, 255, 0.5)",
        backdropFilter: "blur(10px)",
        zIndex: 1,
    }}>
        <div style={{
            width: isPhoneSize() ? "100%" : "500px",
            height: "250px",
            position: 'fixed',
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            background: "white",
            border: "1px solid",
            borderRadius: "5px"
        }}>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', height: "50px" }}>
                <div style={{ fontSize: "16px", fontWeight: "bold", marginLeft: "10px" }}>{documentURLToDelete.substring(documentURLToDelete.lastIndexOf("/") + 1)}</div>
                <img src={close} style={{ width: "20px", marginRight: "10px", cursor: "pointer" }}
                    onClick={e => setDocumentURLToDelete("")}
                >
                </img>
            </div>
            <Divider style={{ margin: "0" }} />
            <div style={{ fontSize: "18px", marginLeft: "10px", height: "140px", display: "flex", flexDirection: "column", justifyContent: "space-around" }}>

                Are you sure you want to delete current photo?
                <br></br>
                <br></br>
                Type "delete" to confirm delete:
                <div>
                    <input style={{ border: "1px solid" }} onChange={e => setDeletionText(e.target.value)}></input>
                </div>
            </div>
            <Divider style={{ margin: "0" }} />
            <div style={{ display: "flex", justifyContent: "space-between", height: "60px", alignItems: "center", margin: "0 30px" }}>
                <button style={{
                    height: "30px",
                    width: "60px",
                    borderRadius: "30px",
                    background: "rgb(17 32 86)",
                    color: "white",
                    cursor: "pointer",
                }}
                    onClick={e => setDocumentURLToDelete("")}
                >
                    Cancel
                </button>

                <button style={{
                    height: "30px",
                    width: "70px",
                    borderRadius: "30px",
                    background: "red",
                    color: "white",
                    cursor: "pointer",
                }}
                    onClick={e => {
                        if (deletionText.trim().toLowerCase() === "delete") {
                            setDeletionButtonText("Deleting")
                            deletionCallback()
                        }
                    }}
                >
                    {deletionButtonText}
                </button>
            </div>
        </div>
    </div>
}