import backArrow from "../../assets/img/backArrow.png"
import { USDollar } from "utils/format"
import { investmentStructToName } from "utils/util"
import { useState, useEffect, useContext } from "react"
import api from "api"
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { message, Spin, Select, Space, Calendar, theme, Switch } from "antd"
import { get } from "utils/fetch"
import { investorStructToDisplayedName, fundEntityToName, getTodayDateString, getTomorrowDateString } from "utils/util"
import { DeleteConfirmation } from "components/DeleteConfirmation"
import { InterestManager } from "./InterestManager"
import { isPhoneSize } from "utils/util"
import { GlobalContext } from 'GlobalContext';
import { Email } from "pages/Email/Email"
import { EmailResultBox } from "pages/Email/EmailResultBox"
import { InvestmentUpdateConfirmation } from "components/InvestmentUpdateConfirmation"
import './RecordInsertion.css'
import { InvestmentFileManager } from "./InvestmentFileManager"
import { PrincipalManager } from "./PrincipalManager"

export function InvestmentInsertion(props) {
    // const { investment, setInvestmentId, funds } = props
    const { setManageRecordId, manageRecordId, setManageRecordTab, accessToken } = useContext(GlobalContext)

    // hook to determine whether in investment page or interest modification page
    const [view, setView] = useState("investment")
    const [investors, setInvestors] = useState([])
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(true)
    const [creationErrorMessages, setCreationErrorMessages] = useState([])
    const [investorIdSelected, setInvestorIdSelected] = useState(-1)
    const [accountIdSelected, setAccountIdSelected] = useState(-1)
    const [fundIdSelected, setFundIdSelected] = useState(-1)
    const [amount, setAmount] = useState(0)
    const [method, setMethod] = useState("Cashout")
    const [type, setType] = useState("")
    const [frequency, setFrequency] = useState("")
    const [endDate, setEndDate] = useState("")
    const [interest, setInterest] = useState(0)
    const [overallInterest, setOverallInterest] = useState(0)
    const [startDate, setStartDate] = useState("")
    // whether end date of an existiing investment is changed
    const [endDateChanged, setEndDateChanged] = useState(false)
    const [account, setAccount] = useState("")
    const [showDeletionBox, setShowDeletionBox] = useState(false)
    const [finalPayout, setFinalPayout] = useState(0)
    const [investment, setInvestment] = useState(null)
    const [funds, setFunds] = useState([])
    const [emailResponse, setEmailResponse] = useState("")
    const [subtype, setSubtype] = useState(null)
    // emails of investors that make investment
    const [investmentEmails, setInvestmentEmails] = useState([])
    const [editMode, setEditMode] = useState(manageRecordId === -1 ? true : false)
    // all accounts
    const [accounts, setAccounts] = useState([])
    // previously taken out
    const [principalTakenOut, setPrincipalTakenOut] = useState([])
    // will be taken out in current session
    const [principalTakeOut, setPrincipalTakeOut] = useState([])
    // previous interest in database
    const [prevInterest, setPrevInterest] = useState([])
    // manually added interest entries
    const [manuallyAddedInterestEntry, setManuallyAddedInterestEntry] = useState([])
    const [changeOfInvestmentText, setChangeOfInvestmentText] = useState("")
    const [minorChange, setMinorChange] = useState(false)
    const [majorChange, setMajorChange] = useState(false)
    const [showUpdateBox, setShowUpdateBox] = useState(false)
    const [loadUpdateText, setLoadUpdateText] = useState(false)
    const [status, setStatus] = useState("Active")


    const timezoneOffset = "T00:00:00-07:00"

    const { token } = theme.useToken();

    const switchStyle = {
        fontSize: '16px', // Adjust this to your preferred size
    };

    const wrapperStyle = {
        width: 300,
        border: `1px solid ${token.colorBorderSecondary}`,
        borderRadius: token.borderRadiusLG,
    };

    const entryStyle = {
        margin: "20px",
        fontSize: '20px'
    }

    const inputStyle = {
        border: "0",
        outline: "none",
        borderBottom: "1px solid black",
        fontSize: '20px',
        background: "transparent"
    }

    const displayErrorMessage = (message) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };

    async function fetchInvestors() {
        await get(api.manager.investors, setInvestors, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            }), accessToken
        )
        setLoading(false)
    }

    async function fetchAccounts() {
        await get(api.manager.accounts, setAccounts, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            }), accessToken
        )
    }

    async function fetchPrevInterest(id) {
        await get(api.manager.interestByInvestment(id), setPrevInterest, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            }), accessToken
        )
    }

    async function fetchInvestment(id) {
        await get(api.manager.investment(id), setInvestment, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            }), accessToken
        )
    }

    async function fetchFunds() {
        await get(api.manager.funds, setFunds, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            }), accessToken
        )
        setLoading(false)
    }

    async function fetchFinalPayout(id) {
        await get(api.manager.finalPayout(id), setFinalPayout, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            }), accessToken
        )
    }
    async function fetchInvestmentEmails(id) {
        await get(api.manager.investmentEmails(id), setInvestmentEmails, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            }), accessToken
        )
        setLoading(false)
    }
    // console.log(investment)
    async function fetchPrincipalTakeout(id) {
        await get(api.manager.investmentPrincipalTakeOut(id), setPrincipalTakenOut, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            }), accessToken
        )
        setLoading(false)
    }
    // console.log("finalPayout", finalPayout)
    // console.log(prevInterest)
    // console.log(principalTakenOut)
    // init, get investors and funds
    // console.log(investment)
    useEffect(() => {
        if (manageRecordId < 0) {
            setLoading(false)
            return
        }
        if (investment !== null) {
            // for existing investment, don't display all investors and funds
            // but need to retrive principal takeout information
            if (investment.payout_frequency.toLowerCase() === "end of project payout") {
                fetchPrevInterest(manageRecordId)
            }
            if (parseFloat(overallInterest) > parseFloat(interest) || investment.payout_frequency.toLowerCase() === "end of project payout") {
                fetchFinalPayout(manageRecordId)
            }
            fetchInvestmentEmails(manageRecordId)
            fetchPrincipalTakeout(manageRecordId)
            setFrequency(investment?.payout_frequency ?? "")
            setEndDate(investment?.actual_redemption_date?.slice(0, 10) ?? "")
            setStartDate(investment?.funding_receipt_date?.slice(0, 10) ?? "")
            setInterest(investment?.annual_interest_rate ?? 0)
            setOverallInterest(investment?.overall_interest ?? 0)
            setType(investment?.investment_type ?? "")
            setSubtype(investment?.subtype ?? null)
            setAccountIdSelected(investment.account_id)
            setFundIdSelected(investment.fund_nameid)
            setAmount(investment.amount)
            setInterest(investment.annual_interest_rate)
            setFrequency(investment.payout_frequency)
            setStatus(investment.investment_status)
            return
        }

    }, [investment])

    function restore() {
        setFrequency(investment?.payout_frequency ?? "")
        setEndDate(investment?.actual_redemption_date?.slice(0, 10) ?? "")
        setStartDate(investment?.funding_receipt_date?.slice(0, 10) ?? "")
        setInterest(investment?.annual_interest_rate ?? 0)
        setOverallInterest(investment?.overall_interest ?? 0)
        setType(investment?.investment_type ?? "")
        setSubtype(investment?.subtype ?? null)
        setAccountIdSelected(investment.account_id)
        setFundIdSelected(investment.fund_nameid)
        setAmount(investment.amount)
        setInterest(investment.annual_interest_rate)
        setFrequency(investment.payout_frequency)
        setStatus(investment.investment_status)
    }

    useEffect(() => {
        fetchFunds()
        // fetchInvestors()
        fetchAccounts()
        if (manageRecordId > 0) {
            fetchInvestment(manageRecordId)
        }
    }, [])

    function mapNameToId(entities, getKey) {
        var mapRes = {}
        for (const entity of entities) {
            mapRes[getKey(entity)] = entity.id
        }
        return mapRes
    }

    const investorNameToId = mapNameToId(investors, investorStructToDisplayedName)
    const fundNameToId = mapNameToId(funds, fundEntityToName)
    var accountName2id = {}
    for (const account of accounts) {
        accountName2id[account.account_name] = account.id
    }

    const subTypes = {
        debt: [
            "Senior Loan",
            "2nd lien loan",
            "PPM-Notes",
            "Unsecured loan"
        ],
        equity: [
            "PPM-Preferred Equity",
            "Preferred Equity",
            "Common Equity",
            "Debt Fund",
            "BRRRR"
        ]
    }

    // console.log(investors)
    // console.log(accounts)
    // console.log(funds)
    // console.log("principalTakenOut", principalTakenOut)

    // console.log(investment)
    // ----------------- investor selecion -------------------
    const handleChangeInvestor = (investor) => {
        // console.log(`investor ${investor} with id ${investorNameToId[investor]} selected`)
        setInvestorIdSelected(investorNameToId[investor])
        // companies: companies names
        // setCompanyIdxSelected(companies.map(company => holdingCompany2idx[company]))
        // setDirty(true)
    };
    const investorSelectionOptions = investors
        .sort((a, b) => {
            if (investorStructToDisplayedName(a) < investorStructToDisplayedName(b)) {
                return -1
            }
            return 1
        })
        .map(investor => {
            return {
                label: investorStructToDisplayedName(investor),
                value: investorStructToDisplayedName(investor),
                desc: investorStructToDisplayedName(investor),
            }
        })
    // console.log(accountIdSelected)
    // ----------------- account selecion -------------------
    const handleChangeAccount = (account) => {
        // console.log(accountName2id[account])
        // console.log(`investor ${investor} with id ${investorNameToId[investor]} selected`)
        setAccountIdSelected(accountName2id[account])
        // companies: companies names
        // setCompanyIdxSelected(companies.map(company => holdingCompany2idx[company]))
        // setDirty(true)
    };

    const accountSelectionOptions = accounts
        .sort((a, b) => {
            if (a.account_name < b.account_name) {
                return -1
            }
            return 1
        })
        .map(account => {
            return {
                label: account.account_name,
                value: account.account_name,
                desc: account.account_name,
            }
        })

    // ----------------- fund selecion -------------------
    const handleChangeFund = (fund) => {
        //console.log(`fund ${fund} with id ${fundNameToId[fund]} selected`)
        setFundIdSelected(fundNameToId[fund])
        // companies: companies names
        // setCompanyIdxSelected(companies.map(company => holdingCompany2idx[company]))
        // setDirty(true)
    };

    const fundSelectionOptions = funds
        .sort((a, b) => {
            if (a.name < b.name) {
                return -1
            }
            return 1
        })
        .map(fund => {
            return {
                label: fund.name,
                value: fund.name,
                desc: fund.name,
            }
        })

    // ----------------- type selecion -------------------
    const handleChangeType = (type) => {
        setType(type)
        setSubtype(null)
    };

    const typeSelectionOptions = [{
        label: "Debt",
        value: "Debt",
        desc: "Debt",
    }, {
        label: "Equity",
        value: "Equity",
        desc: "Equity",
    }]


    // ----------------- method selecion -------------------

    const handleChangeMethod = (method) => {
        setMethod(method)
        //console.log(method)
    };

    const methodSelectionOptions = [{
        label: "Reinvest",
        value: "Reinvest",
        desc: "Reinvest",
    }, {
        label: "Cashout",
        value: "Cashout",
        desc: "Cashout",
    }]
    //  ----------------- start date selection -------------------
    const onStartDateChange = (value, mode) => {
        //console.log(value.format('YYYY-MM-DD'), mode);
    };

    // ----------------- frequency selecion -------------------

    const handleChangeFrequency = (frequency) => {
        setFrequency(frequency)
    };

    const frequencySelectionOptions = [
        {
            label: "End of Project Payout",
            value: "End of Project Payout",
            desc: "End of Project Payout",
        }, {
            label: "Annually payout",
            value: "Annually payout",
            desc: "Annually payout",
        }, {
            label: "Semi annually payout",
            value: "Semi annually payout",
            desc: "Semi annually payout",
        }, {
            label: "Quarterly payout",
            value: "Quarterly payout",
            desc: "Quarterly payout",
        }, {
            label: "Monthly payout",
            value: "Monthly payout",
            desc: "Monthly payout",
        }, {
            label: "Customized",
            value: "Customized",
            desc: "Customized",
        }]

    function canSubmit() {
        var invalidFields = []
        if (investment === null) {
            // if (investorIdSelected < 0) {
            //     invalidFields.push("Please select investor.")
            // }

            if (accountIdSelected < 0) {
                invalidFields.push("Please select account.")
            }

            if (fundIdSelected < 0) {
                invalidFields.push("Please select fund.")
            }

            if (method === "") {
                invalidFields.push("Please select method.")
            }

            if (amount === 0) {
                invalidFields.push("Please enter amount.")
            }

            if (startDate === "") {
                invalidFields.push("Please select start date.")
            }

            if (endDate === "") {
                invalidFields.push("Please select end date.")
            }

            if (interest === 0) {
                invalidFields.push("Please enter annual interest rate.")
            }

            if (frequency === 0) {
                invalidFields.push("Please enter payout frequency.")
            }
        }

        setCreationErrorMessages(invalidFields)
        return invalidFields.length === 0;
    }
    // console.log("2024-11-01" > "2024-05-31")
    function isMajorChange() {
        // fetchInvestmentEmails(manageRecordId)
        // fetchPrincipalTakeout(manageRecordId)
        // setFrequency(investment?.payout_frequency ?? "")
        // setEndDate(investment?.actual_redemption_date?.slice(0, 10) ?? "")
        // setStartDate(investment?.funding_receipt_date?.slice(0, 10) ?? "")
        // setInterest(investment?.annual_interest_rate ?? 0)
        // setOverallInterest(investment?.overall_interest ?? 0)
        // setType(investment?.type ?? "")
        // setAccountIdSelected(investment.account_id)
        // setFundIdSelected(investment.fund_nameid)
        // setAmount(investment.amount)
        // setOverallInterest(investment.overallInterest)
        // setInterest(investment.annual_interest_rate)
        // setFrequency(investment.payout_frequency)
        if (principalTakeOut.length > 0) {
            const minDate = new Date(Math.min(...principalTakeOut
                .map(entry => new Date(entry[0] + timezoneOffset))))
            const minDateString = minDate.toISOString().split('T')[0]
            const todayDateString = (new Date()).toISOString().split('T')[0]
            if (minDateString <= todayDateString) {
                return true
            }
        }

        return (method !== investment.method ||
            amount !== investment.amount ||
            startDate !== investment?.funding_receipt_date?.slice(0, 10) ||
            // endDate !== investment?.actual_redemption_date?.slice(0, 10) ||
            // overallInterest !== investment?.overall_interest || 
            interest !== investment.interest ||
            frequency !== investment.payout_frequency)
    }
    // console.log(principalTakeOut)
    // overall map from date to principal take out
    function getPrincipalTakeOutMap() {
        return [...principalTakeOut
            .map(principal => [principal[0], parseFloat(principal[1]) || 0]),
        ...principalTakenOut
            .map((principalTakenOut) => [principalTakenOut.timestamp, principalTakenOut.amount])]
            .filter((principalTakeOutEntry, idx) => {
                return principalTakeOutEntry[1] > 0
            })
            .reduce((acc, [date, value]) => {
                if (acc[date]) {
                    acc[date] += value
                } else {
                    acc[date] = value
                }
                return acc
            }, {})
    }

    // a list of newly added principal take out amount and date
    function getNewPrincipalTakeOut() {
        return principalTakeOut
            .filter((principalTakeOutEntry, idx) => {
                return principalTakeOutEntry[1] > 0
            })
            .sort((a, b) => {
                if (a[0] < b[0]) {
                    return 1
                }
                return -1
            })
    }

    //console.log("amount", amount)
    async function saveInvestment() {
        // if (investorIdSelected < 0 || fundIdSelected < 0) {
        //     return
        // }
        // console.log(`Investor id: ${investorIdSelected}`)
        // console.log(`Fund id: ${fundIdSelected}`)
        // console.log(`Method: ${method}`)
        // console.log(`Amount: ${amount}`)
        // console.log(`Start date: ${startDate}`)
        // console.log(`End date: ${endDate}`)
        // console.log(`Annual interest: ${interest}`)
        // console.log(`Payout frequency: ${frequency}`)

        if (canSubmit() === false) {
            return
        }
        // console.log(accountIdSelected)
        if (investment === null) {
            const body = JSON.stringify({
                // investorIdSelected,
                accountIdSelected,
                fundIdSelected,
                method,
                "amount": parseFloat(amount),
                startDate,
                endDate,
                interest: parseFloat(interest),
                overallInterest: parseFloat(overallInterest) || 0,
                frequency,
                account,
                finalPayout: finalPayout.length > 0 ? finalPayout : 0,
                type,
                subtype
            })

            const options = {
                method: "POST", // *GET, POST, PUT, DELETE, etc.
                mode: "cors", // no-cors, *cors, same-origin
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${accessToken}`,
                },
                body
            }
            await fetch(api.manager.investments, options); // new record
        } else {


            const majorChange = isMajorChange()
            const minorChange = accountIdSelected !== investment.account_id || fundIdSelected !== investment.fund_nameid
            setMajorChange(majorChange)
            setMinorChange(minorChange)
            if (majorChange || minorChange) {
                // updateChangeOfInvestmentText(majorChange, minorChange)
                setShowUpdateBox(true)
                return
                // }
                // if (!majorChange && minorChange) {
                //     // need overall map and new taken out map
                //     updateWithMinorChange(principalTakeOutMap, newPrincipalTakeOut)
                // } else if (majorChange) {
                //     // just need overall map to override existing map
                //     updateWithMajorChange(principalTakeOutMap)
            } else {
                // combine previous taken out and newly taken out principal
                const principalTakeOutMap = getPrincipalTakeOutMap()
                // it is ok to have duplicate date entry in transaction database since we aggregate principal taken out on each day prior to calculation
                const newPrincipalTakeOut = getNewPrincipalTakeOut()
                const body = JSON.stringify({
                    id: manageRecordId,
                    startDate: investment.funding_receipt_date.slice(0, 10),
                    endDate: endDate,
                    frequency: investment.payout_frequency,
                    method: investment.method,
                    overallInterest: parseFloat(overallInterest),
                    interest: investment.interest,
                    amount: investment.amount,
                    // overall principal taken out aggregated by date (map)
                    principalTakenOut: principalTakeOutMap,
                    // new principal taken out (list)
                    newPrincipalTakeOut,
                    // newly manually added interest
                    manuallyAddedInterestEntry: manuallyAddedInterestEntry.map(entry => [entry[0], parseFloat(entry[1]) || 0]),
                    endDateChanged,
                    finalPayout: finalPayout.length > 0 ? finalPayout : 0,
                    majorChange,
                    minorChange,
                    status,
                    type,
                    subtype
                })

                const options = {
                    method: "POST", // *GET, POST, PUT, DELETE, etc.
                    mode: "cors", // no-cors, *cors, same-origin
                    headers: {
                        "Content-Type": "application/json",
                        'Authorization': `Bearer ${accessToken}`,
                    },
                    body
                }
                await fetch(api.manager.investment(manageRecordId), options); // old record
            }
        }
        setManageRecordId(0)
    }

    useEffect(() => {
        if (!majorChange && !minorChange) {
            return
        }
        updateChangeOfInvestmentText()
    }, [majorChange, minorChange])

    async function updateChangeOfInvestmentText() {
        setLoadUpdateText(true)
        var hintText = ""
        if (minorChange) {
            hintText += `You are about to change the investment from ${investment.account_name} and ${investment.fund_name} to ${accounts.filter(account => account.id === accountIdSelected)[0].account_name} and ${funds.filter(fund => fund.id === fundIdSelected)[0].name}.\n`
        }
        if (majorChange) {
            const data = await fetch(api.manager.paidInterests(manageRecordId), {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                }
            })
            const paidInterest = await data.json()
            if (paidInterest.data.length > 0) {
                hintText += "The following paid amount will be set to unpaid:\n"
                hintText += paidInterest.data.map(interest => `${USDollar.format(interest.amount)} ${interest.type}`).join('\n')
                hintText += '\n'
            }
        }
        hintText += `Please type "Update" to confirm update.`
        setChangeOfInvestmentText(hintText)
        setLoadUpdateText(false)
        // console.log(hintText)
    }
    // console.log(amount)
    async function updateCallback() {
        const principalTakeOutMap = getPrincipalTakeOutMap()
        const newPrincipalTakeOut = getNewPrincipalTakeOut()
        // console.log("Major change: " + majorChange)
        // console.log("Minor change: " + minorChange)

        const body = JSON.stringify({
            id: manageRecordId,
            startDate: startDate,
            endDate: endDate,
            frequency: frequency,
            method: method,
            overallInterest: parseFloat(overallInterest),
            interest: interest,
            amount: amount,
            // overall principal taken out aggregated by date (map)
            principalTakenOut: principalTakeOutMap,
            // new principal taken out (list)
            newPrincipalTakeOut,
            // newly manually added interest
            manuallyAddedInterestEntry: manuallyAddedInterestEntry.map(entry => [entry[0], parseFloat(entry[1]) || 0]),
            endDateChanged,
            finalPayout: finalPayout.length > 0 ? finalPayout : 0,
            majorChange,
            minorChange,
            accountIdSelected,
            fundIdSelected,
            status,
            type,
            subtype
        })

        const options = {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${accessToken}`,
            },
            body
        }
        await fetch(api.manager.investment(manageRecordId), options); // old record
        setShowUpdateBox(false)
        setManageRecordId(0)
    }

    async function updateWithMinorChange(principalTakeOutMap, newPrincipalTakeOut) {
        const body = JSON.stringify({
            id: manageRecordId,
            startDate: investment.funding_receipt_date.slice(0, 10),
            endDate: endDate,
            frequency: investment.payout_frequency,
            method: investment.method,
            overallInterest: parseFloat(overallInterest),
            interest: investment.interest,
            amount: investment.amount,
            // overall principal taken out aggregated by date (map)
            principalTakenOut: principalTakeOutMap,
            // new principal taken out (list)
            newPrincipalTakeOut,
            // newly manually added interest
            manuallyAddedInterestEntry: manuallyAddedInterestEntry.map(entry => [entry[0], parseFloat(entry[1]) || 0]),
            endDateChanged,
            finalPayout: finalPayout.length > 0 ? finalPayout : 0,
            majorChange,
            minorChange
        })

        const options = {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${accessToken}`,
            },
            body
        }
        await fetch(api.manager.investment(manageRecordId), options); // old record
    }

    async function updateWithMajorChange(principalTakeOutMap) {
        const body = JSON.stringify({
            id: manageRecordId,
            startDate: investment.funding_receipt_date.slice(0, 10),
            endDate: endDate,
            frequency: investment.payout_frequency,
            method: investment.method,
            overallInterest: parseFloat(overallInterest),
            interest: investment.interest,
            amount: investment.amount,
            // overall principal taken out aggregated by date (map)
            principalTakenOut: principalTakeOutMap,
            // new principal taken out (list)
            // newPrincipalTakeOut,
            // newly manually added interest
            manuallyAddedInterestEntry: manuallyAddedInterestEntry.map(entry => [entry[0], parseFloat(entry[1]) || 0]),
            endDateChanged,
            finalPayout: finalPayout.length > 0 ? finalPayout : 0,
            majorChange,
            minorChange
        })

        const options = {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${accessToken}`,
            },
            body
        }
        await fetch(api.manager.investment(manageRecordId), options); // old record
    }

    function addPrincipalTakeOutEntry() {
        setPrincipalTakeOut([...principalTakeOut, [getTomorrowDateString(), ""]])
    }

    function addInteretEntry() {
        setManuallyAddedInterestEntry([...manuallyAddedInterestEntry, [getTomorrowDateString(), ""]])
    }

    async function deletionCallback() {
        const options = {
            method: "DELETE", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${accessToken}`,
            },
            // body
        }
        const result = await fetch(api.manager.investment(manageRecordId), options);
        const data = await result.json()
        setManageRecordId(0)
    }


    function deleteInvestment() {
        setShowDeletionBox(true)
    }
    // console.log(investment)
    // console.log(manuallyAddedInterestEntry)
    function handleInvestmentStatusSwitchChange(status) {
        if (status) {
            setStatus("Active")
        } else {
            setStatus("Closed")
        }
    }

    return <>
        {loading && <div style={{ marginTop: "30px", textAlign: "center" }}><Spin /></div>}
        {!loading && view === "files" && <div>
            <InvestmentFileManager setView={setView} investmentId={manageRecordId} />
        </div>}
        {!loading && view === "interest" && <div>
            <InterestManager setView={setView} investmentId={manageRecordId} frequency={frequency} />
        </div>}
        {!loading && view === "principal" && <div>
            <PrincipalManager
                setView={setView}
                investmentId={manageRecordId}
                startDate={startDate}
                endDate={endDate}
                frequency={frequency}
                method={method}
                interest={interest}
                amount={amount}
            />
        </div>}
        {!loading && view === "email" && <Email
            setView={setView}
            emailPassed={investmentEmails}
            fromView="investment"
            setEmailResponse={setEmailResponse}
            fromId={manageRecordId}
        />}
        {!loading && view === "investment" &&
            <div>
                {showUpdateBox && <InvestmentUpdateConfirmation
                    text={changeOfInvestmentText}
                    setShowUpdateBox={setShowUpdateBox}
                    updateCallback={updateCallback}
                    setMajorChange={setMajorChange}
                    setMinorChange={setMinorChange}
                    setChangeOfInvestmentText={setChangeOfInvestmentText}
                    loadUpdateText={loadUpdateText} />}
                <EmailResultBox emailResponse={emailResponse} />
                {investment !== null && showDeletionBox &&
                    <DeleteConfirmation
                        title={`Investment: ${investment.account_name} - ${investment.fund_name}`}
                        setShowDeletionBox={setShowDeletionBox}
                        deletionCallback={deletionCallback}
                    />
                }

                <div style={{ margin: "20px", display: "flex", justifyContent: "space-between" }}>
                    <img style={{ width: "15px", height: "20px", cursor: "pointer" }}
                        src={backArrow}
                        onClick={() => setManageRecordId(0)}
                    ></img>

                    {investment !== null && <div style={{ display: "flex" }}>
                        <button style={{
                            height: "30px",
                            width: "60px",
                            borderRadius: "30px",
                            background: "rgb(17 32 86)",
                            color: "white",
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            placeContent: "center"
                        }}
                            onClick={e => setView("files")}
                        >
                            Files
                        </button>

                        <button style={{
                            marginLeft: "10px",
                            height: "30px",
                            width: "100px",
                            borderRadius: "30px",
                            background: "rgb(17 32 86)",
                            color: "white",
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            placeContent: "center"
                        }}
                            onClick={e => setView("interest")}
                        >
                            View Interests
                        </button>

                        <button style={{
                            marginLeft: "10px",
                            height: "30px",
                            width: "80px",
                            borderRadius: "30px",
                            background: "rgb(17 32 86)",
                            color: "white",
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            placeContent: "center"
                        }}
                            onClick={e => setView("principal")}
                        >
                            Principal
                        </button>

                        <button style={{
                            marginLeft: "10px",
                            height: "30px",
                            width: "70px",
                            borderRadius: "30px",
                            background: "rgb(17 32 86)",
                            color: "white",
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            placeContent: "center"
                        }}
                            onClick={e => setView("email")}
                        >
                            Email
                        </button>
                    </div>}
                </div>

                {creationErrorMessages.map((message, idx) => {
                    return <div key={idx} style={{ color: "red", marginLeft: "20px" }}>{message}</div>
                })}

                {investment !== null && <div onClick={e => {
                    if (editMode) {
                        restore()
                    }
                    setEditMode(!editMode)
                }}
                    style={{
                        width: "fit-content",
                        color: "blue",
                        textDecoration: "underline",
                        cursor: "pointer",
                        marginLeft: "20px"
                    }}>
                    {editMode ? "Restore" : "Edit"}
                </div>}

                <div style={isPhoneSize() ? entryStyle : { ...entryStyle, display: "flex" }}>
                    <div>Account{investment === null && <span style={{ color: "red" }}>*</span>}:</div>

                    {editMode &&
                        <span>
                            <Select
                                showSearch
                                mode="tag"
                                style={{ width: '300px', marginLeft: "5px" }}
                                placeholder="select investor"
                                defaultValue={investment === null ? [] : [investment.account_name]}
                                onChange={handleChangeAccount}
                                options={accountSelectionOptions}
                                optionRender={(option) => (
                                    <Space>
                                        <span role="img" aria-label={option.data.label}>
                                            {option.data.emoji}
                                        </span>
                                        {option.data.desc}
                                    </Space>
                                )}
                            />
                        </span>}
                    {!editMode &&
                        <span>
                            {investment && investment.account_name}
                        </span>
                    }
                </div>

                <div style={isPhoneSize() ? entryStyle : { ...entryStyle, display: "flex" }}>
                    <div>Fund{investment === null && <span style={{ color: "red" }}>*</span>}:</div>

                    {editMode && <span>
                        <Select
                            showSearch
                            mode="tag"
                            style={{ width: '300px', marginLeft: "5px" }}
                            placeholder="select fund"
                            defaultValue={investment === null ? [] : investment.fund_name}
                            onChange={handleChangeFund}
                            options={fundSelectionOptions}
                            optionRender={(option) => (
                                <Space>
                                    <span role="img" aria-label={option.data.label}>
                                        {option.data.emoji}
                                    </span>
                                    {option.data.desc}
                                </Space>
                            )}
                        />
                    </span>}
                    {!editMode &&
                        <span>
                            {investment && investment.fund_name}
                        </span>
                    }
                </div>

                <div style={isPhoneSize() ? entryStyle : { ...entryStyle, display: "flex" }}>
                    <div>Type:</div>
                    {editMode && <span>
                        <Select
                            showSearch
                            mode="tag"
                            style={{ width: '300px', marginLeft: "5px" }}
                            placeholder="select type"
                            value={type || []}
                            onChange={handleChangeType}
                            options={typeSelectionOptions}
                            optionRender={(option) => (
                                <Space>
                                    <span role="img" aria-label={option.data.label}>
                                        {option.data.emoji}
                                    </span>
                                    {option.data.desc}
                                </Space>
                            )}
                        />
                    </span>}
                    {!editMode &&
                        <span>
                            {type}
                        </span>
                    }
                </div>

                <div style={isPhoneSize() ? entryStyle : { ...entryStyle, display: "flex" }}>
                    <div>Subtype:</div>
                    {editMode && <span>
                        <Select
                            showSearch
                            mode="tag"
                            style={{ width: '300px', marginLeft: "5px" }}
                            placeholder="select subtype"
                            value={subtype || []}
                            onChange={setSubtype}
                            options={type.length > 0 ? subTypes[type.toLowerCase()].map(item => (
                                {
                                    label: item,
                                    value: item,
                                    desc: item
                                })) : []}
                            optionRender={(option) => (
                                <Space>
                                    <span role="img" aria-label={option.data.label}>
                                        {option.data.emoji}
                                    </span>
                                    {option.data.desc}
                                </Space>
                            )}
                        />
                    </span>}
                    {!editMode &&
                        <span>
                            {subtype}
                        </span>
                    }
                </div>

                <div style={isPhoneSize() ? entryStyle : { ...entryStyle, display: "flex" }}>
                    <div>Method{investment === null && <span style={{ color: "red" }}>*</span>}:</div>
                    {editMode && <span>
                        <Select
                            showSearch
                            mode="tag"
                            style={{ width: '300px', marginLeft: "5px" }}
                            placeholder="select method"
                            defaultValue={investment === null ? ["Cashout"] : [investment.method]}
                            onChange={handleChangeMethod}
                            options={methodSelectionOptions}
                            optionRender={(option) => (
                                <Space>
                                    <span role="img" aria-label={option.data.label}>
                                        {option.data.emoji}
                                    </span>
                                    {option.data.desc}
                                </Space>
                            )}
                        />
                    </span>}
                    {!editMode &&
                        <span>
                            {investment && investment.method}
                        </span>
                    }
                </div>

                <div style={isPhoneSize() ? entryStyle : { ...entryStyle, display: "flex" }}>
                    <div>Amount{investment === null && <span style={{ color: "red" }}>*</span>}:</div>

                    {editMode && <><input value={amount} style={{ ...inputStyle, ...{ width: "150px" } }}
                        onInput={e => setAmount(e.target.value)}>
                    </input>

                        {amount > 0 && `(${USDollar.format(amount)})`}</>}
                    {/* {investment !== null && <span>
                        {' ' + USDollar.format(investment.amount)}
                    </span>} */}
                    {!editMode &&
                        <span>
                            {USDollar.format(amount)}
                        </span>
                    }
                </div>

                <div style={isPhoneSize() ? entryStyle : { ...entryStyle, display: "flex" }}>
                    <div>Start Date{investment === null && <span style={{ color: "red" }}>*</span>}:</div>
                    {editMode && <>{investment === null &&
                        <input type="date" onChange={e => setStartDate(e.target.value)}></input>}
                        {investment !== null && <span>
                            <input type="date" value={startDate} onChange={e => {
                                setStartDate(e.target.value)
                            }}></input>
                        </span>}</>}
                    {!editMode &&
                        <span>
                            {startDate}
                        </span>
                    }
                </div>

                <div style={isPhoneSize() ? entryStyle : { ...entryStyle, display: "flex" }}>
                    <div>End Date{investment === null && <span style={{ color: "red" }}>*</span>}:</div>
                    {editMode && <>{investment === null &&
                        <input type="date" onChange={e => setEndDate(e.target.value)}></input>}
                        {investment !== null && <span>
                            <input type="date" value={endDate} onChange={e => {
                                setEndDateChanged(true)
                                setEndDate(e.target.value)
                            }}></input>
                            {/* {' ' + investment.actual_redemption_date.slice(0, 10)} */}
                        </span>}</>}
                    {!editMode &&
                        <span>
                            {endDate}
                        </span>
                    }
                </div>

                <div style={isPhoneSize() ? entryStyle : { ...entryStyle, display: "flex" }}>
                    <div>Overall Interest Rate:</div>
                    {editMode && <>{investment === null && <><input style={inputStyle}
                        onInput={e => setOverallInterest(e.target.value)}></input>%</>}
                        {investment !== null && <><input style={inputStyle} value={overallInterest}
                            onInput={e => setOverallInterest(e.target.value)}></input>%</>}</>}
                    {!editMode &&
                        <span>
                            {Math.max(overallInterest, interest)}
                        </span>
                    }
                </div>

                <div style={isPhoneSize() ? entryStyle : { ...entryStyle, display: "flex" }}>
                    <div>Calculated Interest Rate{investment === null && <span style={{ color: "red" }}>*</span>}:</div>
                    {editMode && <>{investment === null && <><input style={inputStyle}
                        onInput={e => setInterest(e.target.value)}></input>%</>}
                        {investment !== null && <><input style={inputStyle} value={interest}
                            onInput={e => setInterest(e.target.value)}></input>%</>}</>}
                    {!editMode &&
                        <span>
                            {interest}
                        </span>
                    }
                </div>

                <div style={isPhoneSize() ? entryStyle : { ...entryStyle, display: "flex" }}>
                    <div>Payout Frequency{investment === null && <span style={{ color: "red" }}>*</span>}:</div>
                    {investment === null &&
                        <span>
                            <Select
                                showSearch
                                mode="tag"
                                style={isPhoneSize() ? {
                                    width: '300px'
                                } : {
                                    width: '300px', marginLeft: "5px"
                                }}
                                placeholder="select frequency"
                                defaultValue={[]}
                                onChange={handleChangeFrequency}
                                options={frequencySelectionOptions}
                                optionRender={(option) => (
                                    <Space>
                                        <span role="img" aria-label={option.data.label}>
                                            {option.data.emoji}
                                        </span>
                                        {option.data.desc}
                                    </Space>
                                )}
                            />
                        </span>}
                    {investment !== null && <span>
                        {' ' + investment.payout_frequency}
                    </span>}
                </div>

                {investment !== null && <div style={entryStyle}>
                    Status: {editMode && <Switch
                        // className="investment-status-switch"
                        checkedChildren="Active"
                        unCheckedChildren="Closed"
                        onChange={handleInvestmentStatusSwitchChange}
                        checked={status.toLowerCase() === "active"} />}
                    {!editMode && status[0].toUpperCase() + status.slice(1)}
                </div>}

                {investment !== null && <div style={isPhoneSize() ? entryStyle : { ...entryStyle, display: "flex" }}>
                    <div>Account:</div>
                    <span style={{
                        // fontSize: "20px",
                        // margin: "20px",
                        width: "fit-content",
                        color: "blue",
                        textDecoration: "underline",
                        cursor: "pointer"
                    }}
                        onClick={() => {
                            setManageRecordId(investment.account_id)
                            setManageRecordTab("Accounts")
                        }}>
                        {' ' + investment.account_name}
                    </span>
                </div>}

                {investment !== null && <div style={isPhoneSize() ? entryStyle : { ...entryStyle, display: "flex" }}>
                    <div>Fund:</div>
                    <span style={{
                        // fontSize: "20px",
                        // margin: "20px",
                        width: "fit-content",
                        color: "blue",
                        textDecoration: "underline",
                        cursor: "pointer"
                    }}
                        onClick={() => {
                            setManageRecordId(investment.fund_nameid)
                            setManageRecordTab("Entities/Funds")
                        }}>
                        {' ' + investment.fund_name}
                    </span>
                </div>}

                <div style={{ margin: "20px", display: "flex", justifyContent: "space-between" }}>
                    <button style={{
                        height: "30px",
                        width: "50px",
                        borderRadius: "30px",
                        background: "rgb(17 32 86)",
                        color: "white",
                        cursor: "pointer",
                    }}
                        onClick={saveInvestment}
                    >
                        Save
                    </button>
                    {investment !== null && <button style={{
                        height: "30px",
                        width: "70px",
                        borderRadius: "30px",
                        background: "red",
                        color: "white",
                        cursor: "pointer",
                    }}
                        onClick={deleteInvestment}
                    >
                        Delete
                    </button>}
                </div>
            </div>}
    </>
}