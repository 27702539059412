import React from 'react';
import { Card } from 'antd';
import { USDollar } from 'utils/format';
import Paragraph from 'antd/es/typography/Paragraph';
import viewMoreShape from '../assets/img/viewMoreShape.png'
import { getTypeBackgroundColor } from 'utils/util';

export type OfferingCardProps = {
    name: string
    type: string
    link: string
    description?: string
    target_offerings_total: number
    target_offerings_debt?: number
    target_offerings_equity?: number
    target_offerings_preferred?: number
    actual_raised_total?: number
    actual_raised_debt?: number
    actual_raised_equity?: number
    actual_raised_preferred?: number
    imageSrc?: string
}

export default ({
    name, type,
    link, description,
    target_offerings_total,
    target_offerings_debt,
    target_offerings_equity,
    target_offerings_preferred,
    actual_raised_total,
    actual_raised_debt,
    actual_raised_equity,
    actual_raised_preferred,
    imageSrc
}: OfferingCardProps) => {
    let amountFormatted = USDollar.format(target_offerings_total);
    let fontSize = 24;
    if (amountFormatted.length > 14) {
        fontSize = 20;
    } else if (amountFormatted.length > 20) {
        fontSize = 16
    }

    function getTypeTagWidth() {
        if (type.toLowerCase() === "debt" || type.toLowerCase() === "equity") {
            return "50px"
        }
        return "80px"
    }

    return <div style={{ width: 220, height: 300, background: "#F7F7F9", borderRadius: "12px" }}>
        <div>
            <img style={{ marginTop: "10px", width: "100%", maxWidth: "220px", aspectRatio: "300 / 222", overflow: "hidden" }} src={imageSrc || 'https://static.wixstatic.com/media/1e11ac_c9300dd8e54c4e5397141c2246af71c6~mv2.png/v1/fill/w_948,h_1226,al_t,q_90,usm_0.66_1.00_0.01,enc_auto/1e11ac_c9300dd8e54c4e5397141c2246af71c6~mv2.png'}></img>
        </div>
        <div style={{ fontFamily: "Arial", fontSize: name.length > 18 ? "17px" : "20px", fontWeight: "normal", lineHeight: "24px", display: "flex", letterSpacing: "0em", padding: name.length > 18 ? "5px 10px" : "10px" }}>
            {name}
        </div>
        <div style={{ display: "flex", flexDirection: "row", paddingLeft: "10px" }}>
            {type.split('/').map((curType, idx) => <div key={idx} style={{
                width: "54px",
                height: "24px",
                fontFamily: "PingFang SC",
                fontSize: "14px",
                fontWeight: "600",
                lineHeight: "18px",
                letterSpacing: "0em",
                color: "#FFFFFF",
                background: getTypeBackgroundColor(curType),
                borderRadius: "4px",
                alignContent: "center",
                textAlign: "center",
                marginRight: "10px"
            }}>
                {curType}
            </div>)}
        </div>

        <div style={{ placeItems: "center", padding: name.length > 18 ? "7px 10px" : "12px 10px" }}>
            <div style={{ display: "flex", alignItems: "center", width: "200px", height: "25px", background: "white", placeContent: "center", borderRadius: "8px", cursor: "pointer" }}
                onClick={() => window.open(link, '_blank', 'noopener,noreferrer')}
            >
                <div style={{ fontFamily: "Arial", fontSize: "14px", fontWeight: "normal", lineHeight: "18px", letterSpacing: "0em", color: "#1777FF" }}>
                    {"View More"}
                </div>
                <img style={{ height: "12px", width: "6.6px", marginLeft: "10px" }} src={viewMoreShape} alt="" />
            </div>
        </div>
    </div >
    // <Card hoverable bordered={false} style={{ width: 220, height: 200 }} onClick={() => window.open(link, '_blank', 'noopener,noreferrer')}>
    //     <div style={{ display: "flex", placeContent: "space-between" }}>
    //         <h4 style={{ margin: 0 }}>{name}</h4>
    //         {
    //             type && <div style={{
    //                 marginRight: "20px",
    //                 background: getTypeBackgroundColor(),
    //                 color: "white",
    //                 display: "flex",
    //                 borderRadius: "10px",
    //                 width: getTypeTagWidth(),
    //                 height: "20px",
    //                 justifyContent: "center",
    //                 fontWeight: 'bold',
    //             }}>
    //                 {type}
    //             </div>
    //         }
    //     </div>
    //     {/* <div>Target Offering Total: {amountFormatted}</div> */}
    //     <Paragraph ellipsis={{ rows: 3, expandable: false, symbol: 'more' }}>
    //         {description}
    //     </Paragraph>
    // </Card>
}