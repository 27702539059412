// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.caret-icon {
    display: inline-block;
    transition: transform 0.3s ease;
    /* Smooth transition effect */
}

.rotate-up {
    transform: rotate(-180deg);
    /* Rotate to appear "up" */
}

.rotate-down {
    transform: rotate(0deg);
    /* Default position */
}`, "",{"version":3,"sources":["webpack://./src/pages/PayoutSummary/PayoutSummary.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,+BAA+B;IAC/B,6BAA6B;AACjC;;AAEA;IACI,0BAA0B;IAC1B,0BAA0B;AAC9B;;AAEA;IACI,uBAAuB;IACvB,qBAAqB;AACzB","sourcesContent":[".caret-icon {\n    display: inline-block;\n    transition: transform 0.3s ease;\n    /* Smooth transition effect */\n}\n\n.rotate-up {\n    transform: rotate(-180deg);\n    /* Rotate to appear \"up\" */\n}\n\n.rotate-down {\n    transform: rotate(0deg);\n    /* Default position */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
