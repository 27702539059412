import { useState } from "react"

export function SummaryFilter(props) {
    const { setFilterIsOpen, filters, setFilters } = props

    const filterNameStyle = {
        fontSize: "13px",
        margin: "10px 0"
    }

    const selectedOption = {
        background: "#e7f4f5",
        border: "0.1px solid #c7e4e8",
        color: "#007185",
        padding: "7px 9px",
        borderRadius: "7px",
        display: "inline",
        fontSize: "12px",
        minHeight: "33px",
        alignContent: "center",
        cursor: "pointer",
        // whiteSpace: "nowrap",
        boxSizing: "border-box",
        width: "fit-content"
    }

    const unselectedOption = {
        background: "rgb(244, 244, 244)",
        padding: "7px 9px",
        borderRadius: "7px",
        display: "inline",
        fontSize: "12px",
        minHeight: "33px",
        alignContent: "center",
        cursor: "pointer",
        width: "fit-content"
    }

    return <div style={{
        position: "fixed",
        top: "30px", /* distance from the top of the screen */
        left: "220px", /* distance from the left side of the screen */
        /* Adjust as needed for desired coordinates */
        background: "white",
        padding: "0 10px 10px 10px",
        width: "300px",
        border: "1px solid black",
        // borderRadius: "5px",
        // zIndex: "1000",
        display: "flex",
        flexDirection: "column"
    }}>
        <div style={filterNameStyle}>
            Closing Status in Period
        </div>
        <div style={filters.closedWithinPeriod.active ? selectedOption : unselectedOption}
            onClick={() => setFilters(prevFilters => ({
                ...prevFilters,
                closedWithinPeriod: {
                    active: !prevFilters.closedWithinPeriod.active
                }
            }))}>
            Contract Matured Within This Period
        </div>
    </div>
}