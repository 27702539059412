import api from "api"
import { useEffect, useState } from "react"
import { USDollar } from "utils/format";
import _ from 'lodash';
import { Spin } from "antd";
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { getTodayDateString, isPhoneSize, isAdmin, sendEmail, getTomorrow } from "utils/util";
import "./PayoutSummary.css"
import { SummaryFilter } from "./SummaryFilter";

export function PayoutSummary(props) {
    const [startDate, setStartDate] = useState(getTodayDateString())
    const [endDate, setEndDate] = useState(getTodayDateString())
    const [payoutsRaw, setPayoutsRaw] = useState([])
    const [payouts, setPayouts] = useState({})
    const [investorId2name, setInvestorId2name] = useState({})
    const [investorId2email, setInvestorId2email] = useState({})
    const [accountId2accountName, setAccountId2accountName] = useState({})
    const [transactionId2Status, setTransactionId2Status] = useState({})
    const [loading, setLoading] = useState(false)
    const [uiMessage, setUiMessage] = useState("")
    const [totalInterestAmount, setTotalInterestAmount] = useState(0)
    const [filterIsOpen, setFilterIsOpen] = useState(false)
    const timezoneOffset = "T00:00:00-07:00"

    useEffect(() => {
        const data = payoutsRaw
        console.log(data)
        var investorId2name = {}
        var totalInterestAmount = 0
        for (const entry of data) {
            // get sum of interet amount
            totalInterestAmount += entry.amount
            investorId2name[entry.investor_id] = entry.legal_name
        }
        setTotalInterestAmount(totalInterestAmount)
        setInvestorId2name(investorId2name)

        var investorId2email = {}
        for (const entry of data) {
            investorId2email[entry.investor_id] = entry.email
        }
        setInvestorId2email(investorId2email)

        var accountId2accountName = {}
        for (const entry of data) {
            accountId2accountName[entry.account_id] = entry.account_name
        }
        setAccountId2accountName(accountId2accountName)
        var groupByAccount = {}
        for (const entry of data) {
            if (!groupByAccount[entry.investor_id]) {
                groupByAccount[entry.investor_id] = {}
            }
            if (!groupByAccount[entry.investor_id][entry.account_id]) {
                groupByAccount[entry.investor_id][entry.account_id] = []
            }
            groupByAccount[entry.investor_id][entry.account_id].push(entry)
        }
        setPayouts(groupByAccount)

        var transactionId2Status = {}
        for (const entry of data) {
            transactionId2Status[entry.transaction_id] = entry.transaction_status
        }
        setTransactionId2Status(transactionId2Status)
        // console.log(groupByAccount)
        setLoading(false)
    }, [payoutsRaw])

    async function fetchActiveTransactions() {
        const res = await fetch(`${process.env.REACT_APP_BACKEND_HOST}${api.transaction.activeTransactions(startDate, endDate)}`)
        const data = (await res.json()).data
        setPayoutsRaw(data)

        if (data.length === 0) {
            setUiMessage("No records were found for the specified period.")
        } else {
            setUiMessage(`${data.length} records are displayed above.`)
        }
    }

    function handleStatusChange(transaction, email, name, type) {
        const updatedStatus = transactionId2Status[transaction.transaction_id].toLowerCase() === "unpaid" ? "paid" : "unpaid";
        fetch(api.transaction.setStatus(transaction.transaction_id), {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                status: updatedStatus,
            }),
        });

        const newMap = { ...transactionId2Status };
        // Update the investment value for the investor
        newMap[transaction.transaction_id] = updatedStatus;
        // Set the new map in the state
        setTransactionId2Status(newMap);
        sendEmail(updatedStatus === "paid" ? "Pay" : "Unpay", transaction.amount, email, name, type.toLowerCase());
    }

    async function fetchAllActiveTransactions() {
        const res = await fetch(`${process.env.REACT_APP_BACKEND_HOST}${api.transaction.allActiveTransactions}`)
        const data = (await res.json()).data
        setPayoutsRaw(data)

        if (data.length === 0) {
            setUiMessage("No records were found for the specified period.")
        } else {
            setUiMessage(`${data.length} records are displayed above.`)
        }
    }

    function getSummary() {
        setUiMessage("")
        setLoading(true)
        fetchActiveTransactions()
    }

    function getAllSummary() {
        setUiMessage("")
        setLoading(true)
        fetchAllActiveTransactions()
    }
    // useEffect(() => {
    //     fetchActiveTransactions()
    // }, [])
    // console.log(payoutsRaw)
    const content = <div style={{ height: "100vh" }}>

        <div style={isPhoneSize() ? {
            display: "flex", flexDirection: "column", marginBottom: "30px"
        } : {
            display: "flex", flexDirection: "row", marginBottom: "30px"
        }}>
            {filterIsOpen && <SummaryFilter setFilterIsOpen={setFilterIsOpen} />}
            <div style={{ display: "flex", cursor: "pointer" }} onClick={e => setFilterIsOpen(!filterIsOpen)}>
                <div>
                    Filters
                </div>
                <div >
                    {/* Add rotation based on `isOpen` state */}
                    <span className={`caret-icon ${filterIsOpen ? 'rotate-up' : 'rotate-down'}`}>
                        <CaretDownOutlined />
                    </span>
                </div>
            </div>
            <div style={isPhoneSize() ? {
                marginTop: "10px"
            } : {
                marginLeft: "10px"
            }}>
                Start Date:
            </div>

            <input
                type="date"
                value={startDate}
                onChange={e => setStartDate(e.target.value)}
                style={isPhoneSize() ? {
                    marginTop: "10px", width: "100px"
                } : {
                    marginLeft: "10px"
                }}
            />

            <div style={isPhoneSize() ? {
                marginTop: "10px"
            } : {
                marginLeft: "10px"
            }}>
                End Date:
            </div>

            <input
                type="date"
                value={endDate}
                onChange={e => setEndDate(e.target.value)}
                style={isPhoneSize() ? {
                    marginTop: "10px", width: "100px"
                } : {
                    marginLeft: "10px"
                }}
            />

            <button
                style={isPhoneSize() ? {
                    background: "rgb(17 32 86)", color: "white", borderRadius: "5px", marginTop: "10px", width: "100px"
                } : {
                    background: "rgb(17 32 86)", color: "white", borderRadius: "5px", marginLeft: "10px"
                }}
                onClick={() => getSummary()}
            >
                {"Get Summary"}
            </button>

            <button
                style={isPhoneSize() ? {
                    background: "rgb(17 32 86)", color: "white", borderRadius: "5px", marginTop: "10px", width: "100px"
                } : {
                    background: "rgb(17 32 86)", color: "white", borderRadius: "5px", marginLeft: "10px"
                }}
                onClick={() => getAllSummary()}
            >
                {"Get All"}
            </button>
        </div>

        {loading && <div style={{ marginTop: "30px", textAlign: "center" }}><Spin /></div>}
        {/* payout summary component */}
        <div style={isPhoneSize() ? {
            height: "calc(100vh - 30px)",
            overflowY: "scroll",
            scrollbarWidth: "none"
        } : {
            height: "calc(100vh - 70px)",
            overflowY: "scroll",
            scrollbarWidth: "none"
        }}>
            <div>
                {!loading && payoutsRaw.length > 0 &&
                    <div style={isPhoneSize() ? {
                        overflowX: "scroll",
                        overflow: "auto",
                        scrollbarWidth: "auto"
                    } : {
                        overflowX: "scroll",
                        overflow: "auto",
                        scrollbarWidth: "auto"
                    }}>
                        <div style={{
                            width: "1360px",
                            display: "flex",
                            flexDirection: "row",
                            fontSize: "15px",
                            fontWeight: "bold",
                            marginBottom: "10px"
                        }}>
                            <div style={{ width: "150px", textAlign: "center", alignContent: "center" }}>
                                Investor
                            </div>
                            <div style={{ width: "150px", textAlign: "center", alignContent: "center" }}>
                                Account
                            </div>
                            <div style={{ width: "150px", textAlign: "center", alignContent: "center" }}>
                                Fund
                            </div>
                            <div style={{ width: "100px", textAlign: "center", alignContent: "center" }}>
                                Frequency
                            </div>
                            <div style={{ width: "50px", textAlign: "center", alignContent: "center" }}>
                                Interest
                            </div>
                            <div style={{ width: "100px", textAlign: "center", alignContent: "center" }}>
                                Invested Amount
                            </div>
                            <div style={{ width: "100px", textAlign: "center", alignContent: "center" }}>
                                Account Balance
                            </div>
                            <div style={{ width: "100px", textAlign: "center", alignContent: "center" }}>
                                Amount
                            </div>
                            <div style={{ width: "100px", textAlign: "center", alignContent: "center" }}>
                                Type
                            </div>
                            <div style={{ width: "100px", textAlign: "center", alignContent: "center" }}>
                                Interest End Date
                            </div>
                            <div style={{ width: "100px", textAlign: "center", alignContent: "center" }}>
                                Payment Due Date
                            </div>
                            <div style={{ width: "80px", textAlign: "center", alignContent: "center" }}>
                                Status
                            </div>
                        </div>

                        <div style={{ fontSize: "14px" }}>
                            {Object.keys(payouts)
                                .sort((a, b) => {
                                    if (investorId2name[a] < investorId2name[b]) {
                                        return -1
                                    }
                                    return 1
                                })
                                .map((investorId, idx) => {
                                    // per investor
                                    return <div key={idx} style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        border: "1px solid black",
                                        marginBottom: "2px",
                                        width: "1360px"
                                    }}>

                                        <div style={{ width: "150px", alignContent: "center", textAlign: "center" }}>
                                            {investorId2name[investorId]}
                                            <br></br>
                                            <div style={{ fontSize: "10px" }}>
                                                {investorId2email[investorId]}
                                            </div>
                                        </div>

                                        <div style={{ display: "flex", flexDirection: "column", borderLeft: "1px solid black" }}>
                                            {Object.keys(payouts[investorId])
                                                .sort((a, b) => {
                                                    if (accountId2accountName[a] < accountId2accountName[b]) {
                                                        return -1
                                                    }
                                                    return 1
                                                })
                                                .map((accountId, idx) => {
                                                    // per account
                                                    return <div key={idx} style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        borderTop: idx === 0 ? "" : "2px solid black",
                                                        height: "100%"
                                                    }}>
                                                        <div style={{ width: "150px", alignContent: "center", textAlign: "center" }}>
                                                            {accountId2accountName[accountId]}
                                                        </div>

                                                        <div style={{ display: "flex", flexDirection: 'column' }}>
                                                            {payouts[investorId][accountId]
                                                                .sort((a, b) => {
                                                                    if (a.fund_name === b.fund_name) {
                                                                        if (a.timestamp > b.timestamp) {
                                                                            return -1
                                                                        }
                                                                        return 1
                                                                    }
                                                                    if (a.fund_name < b.fund_name) {
                                                                        return -1
                                                                    }
                                                                    return 1
                                                                })
                                                                .map((transaction, idx) => {
                                                                    // per transaction
                                                                    return <div key={idx}
                                                                        style={{
                                                                            borderLeft: "1px solid black",
                                                                            borderTop: idx === 0 ? "" : "1px solid black",
                                                                            display: "flex",
                                                                            flexDirection: "row",
                                                                            height: "100%"
                                                                        }}>

                                                                        <div style={{ width: "150px", textAlign: "center", alignContent: "center" }}>
                                                                            {transaction.fund_name}
                                                                        </div>

                                                                        <div style={{ borderLeft: "1px solid black", width: "100px", textAlign: "center", alignContent: "center" }}>
                                                                            {transaction.payout_frequency.replace(" payout", "").replace(" Payout", "")}
                                                                        </div>

                                                                        <div style={{ borderLeft: "1px solid black", width: "50px", textAlign: "center", alignContent: "center" }}>
                                                                            {transaction.interest + "%"}
                                                                        </div>

                                                                        <div style={{ borderLeft: "1px solid black", width: "100px", textAlign: "center", alignContent: "center" }}>
                                                                            {USDollar.format(transaction.invested_amount)}
                                                                        </div>

                                                                        <div style={{ borderLeft: "1px solid black", width: "100px", textAlign: "center", alignContent: "center" }}>
                                                                            {USDollar.format(transaction.account_balance)}
                                                                        </div>

                                                                        <div style={{
                                                                            width: "100px",
                                                                            textAlign: "center",
                                                                            borderLeft: "1px solid black",
                                                                            alignContent: 'center'
                                                                        }}>
                                                                            {USDollar.format(transaction.transaction_amount)}
                                                                        </div>
                                                                        <div style={{
                                                                            width: "100px",
                                                                            textAlign: "center",
                                                                            borderLeft: "1px solid black",
                                                                            alignContent: 'center'
                                                                        }}>
                                                                            {transaction.transaction_type[0].toUpperCase() + transaction.transaction_type.slice(1)}
                                                                        </div>
                                                                        <div style={{
                                                                            width: "100px",
                                                                            textAlign: "center",
                                                                            borderLeft: "1px solid black",
                                                                            alignContent: 'center'
                                                                        }}>
                                                                            {transaction.timestamp}
                                                                        </div>

                                                                        <div style={{
                                                                            width: "100px",
                                                                            textAlign: "center",
                                                                            borderLeft: "1px solid black",
                                                                            alignContent: 'center'
                                                                        }}>
                                                                            {getTomorrow(transaction.timestamp)}
                                                                        </div>

                                                                        <div style={{
                                                                            width: "80px",
                                                                            textAlign: "center",
                                                                            borderLeft: "1px solid black",
                                                                            alignContent: 'center'
                                                                        }}>
                                                                            {transactionId2Status[transaction.transaction_id][0].toUpperCase() + transactionId2Status[transaction.transaction_id].slice(1)}
                                                                        </div>

                                                                        <div style={{
                                                                            width: "80px",
                                                                            textAlign: "center",
                                                                            borderLeft: "1px solid black",
                                                                            alignContent: 'center'
                                                                        }}>

                                                                            {transactionId2Status[transaction.transaction_id].toLowerCase() !== "reinvest" &&
                                                                                new Date(transaction.timestamp + timezoneOffset) <= new Date() &&
                                                                                <button
                                                                                    style={{ background: "rgb(17 32 86)", color: "white", borderRadius: "5px" }}
                                                                                    onClick={() => handleStatusChange(transaction, investorId2email[investorId], investorId2name[investorId], transaction.transaction_type)}
                                                                                >
                                                                                    {transactionId2Status[transaction.transaction_id].toLowerCase() === "unpaid" ? "Pay" : "Unpay"}
                                                                                </button>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                })}
                                                        </div>
                                                    </div>
                                                })}
                                        </div>
                                    </div>
                                })}
                        </div>

                        <div style={{
                            width: "1360px",
                            display: "flex",
                            flexDirection: "row",
                            fontSize: "15px",
                            marginBottom: "10px",
                            border: "1px solid black"
                        }}>
                            <div style={{ width: "150px", textAlign: "center", alignContent: "center" }}>
                                Summary
                            </div>
                            <div style={{ width: "150px", textAlign: "center", alignContent: "center" }}>

                            </div>
                            <div style={{ width: "150px", textAlign: "center", alignContent: "center" }}>

                            </div>
                            <div style={{ width: "100px", textAlign: "center", alignContent: "center" }}>

                            </div>
                            <div style={{ width: "50px", textAlign: "center", alignContent: "center" }}>

                            </div>
                            <div style={{ width: "100px", textAlign: "center", alignContent: "center" }}>

                            </div>
                            <div style={{ width: "100px", textAlign: "center", alignContent: "center" }}>

                            </div>
                            <div style={{ width: "100px", textAlign: "center", alignContent: "center" }}>
                                {USDollar.format(totalInterestAmount)}
                            </div>
                            <div style={{ width: "100px", textAlign: "center", alignContent: "center" }}>

                            </div>
                            <div style={{ width: "100px", textAlign: "center", alignContent: "center" }}>

                            </div>
                            <div style={{ width: "100px", textAlign: "center", alignContent: "center" }}>

                            </div>
                            <div style={{ width: "80px", textAlign: "center", alignContent: "center" }}>

                            </div>
                        </div>

                    </div>
                }
                {!loading && <div>{uiMessage}</div>}
            </div>
        </div>
    </div>
    return <>
        {!isAdmin() && <>Not authorized</>}
        {isAdmin() && content}
    </>
}