import { useEffect, useState } from "react"
import { Col, Divider, Layout, Row, Spin, Table, message, Typography, Collapse, Tooltip, Select } from 'antd';
import { CompassOutlined } from '@ant-design/icons';
import api from 'api';
import { get, post } from 'utils/fetch';
import backArrow from "../../assets/img/backArrow.png"
import PdfIcon from "../../assets/img/PdfIcon.png"
import { FundPhotoGallery } from "components/FundPhotoGallery";
import { OfferingDetailedTab } from "components/OfferingDetailedTab";
import Map from "components/Map";
import { isPhoneSize } from "utils/util";
import { CompanyInfo } from "components/CompanyInfo";
import { propertyToAddress } from "utils/util";

export function OfferingDetailed(props) {
    const { email, id, isHolding } = props
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(true);
    const [fund, setFund] = useState(null);
    const [photos, setPhotos] = useState([]);
    const [pdfs, setPdfs] = useState([]);
    const [properties, setProperties] = useState([]);
    const [curProperty, setCurProperties] = useState({})
    const [position, setPosition] = useState([51.505, -0.09]);
    const [address, setAddress] = useState("")

    const displayErrorMessage = (message) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };

    async function fetchFund(id) {
        await get(api.fund.getBasicInfo(id), setFund, (errors) => errors.forEach(err => {
            displayErrorMessage(err.message);
        }));
    };

    async function fetchPhotos(id) {
        await get(api.file.getPhotos(id), setPhotos, (errors) => errors.forEach(err => {
            displayErrorMessage(err.message);
        }));
    };

    async function fetchPdfs(id) {
        await get(api.file.getPdfs(id), setPdfs, (errors) => errors.forEach(err => {
            displayErrorMessage(err.message);
        }));
    };

    async function fetchProperties(id) {
        await get(api.fund.getFundProperty(id), setProperties, (errors) => errors.forEach(err => {
            displayErrorMessage(err.message);
        }));
    };

    async function fetchData(id) {
        setLoading(true);
        await fetchFund(id);
        await fetchPhotos(id);
        await fetchPdfs(id);
        await fetchProperties(id);
        setLoading(false);
    }

    //console.log("properties", properties)

    useEffect(() => {
        if (properties.length == 0) return
        //console.log("cur property", properties[0])
        setPosition([properties[0].latitude, properties[0].longitude])
        setAddress(`${properties[0].address}, ${properties[0].city}, ${properties[0].state} ${properties[0].zip}`)
    }, [properties])

    useEffect(() => {
        fetchData(id);
    }, [id])

    function getTypeBackgroundColor(type) {
        if (type.toLowerCase() === "debt") {
            return "blue"
        } else if (type.toLowerCase() === "equity") {
            return "green"
        }
        return "darkcyan"
    }

    function getTypeTagWidth(type) {
        if (type.toLowerCase() === "debt" || type.toLowerCase() === "equity") {
            return "50px"
        }
        return "80px"
    }

    //console.log("fund,", fund)
    return <div style={isPhoneSize() ? {
        width: window.innerWidth - 20,
        position: "fixed"
    } : {
        width: "100%"
    }}>
        {loading && <div style={{ textAlign: "center", height: "100vh", marginTop: "30px" }}><Spin /></div>}
        {!loading && fund && <div style={isPhoneSize() ? {} : { marginTop: "20px" }}>
            <div>
                <div>
                    {/* tabs */}
                    <div style={isPhoneSize() ? {
                        display: "flex"
                    } : {
                        paddingLeft: "30px",
                        display: 'flex',
                        justifyContent: "space-around",
                        alignItems: "center",
                        // position: "sticky",
                        // top: "25px",
                        height: "20px",
                        background: "transparent"
                    }}>
                        <OfferingDetailedTab text={"Overview"} />
                        <OfferingDetailedTab text={"Video"} />
                        <OfferingDetailedTab text={"Photos"} />
                        <OfferingDetailedTab text={"Locations"} />
                        <OfferingDetailedTab text={"Documents"} />
                    </div>
                    {/* back arrow, topic, fund tag */}
                    <div style={{
                        // position: "sticky",
                        // top: "45px",
                        background: "rgb(245 245 245)",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "20px",
                        background: "center"
                    }}>
                        <div>
                            <a href={isHolding ? "holdings" : "offerings"}>
                                <img style={{ width: "15px", height: "20px", cursor: "pointer" }} src={backArrow}></img>
                            </a>
                        </div>
                        <div style={{ textAlign: "center", fontSize: "30px", fontWeight: "bold", display: "flex", flexDirection: "column" }}>
                            <div>
                                {fund.name}
                            </div>


                        </div>

                        <div>
                            {fund.type && <div style={{
                                fontSize: "13px",
                                background: getTypeBackgroundColor(fund.type),
                                color: "white",
                                display: "flex",
                                borderRadius: "10px",
                                width: getTypeTagWidth(fund.type),
                                justifyContent: "center",
                                fontWeight: 'bold'
                            }}>
                                {fund.type}
                            </div>
                            }

                            {isPhoneSize() &&
                                <a target="_blank" rel="noopener noreferrer" href='https://calendly.com/alphax-investment/30min' style={{ color: "red" }}>
                                    <button style={{
                                        background: "rgb(17 32 86)",
                                        color: "white",
                                        width: "50px",
                                        fontSize: "12px",
                                        borderRadius: "15px",
                                        cursor: "pointer",
                                        fontWeight: 'bold',
                                        padding: "0"
                                    }}>
                                        Invest
                                    </button>
                                </a>}
                        </div>

                    </div>
                </div>
                <div style={isPhoneSize() ? {
                    overflowY: "scroll", height: "calc(100vh - 364px)", scrollbarWidth: "none"
                } : {
                    overflowY: "scroll", height: "calc(100vh - 264px)", scrollbarWidth: "none"
                }}>
                    {/* top image */}
                    <div>
                        {fund.img_src && fund.img_src.length > 0 &&
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <img style={isPhoneSize() ? {
                                    width: window.innerWidth - 20
                                } : {
                                    width: "560px"
                                }} src={fund.img_src}></img>
                            </div>
                        }

                        {/* Overview */}
                        <h1 id="Overview">
                            Overview
                        </h1>
                        {fund.overview && fund.overview.length > 0 &&
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <div style={{ width: "560px", fontSize: "16px" }}>
                                    {fund.overview.split('\n')
                                        .map(e => <p>{e}</p>)}
                                </div>
                            </div>
                        }

                        {/* Video */}
                        <h1 id="Video">
                            Video
                        </h1>
                        {fund.video_src && fund.video_src.length > 0 &&
                            <div style={{ textAlign: "center" }}>
                                <iframe
                                    width={isPhoneSize() ? window.innerWidth - 20 : "560"}
                                    height={isPhoneSize() ? (window.innerWidth - 20) / 560 * 315 : "315"}
                                    src="https://www.youtube.com/embed/MdGcS0tzAzc?si=PpTwOAEi1KEMXQPU"
                                    title="YouTube video player"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen; web-share"
                                    referrerPolicy="strict-origin-when-cross-origin"
                                // allowfullscreen
                                >

                                </iframe>
                            </div>
                        }

                        {/* Photos Gallery */}

                        <h1 id="Photos">
                            {photos.length > 1 ? "Photos" : "Photo"}
                        </h1>
                        {photos.length > 0 &&
                            <>
                                <FundPhotoGallery photos={photos} />
                            </>
                        }
                        <h1>Progress Photos</h1>
                        <h1 id="Documents">
                            {pdfs.length > 1 ? "Documents" : "Document"}
                        </h1>

                        {pdfs.length > 0 &&
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <div style={{ width: "560px" }}>
                                    {pdfs.map((pdf, id) => {
                                        return <a href={pdf.url} key={id} target="_blank" rel="noreferrer noopener">
                                            <div key={id} style={{ display: "flex", alignItems: "center", fontSize: "15px", color: "black" }}>
                                                <div style={{ padding: "5px", paddingBottom: "0" }}>
                                                    <img src={PdfIcon} style={{ width: "20px" }}></img>
                                                </div>
                                                {pdf.url.substring(pdf.url.lastIndexOf('/') + 1)}
                                            </div>
                                        </a>
                                    })}
                                </div>
                            </div>
                        }

                        <h1 id="Locations">
                            {properties.length > 1 ? "Locations" : "Location"}
                        </h1>
                        {address.length > 0 &&
                            <div style={{ display: "flex", width: "100%", alignItems: "center", flexDirection: "column", paddingBottom: isPhoneSize() ? "50px" : "" }}>
                                <div style={isPhoneSize() ? {
                                    width: window.innerWidth - 80, height: window.innerWidth - 80, display: "flex"
                                } : {
                                    width: "560px", height: "560px", display: "flex"
                                }}
                                >
                                    <Map position={position}
                                        setPosition={setPosition}
                                        properties={properties}
                                        setAddress={setAddress}
                                    />
                                </div>

                                {properties.map((property, idx) => (
                                    <div key={idx} style={{ display: "flex", flexDirection: "row", alignItems: "center", height: "30px" }}>

                                        {/* Address literal */}
                                        <div style={propertyToAddress(property) === address ? {
                                            fontSize: "20px", cursor: "pointer", fontWeight: "bold", title: "hi"
                                        } : {
                                            fontSize: "20px", cursor: "pointer", title: "hi"
                                        }}
                                            onClick={e => {
                                                setPosition([property.latitude, property.longitude])
                                                setAddress(propertyToAddress(property))
                                            }}
                                        >
                                            {propertyToAddress(property)}
                                        </div>

                                        {/* Google map sign */}
                                        <a href={`http://maps.google.com/maps?q=${encodeURIComponent(propertyToAddress(property))}`} target='_blank'>
                                            <CompassOutlined style={{ fontSize: "20px", margin: "0 10px", cursor: "pointer", title: "open google map" }} />
                                        </a>
                                    </div>
                                ))}

                                {/* <a href={`http://maps.google.com/maps?q=${encodeURIComponent(address)}`} target='_blank'>
                                    <div style={{ color: "black" }}>{address}</div>
                                </a> */}
                            </div>
                        }

                        <div style={isPhoneSize() ? {
                            height: "250px", display: "flex"
                        } : {
                            height: "50px", display: "flex"
                        }}></div>
                    </div>
                </div>
            </div>
        </div>
        }
    </div >
}