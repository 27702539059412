import api from "api";
import { get } from "utils/fetch";
import { isPhoneSize } from "utils/util";
import { GlobalContext } from "GlobalContext";
import { useEffect, useState, useContext, useRef } from "react"
import { Select, Space, message, Spin, Switch } from "antd"

export function Video() {
    const { manageRecordId, accessToken } = useContext(GlobalContext)
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(true)
    const [videoSrc, setVideoSrc] = useState("")
    const [uploading, setUploading] = useState(false);

    const displayErrorMessage = (message) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };

    async function fetchVideoSrc(id) {
        await get(api.offering.getVideoSrc(id), setVideoSrc, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            }), accessToken
        );
        setLoading(false);
    }

    useEffect(() => {
        // if (accessToken === "") return;
        fetchVideoSrc(manageRecordId);
    }, [accessToken]);

    const handleFileChange = async (event) => {
        const file = event.target.files[0]; // Only one file allowed
        if (!file) return;

        setLoading(true);

        const formData = new FormData();
        formData.append("fundId", manageRecordId);
        formData.append("file", file);  // Append video file
        formData.append("videoSrc", videoSrc ? videoSrc : "");

        try {
            const response = await fetch(api.offering.uploadVideo(manageRecordId), {
                method: "POST",
                body: formData,
                headers: {
                    "Authorization": `Bearer ${accessToken}` // Ensure authentication
                }
            });

            if (!response.ok) throw new Error("Upload failed");

            const data = await response.json();
            setVideoSrc(data.newVideoUrl); // Update new video URL from response
            message.success("Video uploaded successfully!");
        } catch (error) {
            displayErrorMessage("Failed to upload video.");
        }

        setLoading(false);
    };

    return <div style={{ justifyItems: "center" }}>
        <div style={isPhoneSize() ? {
            width: window.innerWidth - 20,
        } : {
            width: "560px",
        }}>
            <h1>
                Video
            </h1>
            {loading && <div style={{ marginTop: "30px", textAlign: "center" }}><Spin /></div>}

            {!loading && <>
                {videoSrc && videoSrc.includes("www.youtube.com") > 0 &&
                    <div style={{ textAlign: "center" }}>
                        <iframe
                            width={isPhoneSize() ? window.innerWidth - 20 : "100%"}
                            height={isPhoneSize() ? (window.innerWidth - 20) / 560 * 315 : "315"}
                            src={videoSrc}
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen; web-share"
                            referrerPolicy="strict-origin-when-cross-origin"
                        // allowfullscreen
                        >

                        </iframe>
                    </div>
                }
                {videoSrc && videoSrc.includes("alphax-dashboard-demo.s3") && (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <video
                            style={isPhoneSize() ? { width: window.innerWidth - 20 } : { maxWidth: "560px" }}
                            controls
                        >
                            <source src={videoSrc} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                )}

                <input
                    type="file"
                    accept="video/mp4,video/x-m4v,video/*"
                    onChange={handleFileChange}
                    disabled={uploading}
                    style={{ display: "none" }}
                    id="upload-input-video"
                />

                <div>
                    <label htmlFor="upload-input-video">
                        <button
                            style={{
                                height: "30px",
                                width: "100px",
                                borderRadius: "30px",
                                background: "rgb(17 32 86)",
                                color: "white",
                                cursor: "pointer",
                                marginTop: "20px"
                            }}
                            onClick={() => document.getElementById("upload-input-video").click()}
                        >
                            {videoSrc ? "Update Video" : "Upload Video"}
                        </button>
                    </label>
                </div>
            </>}
        </div>
    </div>
}