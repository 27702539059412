import { useEffect, useState, useContext } from "react"
import backArrow from "../../assets/img/backArrow.png"
import api from "api"
import { get } from "utils/fetch"
import { Select, Space, Spin, message } from "antd"
import { DeleteConfirmation } from "components/DeleteConfirmation"
import { propertyToAddress } from "utils/util"
import { isPhoneSize } from "utils/util"
import { Email } from "pages/Email/Email"
import { EmailResultBox } from "pages/Email/EmailResultBox"
import { GlobalContext } from "GlobalContext";
import { USDollar } from "utils/format"

export function InvestorInsertion(props) {
    // const { investor, accounts } = props
    const [messageApi, contextHolder] = message.useMessage();
    const { setManageRecordId, manageRecordId, setManageRecordTab } = useContext(GlobalContext)
    const [dirty, setDirty] = useState(false)
    const [saving, setSaving] = useState(false)
    const [accountsHeld, setAccountsHeld] = useState([])
    const [creationErrorMessages, setCreationErrorMessages] = useState([])
    const [loading, setLoading] = useState(true)
    const [accountIdSelected, setAccountIdSelected] = useState([])
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [address, setAddress] = useState("")
    const [ssn, setSsn] = useState("")
    const [showDeletionBox, setShowDeletionBox] = useState(false)
    const [fundsByInvestor, setFundsByInvestor] = useState([])
    const [companiesByInvestor, setCompaniesByInvestor] = useState([])
    const [propertiesByInvestor, setPropertiesByInvestor] = useState([])
    const [view, setView] = useState("investor")
    const [emailResponse, setEmailResponse] = useState("")
    const [investor, setInvestor] = useState(null)
    const [accounts, setAccounts] = useState([])
    // accounts to be displayed in dropdown menu
    const [accountsDisplay, setAccountsDisplay] = useState([])
    const [positionsByInvestor, setPositionsByInvestor] = useState([])
    // account id -> account name
    const [accountIdToName, setAccountIdToName] = useState({})
    // account id -> [position]
    const [groupByAccount, setGroupByAccount] = useState({})

    const displayErrorMessage = (message) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };

    var account2id = {}
    for (const account of accounts) {
        account2id[account.account_name] = account.id
    }

    const entryStyle = isPhoneSize() ? {
        margin: "20px",
        fontSize: '20px'
    } : {
        margin: "20px",
        fontSize: '20px',
        // display: "flex"
    }

    const inputStyle = {
        border: "0",
        outline: "none",
        borderBottom: "1px solid black",
        fontSize: '20px',
        background: "transparent"
    }

    function canSubmit() {
        var invalidFields = []
        if (firstName.trim().length === 0) {
            invalidFields.push("Please fill in first name.")
        }
        if (lastName.trim().length === 0) {
            invalidFields.push("Please fill in last name.")
        }
        if (email.trim().length === 0) {
            invalidFields.push("Please fill in phone.")
        }
        if (phone.trim().length === 0) {
            invalidFields.push("Please fill in phone.")
        }

        setCreationErrorMessages(invalidFields)
        return invalidFields.length === 0;
    }

    async function fetchInvestmentsByInvestor(id) {
        await get(api.manager.fundsByInvestor(id), setFundsByInvestor, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            })
        )
    }

    async function fetchCompaniesByInvestor(id) {
        await get(api.manager.companiesByInvestor(id), setCompaniesByInvestor, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            })
        )
    }

    async function fetchPropertiesByInvestor(id) {
        await get(api.manager.propertiesByInvestor(id), setPropertiesByInvestor, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            })
        )
    }

    async function fetchAccounts() {
        await get(api.manager.accounts, setAccounts, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            })
        )
    }

    async function fetchInvestor(id) {
        await get(api.manager.investor(id), setInvestor, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            })
        )
    }

    async function fetchPositionsByInvestor(id) {
        await get(api.manager.positionsByInvestor(id), setPositionsByInvestor, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            })
        )
    }

    async function fetchAccountsByInvestor(id) {
        await get(api.manager.accountsByInvestor(id), setAccountsHeld, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            })
        )
        setLoading(false)
    }

    useEffect(() => {
        setAccountIdSelected(accountsHeld.map(account => account.id))
        setAccountsDisplay(accountsHeld.map(account => account.account_name))
    }, [accountsHeld])

    useEffect(() => {
        // get account id -> account name map,
        // group investment by account
        var accountIdToName = {}
        for (const position of positionsByInvestor) {
            accountIdToName[position.account_id] = position.account_name
        }
        setAccountIdToName(accountIdToName)

        var groupByAccount = {}
        for (const position of positionsByInvestor) {
            if (!groupByAccount[position.account_id]) {
                groupByAccount[position.account_id] = []
            }
            groupByAccount[position.account_id].push(position)
        }
        setGroupByAccount(groupByAccount)
    }, [positionsByInvestor])

    useEffect(() => {
        //console.log("Investor Insertion Rendered.")
        if (manageRecordId <= 0) {
            setLoading(false)
            return
        }
        if (investor === null) {
            return
        }
        setFirstName(investor === null || investor.first_name === null ? "" : investor.first_name)
        setLastName(investor === null || investor.last_name === null ? "" : investor.last_name)
        setEmail(investor === null || investor.email === null ? "" : investor.email)
        setPhone(investor === null || investor.phone === null ? "" : investor.phone)
        setAddress(investor === null || investor.address === null ? "" : investor.address)
        setSsn(investor === null || investor.tax_id_ssn === null ? "" : investor.tax_id_ssn)
        fetchInvestmentsByInvestor(investor.id)
        fetchCompaniesByInvestor(investor.id)
        fetchPropertiesByInvestor(investor.id)
        fetchPositionsByInvestor(investor.id)
        fetchAccountsByInvestor(investor.id)
    }, [investor])

    // console.log("positionsByInvestor", positionsByInvestor)

    useEffect(() => {
        fetchAccounts()
        if (manageRecordId > 0) {
            fetchInvestor(manageRecordId)
        }
    }, [])
    // console.log(manageRecordId)
    // console.log(accountsHeld.map(account => account.account_name))

    async function saveInvestor() {
        //console.log(canSubmit())
        if (canSubmit() === false) {
            return
        }
        //console.log("idDirty", dirty)
        if (dirty === false) {
            setManageRecordId(0)
            return
        }
        setSaving(true)
        setFirstName(firstName.trim())
        setLastName(lastName.trim())
        setEmail(email.trim())
        setPhone(phone.trim())
        setSsn(ssn.trim())
        setAddress(address.trim())
        // account id held
        const accountIdsHeld = accounts.length === 0 ? [] : accountsHeld.map(account => account.id)
        // newly added account idx (selected but not previously held)
        const addedAccountIds = accountIdSelected.length === 0 ? [] : accountIdSelected.filter(id => !accountIdsHeld.includes(id));
        // removed property idx (previously invested but not selected)
        const removedAccountIds = accountIdsHeld.length === 0 ? [] : accountIdsHeld.filter(id => !accountIdSelected.includes(id))
        // console.log(accountIdsHeld, addedAccountIds, removedAccountIds)
        const option = {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            headers: {
                "Content-Type": "application/json",
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: JSON.stringify({
                firstName,
                lastName,
                email,
                phone,
                addedAccountIds,
                removedAccountIds,
                ssn,
                address
            }),
        }
        if (investor === null) {
            await fetch(api.manager.investors, option);
        } else {
            await fetch(api.manager.investor(investor.id), option);
        }
        setManageRecordId(0)
    }
    // console.log(accountsHeld)
    const handleChange = (accounts) => {
        setDirty(true)
        setAccountIdSelected(accounts.map(account => account2id[account]))
        setAccountsDisplay(accounts)
    };
    const selectionOptions = accounts
        .map(account => {
            return {
                label: account.account_name,
                value: account.account_name,
                desc: account.account_name
            }
        })

    function deleteInvestor() {
        if (accountsHeld.length > 0) {
            setCreationErrorMessages([
                ...accountsHeld
                    .map(account => {
                        return `Current investor is holding ${account.account_name}.`
                    })
            ])
            return
        }
        setShowDeletionBox(true)
    }

    // called by DeleteConfirmation component to delete current record
    async function deletionCallback() {
        const options = {
            method: "DELETE", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            headers: {
                "Content-Type": "application/json"
            },
            // body
        }
        const result = await fetch(api.manager.investor(investor.id), options);
        const data = await result.json()
        setManageRecordId(0)
    }

    function getEmailResBGColor() {
        if (emailResponse === "Email sent successfully") {
            return "#007BFF"
        } else if (emailResponse === "Sending...") {
            return "#4A90E2"
        }
        return "#FF4C4C"
    }

    function getEmailBoxShadow() {
        if (emailResponse === "Email sent successfully") {
            return "0 4px 8px rgba(0, 123, 255, 0.3)"
        } else if (emailResponse === "Sending...") {
            return "0 4px 8px #3E7BB1"
        }
        return "0 4px 8px rgba(255, 76, 76, 0.3)"
    }
    // console.log(accountsHeld.map(account => account.account_name))
    return <>
        {loading && <div style={{ marginTop: "30px", textAlign: "center" }}><Spin /></div>}

        {!loading && view === "email" && <Email
            setView={setView}
            emailPassed={[investor.email]}
            fromView="investor"
            setEmailResponse={setEmailResponse}
        />}

        {!loading && view === "investor" && <div>

            {
                // emailResponse.length > 0 && <div style={{
                //     position: "fixed",       /* Fix the div in place */
                //     top: "100px",           /* 100px from the top */
                //     right: "0",             /* Align to the right */
                //     width: "200px",         /* Optional width */
                //     backgroundColor: getEmailResBGColor(), /* Success Blue */
                //     color: "white",              /* White text for contrast */
                //     padding: "10px",
                //     borderRadius: "5px",
                //     boxShadow: getEmailBoxShadow(), /* Shadow for success */
                //     transition: "opacity 1s ease, transform 1s ease" /* Transition for smoothness */
                // }}>
                //     {emailResponse}
                // </div>
            }

            <EmailResultBox emailResponse={emailResponse} />

            {investor !== null && showDeletionBox &&
                <DeleteConfirmation
                    title={`Investor: ${investor.legal_name}`}
                    setShowDeletionBox={setShowDeletionBox}
                    deletionCallback={deletionCallback}
                />
            }

            <div style={{ margin: "20px", display: "flex", justifyContent: "space-between" }}>
                <img style={{ width: "15px", height: "20px", cursor: "pointer" }}
                    src={backArrow}
                    onClick={() => setManageRecordId(0)}
                ></img>

                {/* {investor !== null && <button style={{
                    height: "30px",
                    width: "70px",
                    borderRadius: "30px",
                    background: "rgb(17 32 86)",
                    color: "white",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    placeContent: "center"
                }}
                    onClick={e => setView("email")}
                >
                    Email
                </button>} */}
            </div>

            {creationErrorMessages.map((message, idx) => {
                return <div key={idx} style={{ color: "red", marginLeft: "20px" }}>{message}</div>
            })}
            <div style={isPhoneSize() ? entryStyle : { ...entryStyle, display: "flex" }}>
                <div>First Name<span style={{ color: "red" }}>*</span>:</div> <input style={inputStyle}
                    value={firstName}
                    onInput={(e) => {
                        setFirstName(e.target.value)
                        setDirty(true)
                    }}></input>
            </div>

            <div style={isPhoneSize() ? entryStyle : { ...entryStyle, display: "flex" }}>
                <div>Last Name<span style={{ color: "red" }}>*</span>:</div> <input style={inputStyle}
                    value={lastName}
                    onInput={(e) => {
                        setLastName(e.target.value)
                        setDirty(true)
                    }}></input>
            </div>

            <div style={isPhoneSize() ? entryStyle : { ...entryStyle, display: "flex" }}>
                <div>Email<span style={{ color: "red" }}>*</span>:</div> <input style={inputStyle}
                    value={email}
                    onInput={(e) => {
                        setEmail(e.target.value)
                        setDirty(true)
                    }}></input>
            </div>

            <div style={isPhoneSize() ? entryStyle : { ...entryStyle, display: "flex" }}>
                <div>Phone Number<span style={{ color: "red" }}>*</span>:</div> <input style={inputStyle}
                    value={phone}
                    onInput={(e) => {
                        setPhone(e.target.value)
                        setDirty(true)
                    }}></input>
            </div>

            <div style={isPhoneSize() ? entryStyle : { ...entryStyle, display: "flex" }}>
                <div>SSN:</div> <input style={inputStyle}
                    value={ssn}
                    onInput={(e) => {
                        setSsn(e.target.value)
                        setDirty(true)
                    }}></input>
            </div>

            <div style={isPhoneSize() ? entryStyle : { ...entryStyle, display: "flex" }}>
                <div>Address:</div> <input style={inputStyle}
                    value={address}
                    onInput={(e) => {
                        setAddress(e.target.value)
                        setDirty(true)
                    }}></input>
            </div>

            <div style={entryStyle}>
                <div style={isPhoneSize() ? {
                    display: "flex", flexDirection: "column"
                } : {
                    display: "flex", flexDirection: "row"
                }}>
                    <div>Accounts: </div>
                    <span>
                        <Select
                            mode="multiple"
                            style={isPhoneSize() ? {
                                width: '300px'
                            } : {
                                width: '300px', marginLeft: "5px"
                            }}
                            placeholder="select accounts"
                            value={accountsDisplay}
                            onChange={handleChange}
                            options={selectionOptions}
                            optionRender={(option) => (
                                <Space>
                                    <span role="img" aria-label={option.data.label}>
                                        {option.data.emoji}
                                    </span>
                                    {option.data.desc}
                                </Space>
                            )}
                        />
                    </span>
                </div>
            </div>

            <div style={entryStyle}>
                Positions:
                {/* head */}
                <div style={{
                    overflowX: "scroll",
                    overflow: "auto",
                    scrollbarWidth: "auto"
                }}>
                    <div style={{ width: "1200px" }}>
                        <div style={{ display: "flex", fontSize: "17px" }}>
                            <div style={{ width: "100px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                Account
                            </div>

                            <div style={{ width: "270px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                Fund
                            </div>

                            <div style={{ width: "180px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                Commitment Number
                            </div>

                            <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                Equity Balance
                            </div>

                            <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                Capital in Date
                            </div>

                            <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                Distribute Interest
                            </div>

                            <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                Principal Return
                            </div>
                        </div>
                        {Object.keys(groupByAccount).map((id) => <div key={id} style={{ display: "flex", flexDirection: "row" }}>
                            <div style={{
                                width: "100px",
                                alignContent: 'center',
                                textAlign: "center",
                                border: "1px solid black",
                                marginBottom: "2px",
                                // color: "blue",
                                // textDecoration: "underline",
                                // cursor: "pointer"
                            }}
                            // onClick={() => {
                            //     setManageRecordId(groupByAccount[id][0].account_id)
                            //     setManageRecordTab("Accounts")
                            // }}
                            >
                                {accountIdToName[id]}
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", marginBottom: "2px" }}>
                                {groupByAccount[id].map((position, id) => <div key={id}
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        border: "1px solid black",
                                        borderLeft: "0px",
                                        borderTop: id > 0 ? "0px" : "1px solid black"
                                    }}>
                                    <div style={{
                                        width: "270px",
                                        display: "flex",
                                        placeContent: "center",
                                        alignItems: "center",
                                        textAlign: "center",
                                        flexDirection: 'column',
                                        // color: "blue",
                                        // textDecoration: "underline",
                                        // cursor: "pointer"
                                    }}
                                    // onClick={() => {
                                    //     setManageRecordId(position.fund_id)
                                    //     setManageRecordTab("Entities")
                                    // }}
                                    >
                                        {position.fund_name}
                                    </div>

                                    <div style={{ width: "180px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                        {USDollar.format(position.amount)}
                                    </div>

                                    <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                        {USDollar.format(position.account_balance)}
                                    </div>

                                    <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                        {position.funding_receipt_date.slice(0, 10)}
                                    </div>

                                    <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                        {USDollar.format(position.interest_received)}
                                    </div>

                                    <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                        {USDollar.format(position.principal_returned)}
                                    </div>

                                    <div style={{ width: "50px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                        <button
                                            style={{ background: "rgb(17 32 86)", color: "white", height: "30px", borderRadius: "10px", cursor: "pointer" }}
                                            onClick={() => {
                                                setManageRecordId(position.investment_id)
                                                setManageRecordTab("Positions")
                                            }}>
                                            Detail
                                        </button>
                                    </div>
                                </div>)}
                            </div>
                        </div>)}
                        <div style={{ display: "flex", border: "1px solid black" }}>
                            <div style={{ width: "370px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                Summary
                            </div>

                            <div style={{ width: "180px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                {USDollar.format(
                                    positionsByInvestor.reduce((acc, cur) => {
                                        return acc + cur.amount
                                    }, 0)
                                )}
                            </div>

                            <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                {USDollar.format(
                                    positionsByInvestor.reduce((acc, cur) => {
                                        return acc + cur.account_balance
                                    }, 0)
                                )}
                            </div>

                            <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>

                            </div>

                            <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                {USDollar.format(
                                    positionsByInvestor.reduce((acc, cur) => {
                                        return acc + cur.interest_received
                                    }, 0)
                                )}
                            </div>

                            <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                {USDollar.format(
                                    positionsByInvestor.reduce((acc, cur) => {
                                        return acc + cur.principal_returned
                                    }, 0)
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div style={entryStyle}>
                Accounts:
                {accountsHeld
                    .sort((a, b) => {
                        if (a.account_name < b.account_name) {
                            return -1
                        }
                        return 1
                    })
                    .map((account, idx) => {
                        return <div key={idx} style={{ display: "flex", flexDirection: "row" }}>
                            <div style={{
                                // fontSize: "20px",
                                // margin: "20px",
                                width: "fit-content",
                                color: "blue",
                                textDecoration: "underline",
                                cursor: "pointer"
                            }}
                                onClick={() => {
                                    setManageRecordId(account.id)
                                    setManageRecordTab("Accounts")
                                }}
                            >
                                {account.account_name}
                            </div>
                        </div>
                    })}
            </div>

            <div style={entryStyle}>
                Entities:
                {fundsByInvestor
                    .sort((a, b) => {
                        if (a.name < b.name) {
                            return -1
                        }
                        return 1
                    })
                    .map((fund, idx) => {
                        return <div key={idx} style={{ display: "flex", flexDirection: "row" }}>
                            <div style={{
                                // fontSize: "20px",
                                // margin: "20px",
                                width: "fit-content",
                                color: "blue",
                                textDecoration: "underline",
                                cursor: "pointer"
                            }}
                                onClick={() => {
                                    setManageRecordId(fund.id)
                                    setManageRecordTab("Entities/Funds")
                                }}>
                                {fund.name}
                            </div>
                        </div>
                    })}
            </div>

            {/* <div style={entryStyle}>
                Holding Companies:
                {companiesByInvestor
                    .sort((a, b) => {
                        if (a.name < b.name) {
                            return -1
                        }
                        return 1
                    })
                    .map((company, idx) => {
                        return <div key={idx} style={{ display: "flex", flexDirection: "row" }}>
                            <div style={{
                                // fontSize: "20px",
                                // margin: "20px",
                                width: "fit-content",
                                color: "blue",
                                textDecoration: "underline",
                                cursor: "pointer"
                            }}
                                onClick={() => {
                                    setManageRecordId(company.id)
                                    setManageRecordTab("Companies")
                                }}>
                                {company.name}
                            </div>
                        </div>
                    })}
            </div> */}

            {/* <div style={entryStyle}>
                Assets:
                {propertiesByInvestor
                    .sort((a, b) => {
                        if (propertyToAddress(a) < propertyToAddress(b)) {
                            return -1
                        }
                        return 1
                    })
                    .map((property, idx) => {
                        return <div key={idx} style={{ display: "flex", flexDirection: "row" }}>
                            <div style={{
                                // fontSize: "20px",
                                // margin: "20px",
                                width: "fit-content",
                                color: "blue",
                                textDecoration: "underline",
                                cursor: "pointer"
                            }}
                                onClick={() => {
                                    setManageRecordId(property.id)
                                    setManageRecordTab("Assets")
                                }}>
                                {propertyToAddress(property)}
                            </div>
                        </div>
                    })}
            </div> */}

            {!saving && <div style={{ margin: "20px", display: "flex", justifyContent: "space-between" }}>
                <button style={{
                    height: "30px",
                    width: "50px",
                    borderRadius: "30px",
                    background: "rgb(17 32 86)",
                    color: "white",
                    cursor: "pointer",
                }}
                    onClick={saveInvestor}
                >
                    Save
                </button>
                {investor !== null && <button style={{
                    height: "30px",
                    width: "70px",
                    borderRadius: "30px",
                    background: "red",
                    color: "white",
                    cursor: "pointer",
                }}
                    onClick={deleteInvestor}
                >
                    Delete
                </button>}
            </div>
            }
            {saving && <div style={{ margin: "20px" }}>
                <button style={{
                    height: "30px",
                    width: "70px",
                    borderRadius: "30px",
                    background: "rgb(17 32 86)",
                    color: "white",
                    cursor: "pointer",
                }}
                >
                    Saving
                </button>
            </div>}
        </div >}
    </>
}