import { CloseOutlined } from '@ant-design/icons';
import { Spin, message } from "antd";
import api from 'api';
import { get } from 'utils/fetch';
import { useEffect, useState, useContext } from "react"
import { GlobalContext } from 'GlobalContext';
import { isPhoneSize } from 'utils/util';

export function AccountDetail(props) {
    const { accountId, setAccountId } = props
    const { accessToken } = useContext(GlobalContext)
    const [messageApi, contextHolder] = message.useMessage();
    const [account, setAccount] = useState({})
    const [loading, setLoading] = useState(false)

    const displayErrorMessage = (message) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };

    async function fetchAccount(id) {
        await get(api.manager.account(id), setAccount, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            }), accessToken
        )
        setLoading(false)
    }
    console.log(accountId, account)
    useEffect(() => {
        if (accountId < 0) return
        setLoading(true)
        fetchAccount(accountId)
    }, [accountId])

    const entryStyle = {
        fontSize: "17px",
        margin: "10px 20px"
    }

    const keyStyle = {
        fontWeight: "bold"
    }

    return <>
        {accountId >= 0 && <div style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: isPhoneSize() ? "100vw" : "500px", /* fixed width */
            height: "350px", /* fixed height */
            background: "white",
            border: "1px solid black",
            borderRadius: "10px"
        }}>
            <div style={{
                position: "absolute",
                top: "12px",
                right: "12px",
                cursor: "pointer",
                fontSize: "20px"
            }}
                onClick={() => setAccountId(-1)}>
                <CloseOutlined />
            </div>
            
            {loading && <div style={{margin: "20px"}}><Spin/></div>}

            {!loading && account && <div style={{}}>
                <div style={{...entryStyle, fontSize: "20px"}}>
                    {account.account_name}
                </div>

                <div style={entryStyle}>
                    <div style={keyStyle}>
                        Account Number
                    </div>
                    <div>
                        {account.account_number}
                    </div>
                </div>

                <div style={entryStyle}>
                    <div style={keyStyle}>
                        Bank Address
                    </div>
                    <div>
                        {account.bank_address}
                    </div>
                </div>

                <div style={entryStyle}>
                    <div style={keyStyle}>
                        Bank Name
                    </div>
                    <div>
                        {account.bank_name}
                    </div>
                </div>

                <div style={entryStyle}>
                    <div style={keyStyle}>
                        EIN
                    </div>
                    <div>
                        {account.ein}
                    </div>
                </div>

                <div style={entryStyle}>
                    <div style={keyStyle}>
                        Routing Number
                    </div>
                    <div>
                        {account.routing_number}
                    </div>
                </div>
            </div>}
        </div>}
    </>
}