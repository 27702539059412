import api from "api";
import { get } from "utils/fetch";
import { isPhoneSize } from "utils/util";
import { GlobalContext } from "GlobalContext";
import { useEffect, useState, useContext, useRef } from "react"
import { Select, Space, message, Spin, Switch } from "antd"

export function Overview() {
    const { manageRecordId, accessToken } = useContext(GlobalContext)
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(true)
    const [overview, setOverview] = useState("")
    const textareaRef = useRef(null);

    const displayErrorMessage = (message) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };

    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = "auto"; // Reset height to recalculate
            textareaRef.current.style.height = textareaRef.current.scrollHeight + "px";
        }
    }, [overview]);

    async function fetchOfferingOverview(id) {
        await get(api.offering.getOfferingOverview(id), setOverview, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            }), accessToken
        )
        setLoading(false)
    }

    async function updateOverview() {
        setLoading(true)
        const body = JSON.stringify({
            overview
        })

        const options = {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${accessToken}`,
            },
            body
        }

        const response = await fetch(api.offering.updateOfferingOverview(manageRecordId), options);
        setOverview("")
        const data = await response.json()
        fetchOfferingOverview(manageRecordId)
    }

    useEffect(() => {
        // if (accessToken === "") return
        fetchOfferingOverview(manageRecordId)
    }, [accessToken])

    return <div style={{ justifyItems: "center" }}>
        <div style={isPhoneSize() ? {
            width: window.innerWidth - 20,
        } : {
            width: "560px",
        }}>
            <h1>
                Overview
            </h1>
            {loading && <div style={{ marginTop: "30px", textAlign: "center" }}><Spin /></div>}
            {!loading && <>
                <textarea
                    ref={textareaRef}
                    value={overview ? overview : ""}
                    onChange={(e) => setOverview(e.target.value)}
                    style={{
                        width: "100%",
                        minHeight: "50px",
                        padding: "10px",
                        border: "1px solid #ccc",
                        borderRadius: "5px",
                        fontSize: "16px",
                        fontFamily: "Arial, sans-serif",
                        resize: "none", // Disable manual resizing
                        overflow: "hidden", // Prevent scrollbars
                    }}
                />

                <button style={{
                    height: "30px",
                    width: "120px",
                    borderRadius: "30px",
                    background: "rgb(17 32 86)",
                    color: "white",
                    cursor: "pointer",
                    marginTop: "20px"
                }}
                    onClick={updateOverview}
                >{"Update Overview"}</button>
            </>
            }
        </div>

    </div>
}