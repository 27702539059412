import { useFiefAuth, useFiefIsAuthenticated, useFiefUserinfo } from '@fief/fief/react';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { LoginEmailContext } from './Context';
import { GlobalContext } from 'GlobalContext';

const RequireAuth = ({ children, setEmail, email }) => {
    const fiefAuth = useFiefAuth();
    const isAuthenticated = useFiefIsAuthenticated();
    const userinfo = useFiefUserinfo();
    const { setGlobalEmail } = useContext(GlobalContext)
    let content = children;
    //console.log("RequireAuth: hi")
    const logout = useCallback(() => {
        fiefAuth.logout(`${window.location.protocol}//${window.location.host}`);
    }, [fiefAuth]);

    useEffect(() => {
        if (!isAuthenticated) {
            const params = {
                state: window.location.href,
            };
            fiefAuth.redirectToLogin(`${window.location.protocol}//${window.location.host}/callback`, params);
        } else if (!userinfo) {
            content = <div>Can't fetch user info, please <a onClick={logout}>login</a> again</div>
        } else {
            //console.log("RequireAuth.tsx")
            //console.log(userinfo);
            //console.log(userinfo.email);
            if (!userinfo.email || !userinfo.email_verified) content = <div>Email unverified, please verify your email first</div>
            if (!userinfo.is_active) content = <div>Account inactive, please ask your admin again!</div>
            if (email === undefined) {
                setEmail(userinfo.email); // Set email in state
                setGlobalEmail(userinfo.email)
            }
        }
    }, [fiefAuth, isAuthenticated]);

    return (
        <>
            {isAuthenticated && content}
        </>
    );
};

export default RequireAuth;
