import { isPhoneSize } from "utils/util"
import { useEffect, useState } from "react";

export function OfferingDetailedTab(props) {
    const { text, activeSection } = props

    return <div style={{ width: isPhoneSize() ? `${text.length * 100}%` : "", display: "flex", justifyContent: isPhoneSize() ? "center" : "", background: "transparent" }}>
        <a href={`#${text}`} >
            <div
                // id={text}
                style={isPhoneSize() ? {
                    fontWeight: "bold",
                    fontSize: isPhoneSize() ? "12px" : "20px",
                    // color: "black",
                    color: activeSection === text ? "#2683FF" : "#4D4D4D",
                } : {
                    fontFamily: "Helvetica",
                    fontSize: "16px",
                    fontWeight: "normal",
                    lineHeight: "#4D4D4D",
                    color: activeSection === text ? "#2683FF" : "#4D4D4D",
                    marginBottom: "10px"
                }}
            >
                {text}
            </div>
        </a>
    </div>
}