import { isAdmin } from "utils/util"
import { useState, useEffect, useContext } from "react"
import { Select, Space, message, Spin } from "antd"
import { get } from "utils/fetch";
import api from "api";
import { USDollar } from "utils/format";
import { GlobalContext } from 'GlobalContext';
import { isPhoneSize } from "utils/util";

export function PortfolioSumary(props) {

    const { setManageRecordId, setManageRecordTab, accessToken } = useContext(GlobalContext)
    const [messageApi, contextHolder] = message.useMessage();
    const [positions, setPositions] = useState([])
    const [uniqueFundIds, setUniqueFundIds] = useState([])
    const [fundIdToName, setFundIdToName] = useState({})
    const [loading, setLoading] = useState(true)
    const [DRIs, setDRIs] = useState([])
    const [driIdSelected, setDriIdSelected] = useState(-1)
    const [accountIdsByDRI, setAccountIdsByDRI] = useState([])
    const [positionsToShow, setPositionsToShow] = useState([])
    const [startDateFilter, setStartDateFilter] = useState("")
    const [endDateFilter, setEndDateFilter] = useState("")
    const [useDateFilter, setUseDateFilter] = useState(false)
    const defaultFilterByDRIText = "All Investors"

    var driName2id = { "All Investors": -1 }
    for (const dri of DRIs) {
        driName2id[dri.legal_name] = dri.id
    }

    const displayErrorMessage = (message) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };

    async function fetchPositions() {
        await get(api.manager.positions, setPositions, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            }), accessToken
        )
        setLoading(false)
    }

    async function fetchDRIs() {
        await get(api.manager.DRIs, setDRIs, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            }), accessToken
        )
    }

    async function fetchAccountIdsByDRI(id) {
        await get(api.manager.accountIdsByDRI(id), setAccountIdsByDRI, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            }), accessToken
        )
    }

    const entryStyle = {
        margin: "20px",
        fontSize: '20px'
    }

    // console.log(positions)

    useEffect(() => {
        setPositionsToShow(positions
            .filter(position => {
                if (driIdSelected === -1) {
                    return true
                } else {
                    return accountIdsByDRI.includes(position.account_id)
                }
            })
            .filter(position => {
                if (useDateFilter === false) {
                    return true
                }
                if (startDateFilter === "" && endDateFilter === "") {
                    return true
                }
                if (startDateFilter !== "" && endDateFilter !== "") {
                    return position.funding_receipt_date >= startDateFilter && position.funding_receipt_date <= endDateFilter
                }
                if (startDateFilter !== "") {
                    return position.funding_receipt_date >= startDateFilter
                }
                return position.funding_receipt_date <= endDateFilter
            })
        )
    }, [positions, accountIdsByDRI, startDateFilter, endDateFilter, useDateFilter])

    console.log("positionsToShow", positions, accountIdsByDRI, positionsToShow, positions
        .filter(position => {
            if (driIdSelected === -1) {
                return true
            } else {
                return accountIdsByDRI.includes(position.account_id)
            }
        }))

    useEffect(() => {
        const uniqueFundIds = [
            ...new Set(positionsToShow
                .map(position => position.fund_id))
        ]
        const idToNameMapping = positionsToShow.reduce((acc, cur) => {
            acc[cur.fund_id] = cur.fund_name
            return acc
        }, {})
        setUniqueFundIds(uniqueFundIds)
        setFundIdToName(idToNameMapping)
    }, [positionsToShow])

    const handleMyDRIChange = (dri) => {
        setDriIdSelected(driName2id[dri])
    };

    useEffect(() => {
        if (accessToken === "") return
        fetchPositions()
        fetchDRIs()
    }, [accessToken])

    useEffect(() => {
        if (driIdSelected > 0) {
            fetchAccountIdsByDRI(driIdSelected)
        } else {
            setAccountIdsByDRI([])
        }
    }, [driIdSelected])

    const driSelectionOptions = [{
        label: defaultFilterByDRIText,
        value: defaultFilterByDRIText,
        desc: defaultFilterByDRIText
    }, ...DRIs
        .map(DRI => {
            return {
                label: DRI.legal_name,
                value: DRI.legal_name,
                desc: DRI.legal_name
            }
        })]
        
    return <>
        {!isAdmin() && <div>Not Authorized</div>}
        {isAdmin() && loading && <div style={{ marginTop: "30px", textAlign: "center" }}><Spin /></div>}
        {isAdmin() && !loading && <div style={isPhoneSize() ? {} : {
            display: "flex",
            justifyContent: "center",
            flexDirection: "column"
        }}>
            <div style={{ display: "flex", flexDirection: "column", marginLeft: "20px", marginTop: "10px" }}>
                <div>
                    <span style={{ fontSize: "14px" }}>
                        DRI:
                    </span>
                    <span style={{ width: "150px" }}>
                        <Select
                            style={isPhoneSize() ? {
                                width: '150px'
                            } : {
                                width: '150px', marginLeft: "5px"
                            }}
                            placeholder="select DRI"
                            defaultValue={driName2id[driIdSelected]}
                            onChange={handleMyDRIChange}
                            options={driSelectionOptions}
                            optionRender={(option) => (
                                <Space>
                                    <span role="img" aria-label={option.data.label}>
                                        {option.data.emoji}
                                    </span>
                                    {option.data.desc}
                                </Space>
                            )}
                        />
                    </span>
                </div>

                <div style={{ margin: "10px 0" }}>
                    <span style={{ fontSize: "14px", marginRight: "9px" }}>
                        Start Date:
                    </span>
                    <span style={{ width: "150px" }}>
                        <input type="date" onChange={(e) => setStartDateFilter(e.target.value)}></input>
                    </span>
                </div>

                <div>
                    <span style={{ fontSize: "14px", marginRight: "14px" }}>
                        End Date:
                    </span>
                    <span style={{ width: "150px" }}>
                        <input type="date" onChange={(e) => setEndDateFilter(e.target.value)}></input>
                    </span>
                </div>

                <div style={{marginTop: "10px"}}>
                    <span style={{ fontSize: "14px", marginRight: "14px" }}>
                        <input type="checkbox" onClick={(e) => setUseDateFilter(e.target.checked)}></input>
                        Use Date Filter
                    </span>
                </div>

            </div>
            <div style={isPhoneSize() ? entryStyle : {
                ...entryStyle,
                overflowX: "auto",
                marginTop: "10px"
            }}>
                {/* head */}
                <div style={isPhoneSize() ? {
                    // overflow: "auto",
                    // scrollbarWidth: "auto",
                    overflowX: "auto",
                    overflowY: "hidden",
                    // scrollbarWidth: "auto",
                    display: "flex",
                    justifyContent: "center"
                } : {
                    // overflow: "auto",
                    // scrollbarWidth: "auto",
                    width: "900px",
                    overflowX: "auto",
                    overflowY: "hidden",
                    // scrollbarWidth: "auto",
                    display: "flex",
                    justifyContent: "center"
                }}>
                    <div style={isPhoneSize() ? { width: "900px" } : {

                    }}>
                        <div style={{ display: "flex", fontSize: "17px" }}>
                            <div style={{ width: "270px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                Fund
                            </div>

                            <div style={{ width: "180px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                Commitment Number
                            </div>

                            <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                Equity Balance
                            </div>

                            <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                Distribute Interest
                            </div>

                            <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                Principal Return
                            </div>
                        </div>
                        {positions.length > 0 && <div style={{ border: "1px solid black", width: "900px" }}>
                            {
                                uniqueFundIds
                                    // .sort((a, b) => {
                                    //     if (a.legal_name < b.legal_name) {
                                    //         return -1
                                    //     }
                                    //     return 1
                                    // })
                                    .map((fund_id, idx) => {
                                        return <div key={idx} style={{ display: "flex", flexDirection: "row", borderTop: idx > 0 ? "1px solid black" : "" }}>
                                            {/* <div style={{ width: "270px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center", flexDirection: 'column' }}>
                                                {fundIdToName[fund_id]}
                                            </div> */}
                                            <span style={{
                                                // fontSize: "20px",
                                                // margin: "20px",
                                                width: "270px",
                                                display: "flex",
                                                placeContent: "center",
                                                alignItems: "center",
                                                textAlign: "center",
                                                flexDirection: 'column',
                                                color: "blue",
                                                textDecoration: "underline",
                                                cursor: "pointer"
                                            }}
                                                onClick={() => {
                                                    setManageRecordId(fund_id)
                                                    setManageRecordTab("Entities/Funds")
                                                }}>
                                                {fundIdToName[fund_id]}
                                            </span>

                                            <div style={{ width: "180px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                                {USDollar.format(positionsToShow.reduce((acc, cur) => {
                                                    if (cur.fund_id !== fund_id) {
                                                        return acc
                                                    }
                                                    return acc + cur.amount
                                                }, 0))}
                                            </div>

                                            <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                                {USDollar.format(positionsToShow.reduce((acc, cur) => {
                                                    if (cur.fund_id !== fund_id) {
                                                        return acc
                                                    }
                                                    return acc + cur.account_balance
                                                }, 0))}
                                            </div>

                                            <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                                {USDollar.format(positionsToShow.reduce((acc, cur) => {
                                                    if (cur.fund_id !== fund_id) {
                                                        return acc
                                                    }
                                                    return acc + cur.interest_received
                                                }, 0))}
                                            </div>

                                            <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                                {USDollar.format(positionsToShow.reduce((acc, cur) => {
                                                    if (cur.fund_id !== fund_id) {
                                                        return acc
                                                    }
                                                    return acc + cur.principal_returned
                                                }, 0))}
                                            </div>
                                        </div>
                                    }
                                    )
                            }

                            <div style={{ display: "flex", borderTop: "1px solid black" }}>
                                <div style={{ width: "270px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                    Summary
                                </div>

                                <div style={{ width: "180px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                    {USDollar.format(
                                        positionsToShow.reduce((acc, cur) => {
                                            return acc + cur.amount
                                        }, 0)
                                    )}
                                </div>

                                <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                    {USDollar.format(
                                        positionsToShow.reduce((acc, cur) => {
                                            return acc + cur.account_balance
                                        }, 0)
                                    )}
                                </div>

                                <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                    {USDollar.format(
                                        positionsToShow.reduce((acc, cur) => {
                                            return acc + cur.interest_received
                                        }, 0)
                                    )}
                                </div>

                                <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                    {USDollar.format(
                                        positionsToShow.reduce((acc, cur) => {
                                            return acc + cur.principal_returned
                                        }, 0)
                                    )}
                                </div>
                            </div>
                        </div>
                        }
                    </div>
                    {/* <div style={isPhoneSize() ? {
                        height: "250px", display: "flex"
                    } : {
                        height: "50px", display: "flex"
                    }}></div> */}
                </div>
            </div>
        </div>}
    </>
}