import { USDollar } from "utils/format";
import arrowLeft from "../assets/img/arrowLeft.png";
import arrowRight from "../assets/img/arrowRight.png";
import { useEffect, useState, useContext } from "react";
import { isAdmin, isInvestor } from "utils/util";
import api from "api";
import { sendEmail, sevenDaysFromNow } from "utils/util";
import { Select, Space, Spin, message } from "antd"
import { get } from "utils/fetch";
import { GlobalContext } from 'GlobalContext';
import { PrincipalSumErrorConfirmation } from "ConfirmationPages/PrincipalSumErrorConfirmation";
import { CloseInvestmentConfirmation } from "ConfirmationPages/CloseInvestmentConfirmation";
import { getTodayDateString } from "utils/util";

export function TransactionGallery(props) {
    const { transactions: initialTransactions, basicInfo, accountId, accountName, investment_id, refreshPage } = props;
    const { accessToken } = useContext(GlobalContext)
    const [messageApi, contextHolder] = message.useMessage();
    const timezoneOffset = "T00:00:00-07:00"
    const [curPageIdx, setCurPageIdx] = useState(0);
    const [loading, setLoading] = useState(true)
    const [investors, setInvestors] = useState([])
    const [showErrorbox, setShowErrorbox] = useState(false)
    const [showCloseConfirmationBox, setShowCloseConfirmationBox] = useState(false)
    const [transactions, setTransactions] = useState(isAdmin() ? initialTransactions :
        initialTransactions.filter(transaction => new Date(transaction.timestamp + timezoneOffset) <= new Date()));

    var id2PaidDate = {}
    for (const transaction of initialTransactions) {
        id2PaidDate[transaction.id] = transaction.paid_timestamp
    }
    const [id2PaidDateMap, setId2PaidDateMap] = useState(id2PaidDate)
    //console.log(new Date(`2024-08-31` + "T00:00:00-07:00"))
    //console.log(new Date())
    //console.log("new Date('2024-08-31') <= new Date()", new Date("2024-08-31") <= new Date())
    // console.log(new Date())
    // console.log(sevenDaysFromNow())
    const displayErrorMessage = (message) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };

    async function fetchEmailsByAccountId(id) {
        await get(api.manager.investorsByAccount(id), setInvestors, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            }), accessToken
        )
        setLoading(false)
    }

    useEffect(() => {
        if (accessToken === "") return
        fetchEmailsByAccountId(accountId)
    }, [accountId, accessToken])

    // console.log(investment_id)
    function isFirstPage() {
        return curPageIdx === 0;
    }

    function isLastPage() {
        return (curPageIdx + 1) * 10 + 1 > transactions.length;
    }

    const curTransaction = transactions.slice(curPageIdx * 10, curPageIdx * 10 + 10);

    const handleStatusChange = async (transaction, idx, email, name, type) => {
        const updatedStatus = transaction.transaction_status === "unpaid" ? "paid" : "unpaid";
        if (updatedStatus.toLowerCase() === "paid") {
            setId2PaidDateMap({ ...id2PaidDateMap, [transaction.id]: getTodayDateString() })
        } else {
            setId2PaidDateMap({ ...id2PaidDateMap, [transaction.id]: "" })
        }
        const response = await fetch(api.transaction.setStatus(transaction.id), {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${accessToken}`,
            },
            body: JSON.stringify({
                status: updatedStatus,
            }),
        });

        // # unpaid entry
        const data = await response.json()
        const unpaidEntryCount = data.data
        // console.log("unpaidEntryCount", unpaidEntryCount)
        if (unpaidEntryCount === -2) {
            // update status failed, don't update ui status and don't send email
            return
        }



        const updatedTransactions = [...transactions];
        updatedTransactions[idx + curPageIdx * 10].transaction_status = updatedStatus;
        setTransactions(updatedTransactions);
        // buttonText, amount, email, name, type
        sendEmail(updatedStatus === "paid" ? "Pay" : "Unpay", transaction.amount, email, name, type);
        if (unpaidEntryCount > 0) {
            // refreshPage()
        } else if (unpaidEntryCount === 0) {
            // pop up prompt to close investment
            setShowCloseConfirmationBox(true)
        } else if (unpaidEntryCount === -1) {
            // error in summation of principals
            // pop up prompt to contact developer
            setShowErrorbox(true)
        }
    };

    async function closeInvestmentCallback() {
        await fetch(api.manager.closeInvestment(investment_id), {
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${accessToken}`,
            }
        });
        refreshPage()
    }

    //console.log(transactions)
    return <>
        {loading && <div style={{ marginLeft: "70px" }}><Spin /></div>}
        {!loading && <div>
            {showCloseConfirmationBox && <CloseInvestmentConfirmation
                setShowCloseConfirmationBox={setShowCloseConfirmationBox}
                closeInvestmentCallback={closeInvestmentCallback}
            />}
            {showErrorbox && <PrincipalSumErrorConfirmation setShowErrorbox={setShowErrorbox} />}
            <div style={{ display: 'flex', alignItems: "center" }}>
                <div style={{
                    width: "30px",
                    height: "30px",
                    display: "flex",
                    position: "relative",
                    left: '15px',
                    zIndex: isFirstPage() ? "0" : "1",
                    cursor: isFirstPage() ? "auto" : 'pointer'
                }}
                    onClick={() => !isFirstPage() && setCurPageIdx(curPageIdx - 1)}
                >
                    <img style={{ display: isFirstPage() ? "none" : "flex" }} src={arrowLeft}></img>
                </div>
                <div style={{ display: 'flex', flexDirection: "column" }}>
                    {curTransaction.map((transaction, idx) => (
                        <li style={{
                            color: transaction.transaction_status.toLowerCase() === "unpaid" ? "gray" : "black",
                            margin: 0,
                            display: "flex"
                        }} key={transaction.id}>
                            <div style={{ width: "100px", textAlign: "center" }}>{transaction.timestamp}</div>
                            <div style={{ width: "100px", textAlign: "center" }}>{id2PaidDateMap[transaction.id]}</div>
                            <div style={{ width: "100px", textAlign: "center" }}>{USDollar.format(transaction.amount)}</div>
                            <div style={{ width: "100px", textAlign: "center" }}>{transaction.type.charAt(0).toUpperCase() + transaction.type.slice(1)}</div>

                            <div style={{ width: "100px", textAlign: "center" }}>
                                {transaction.transaction_status.charAt(0).toUpperCase() + transaction.transaction_status.slice(1)}
                            </div>


                            {/* ----------------------------------------------------------------- */}
                            {/* for admin: cashout: later than today: "pay" */}
                            {/* {isAdmin() && transaction.transaction_status.toLowerCase() !== "reinvest" && new Date(transaction.timestamp + timezoneOffset) > new Date() &&
                                <div style={{ width: "100px", textAlign: "center" }}>{transaction.transaction_status.charAt(0).toUpperCase() + transaction.transaction_status.slice(1)}</div>
                            } */}
                            {/* for admin: cashout: earlier than today: <button>pay/unpay<button> */}
                            {isAdmin() && transaction.transaction_status.toLowerCase() !== "reinvest" && new Date(transaction.timestamp + timezoneOffset) <= sevenDaysFromNow() &&
                                <div style={{ width: "100px", textAlign: "center" }}>
                                    <button
                                        style={{ background: "rgb(17 32 86)", color: "white", borderRadius: "5px" }}
                                        onClick={() => handleStatusChange(transaction, idx, investors.map(investor => investor.email), accountName, transaction.type.toLowerCase())}
                                    >
                                        {transaction.transaction_status === "unpaid" ? "Pay" : "Unpay"}
                                    </button>
                                </div>
                            }
                            {/* for investor: cashout: "pay/paid" */}
                            {/* {isInvestor() && transaction.transaction_status.toLowerCase() !== "reinvest" && <div style={{ width: "100px", textAlign: "center" }}>
                                {transaction.transaction_status.charAt(0).toUpperCase() + transaction.transaction_status.slice(1)}
                            </div>} */}

                            {/* universal: reinvest: "reinvest" */}
                            {/* {transaction.transaction_status.toLowerCase() === "reinvest" &&
                                <div style={{ width: "100px", textAlign: "center" }}>
                                    {transaction.transaction_status.charAt(0).toUpperCase() + transaction.transaction_status.slice(1)}
                                </div>
                            } */}
                        </li>
                    ))}
                </div>


                <div style={{
                    width: "30px",
                    height: "30px",
                    display: isLastPage() ? "none" : "flex",
                    position: "relative",
                    right: '15px',
                    zIndex: isLastPage() ? "0" : "1",
                    cursor: isLastPage() ? "auto" : 'pointer'
                }}
                    onClick={() => !isLastPage() && setCurPageIdx(curPageIdx + 1)}
                >
                    <img src={arrowRight}></img>
                </div>
            </div>
        </div>
        }
    </>
}
