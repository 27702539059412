export function EmailResultBox(props) {
    const { emailResponse } = props

    function getEmailResBGColor() {
        if (emailResponse === "Email sent successfully") {
            return "#007BFF"
        } else if (emailResponse === "Sending...") {
            return "#4A90E2"
        }
        return "#FF4C4C"
    }

    function getEmailBoxShadow() {
        if (emailResponse === "Email sent successfully") {
            return "0 4px 8px rgba(0, 123, 255, 0.3)"
        } else if (emailResponse === "Sending...") {
            return "0 4px 8px #3E7BB1"
        }
        return "0 4px 8px rgba(255, 76, 76, 0.3)"
    }

    return <>
        {emailResponse.length > 0 && <div style={{
            position: "fixed",       /* Fix the div in place */
            top: "100px",           /* 100px from the top */
            right: "0",             /* Align to the right */
            width: "200px",         /* Optional width */
            backgroundColor: getEmailResBGColor(), /* Success Blue */
            color: "white",              /* White text for contrast */
            padding: "10px",
            borderRadius: "5px",
            boxShadow: getEmailBoxShadow(), /* Shadow for success */
            transition: "opacity 1s ease, transform 1s ease" /* Transition for smoothness */
        }}>
            {emailResponse}
        </div>}
    </>
}