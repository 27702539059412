import backArrow from "../../assets/img/backArrow.png"
import { useState, useRef, useEffect, useContext } from "react";
import { Spin, message } from "antd";
import './EmailInput.css';
import api from "api";
import { get } from "utils/fetch";
import PdfIcon from "../../assets/img/PdfIcon.png"
import { FaPaperclip } from 'react-icons/fa';
import { getMimeType, getFileIcon } from "utils/util";
import { USDollar } from "utils/format";
import { GlobalContext } from 'GlobalContext';

export function Email(props) {
    const { setView, emailPassed, fromView, setEmailResponse, fromId } = props

    const { accessToken } = useContext(GlobalContext)
    const [messageApi, contextHolder] = message.useMessage();
    const [emails, setEmails] = useState(emailPassed);
    const [inputEmail, setInputEmail] = useState('');
    const [subject, setSubject] = useState('')
    const textAreaRef = useRef(null);
    const [content, setContent] = useState("")
    const [loading, setLoading] = useState(true)
    const [files, setFiles] = useState([]);
    const [fundName, setFundName] = useState("")
    const [investmentAmount, setInvestmentAmount] = useState(0)
    const [investorNames, setInvestorNames] = useState([])
    const [investmentStartingDate, setInvestmentStartingDate] = useState("")

    const displayErrorMessage = (message) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };

    // console.log(content)

    async function fetchSubjectTemplate() {
        await get(api.email.subjectTemplate, setSubject, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            }), accessToken
        )
    }

    async function fetchPdf() {
        // Fetch the PDF from the FastAPI backend
        fetch(api.email.pdfTemplate, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/pdf',
                'Authorization': `Bearer ${accessToken}`,
            }
        })
            .then(response => {
                // Get filename from Content-Disposition header
                const contentDisposition = response.headers.get('Content-Disposition');
                // console.log(contentDisposition)
                const matches = contentDisposition && contentDisposition.match(/filename=(.+)/);
                // console.log(contentDisposition.match(/filename=(.+)/))
                const filename = matches ? matches[1] : "default.pdf";  // Fallback if no filename
                return response.blob().then(blob => {
                    const url = URL.createObjectURL(blob);
                    setFiles([...files, { filename, url, blob }])
                })
            })
            .catch(err => console.error('Error fetching PDF:', err));
    }

    async function fetchContentTemplate() {
        await get(api.email.contentTemplate, setContent, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            }), accessToken
        )
        setLoading(false)
    }

    async function fetchFundName(id) {
        await get(api.email.fundName(id), setFundName, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            }), accessToken
        )
    }

    async function fetchInvestmentAmount(id) {
        await get(api.email.investmentAmount(id), setInvestmentAmount, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            }), accessToken
        )
    }

    async function fetchInvestorNames(id) {
        await get(api.email.investorNames(id), setInvestorNames, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            }), accessToken
        )
    }

    async function fetchInvestmentStartingDate(id) {
        await get(api.email.investmentStartingDate(id), setInvestmentStartingDate, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            }), accessToken
        )
    }

    async function fetchInvestmentInfo(id) {
        await Promise.all([
            fetchFundName(id),
            fetchInvestmentAmount(id),
            fetchInvestorNames(id),
        ]);
    }

    function setReceiptSubject() {
        setSubject(`Receipt and Acceptance of Capital Investment in ${fundName}`)
    }

    function setReceiptContent() {
        setContent(`Dear Investor,

We are delighted to formally announce the successful receipt of your capital investment amounting to ${USDollar.format(investmentAmount)} for investing in ${fundName}, and your investment subscription has been reviewed and officially accepted. We extend our appreciation for your support and commitment to our company! You could also check the receipt in the "document" part in your Investor Portal. 

We look forward to keeping you informed about our progress and milestones as we work diligently to maximize the potential of your investment. 

If you have any inquiries, require additional information, or wish to discuss any aspect of your investment, please feel free to reach out to us. Your engagement and feedback are invaluable to us.

Thank you for your invaluable trust and partnership.

Warm regards,
AlphaX RE Capital
`)
    }

    // console.log(fundName)
    // console.log(investmentAmount)
    // console.log(investorNames)
    // console.log(investmentStartingDate)
    async function fetchReceiptPdf() {
        const body = JSON.stringify({
            fundName,
            investmentAmount: USDollar.format(investmentAmount),
            investmentStartingDate,
            investorNames
        })
        // Fetch the PDF from the FastAPI backend
        fetch(api.email.receiptPdf, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/pdf',
                'Authorization': `Bearer ${accessToken}`,
            },
            body
        })
            .then(response => {
                // Get filename from Content-Disposition header
                const contentDisposition = response.headers.get('Content-Disposition');
                // console.log(contentDisposition)
                const matches = contentDisposition && contentDisposition.match(/filename="(.+)"/);
                // console.log(contentDisposition.match(/filename=(.+)/))
                const filename = matches ? matches[1] : "default.pdf";  // Fallback if no filename
                return response.blob().then(blob => {
                    const url = URL.createObjectURL(blob);
                    setFiles([...files, { filename, url, blob }])
                })
            })
            .then(() => setLoading(false))
            .catch(err => console.error('Error fetching PDF:', err));
    }

    useEffect(() => {
        // set up receipt text
        // console.log("fundName", fundName)
        // console.log("investmentAmount", investmentAmount)
        // console.log("investorNames", investorNames)
        // console.log("investmentStartingDate", investmentStartingDate)
        if (fromView === "investment" && fundName && investmentAmount && investorNames.length && investmentStartingDate.length) {
            setReceiptSubject()
            setReceiptContent() // Only set content when all the necessary data is loaded
            fetchReceiptPdf()
        }
    }, [fundName, investmentAmount, investorNames, investmentStartingDate]); // Watch for changes to these values


    async function setUp() {
        if (fromView === "investment") {
            // need to get fund name, investor name, invested amount
            fetchFundName(fromId)
            fetchInvestmentAmount(fromId)
            fetchInvestmentStartingDate(fromId)
            fetchInvestorNames(fromId)
        } else {
            // fetchSubjectTemplate()
            // fetchContentTemplate()
            // fetchPdf()
            setLoading(false)
        }
    }

    useEffect(() => {
        if (accessToken === "") return
        // set up subject, content, file
        setUp()
    }, [accessToken])

    // Handle input change
    const handleInputChange = (e) => {
        setInputEmail(e.target.value);
    };

    // Handle key down (when pressing "Enter")
    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && inputEmail.trim() !== '') {
            // Basic email validation
            if (validateEmail(inputEmail.trim())) {
                setEmails([...emails, inputEmail.trim()]);
                setInputEmail(''); // Clear input after adding email
            } else {
                alert('Please enter a valid email address');
            }
            e.preventDefault();
        }
    };

    // Validate email using a basic regex
    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    // Remove email from the list
    const removeEmail = (index) => {
        setEmails(emails.filter((_, i) => i !== index));
    };

    useEffect(() => {
        // Adjust the height based on the scroll height
        if (textAreaRef.current) {
            textAreaRef.current.style.height = "auto"; // Reset height to auto
            textAreaRef.current.style.height = textAreaRef.current.scrollHeight + "px"; // Set to scroll height
        }
    }, [content]); // Re-run whenever the content changes
    console.log(fromId)
    async function sendEmail() {
        if (emails.length === 0) {
            alert("Please add recipients")
            return
        }
        setEmailResponse("Sending...")
        setView(fromView)
        // Create a FormData object
        const formData = new FormData();
        // formData.append('files', files.map(file => new File([file.blob], file.filename, { type: 'application/pdf' })))
        files.map(file => formData.append('files', new File([file.blob], file.filename, { type: getMimeType(file.filename) })))
        // formData.append('to_email', emails);  // Append the recipient's email
        emails.map(email => formData.append("to_emails", email.trim()))
        formData.append('subject', subject);    // Append the email subject
        formData.append('content', content.replace(/\n/g, '<br>\n'));    // Append the email content
        formData.append('investment_id', fromId);

        // Send the email with the PDF attachment
        const emailResponse = await fetch(api.email.sendEmail, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
            },
            body: formData
        });

        const emailResult = await emailResponse.json();
        console.log(emailResult)
        setEmailResponse(emailResult.message)
        // Hide the message after 1 second
        setTimeout(() => {
            setEmailResponse('');
        }, 1000);
        // console.log(emailResult);
    }
    // console.log(content)
    // console.log(content.replace(/\n/g, '<br>\n'))
    const handleFileChange = (e) => {
        const file = e.target.files[0]; // Get the first selected file
        if (file) {
            // Generate a temporary URL for the file
            const tempUrl = URL.createObjectURL(file);
            setFiles([...files, {
                filename: e.target.files[0].name,
                url: tempUrl,
                blob: e.target.files[0]
            }]); // Allow multiple files to be uploaded
        }
    };

    const triggerFileInput = () => {
        document.getElementById('fileInput').click();  // Trigger file input click
    };

    return <>
        {loading && <div style={{ marginTop: "30px", textAlign: "center" }}><Spin /></div>}
        {!loading && <>
            <div style={{ margin: "20px" }}>
                <img style={{ width: "15px", height: "20px", cursor: "pointer" }}
                    src={backArrow}
                    onClick={() => setView(fromView)}
                ></img>
            </div>
            <div className="email-input-container" style={{ width: "100%" }}>
                <div className="emails" style={{ width: "100%" }}>
                    {emails.map((email, index) => (
                        <div key={index} className="email-chip">
                            {email}
                            <span className="remove-btn" onClick={() => removeEmail(index)}>
                                &times;
                            </span>
                        </div>
                    ))}
                    <input
                        type="text"
                        value={inputEmail}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        placeholder="Enter email addresses"
                        className="email-input"
                        style={{ width: `${Math.max(20, inputEmail.length * 10)}px` }}  // Dynamically resize input
                    />
                </div>
            </div>

            <div className="email-input-container" style={{ width: "100%" }}>
                <div className="emails" style={{ width: "100%" }}>
                    <input
                        type="text"
                        value={subject}
                        onChange={e => setSubject(e.target.value)}
                        // onKeyDown={handleKeyDown}
                        placeholder="Subject..."
                        className="email-input"
                        style={{ width: `100%` }}  // Dynamically resize input
                    />
                </div>
            </div>

            <div className="email-input-container" style={{ width: "100%" }}>
                <div className="emails" style={{ width: "100%" }}>
                    <textarea
                        type="text"
                        ref={textAreaRef}
                        value={content}
                        onChange={e => setContent(e.target.value)}
                        // onKeyDown={handleKeyDown}
                        placeholder="Content..."
                        className="email-input"
                        style={{ width: `100%`, minHeight: "500px", fontFamily: "Arial, sans-serif" }}  // Dynamically resize input
                    />
                </div>
            </div>

            {files && <div>
                {files.map((file, idx) =>
                    <div key={idx} style={{ display: "flex", flexDirection: "row" }}>
                        <a href={file.url} target="_blank" rel="noreferrer noopener">
                            {/* <iframe src={pdfUrl} width="100%" height="600px" title="PDF Preview" /> */}
                            <div style={{ display: "flex", alignItems: "center", fontSize: "15px", color: "black" }}>
                                <div style={{ padding: "5px", paddingBottom: "0", fontSize: "20px" }}>
                                    {getFileIcon(file.filename)}
                                    {/* <img src={PdfIcon} style={{ width: "14px" }}></img> */}
                                </div>
                                {file.filename}
                            </div>
                        </a>
                        <span style={{
                            marginLeft: "10px",
                            alignContent: "center",
                            fontWeight: "bold",
                            color: "#FF0000",
                            fontSize: "15px",
                            cursor: "pointer"
                        }} className="remove-btn" onClick={() => setFiles(files.filter((_, i) => i !== idx))}>
                            &times;
                        </span>
                    </div>
                )
                }
            </div>}

            <div style={{ display: "flex" }}>
                <button style={{
                    height: "30px",
                    width: "50px",
                    borderRadius: "30px",
                    background: "rgb(17 32 86)",
                    color: "white",
                    cursor: "pointer",
                }}
                    onClick={sendEmail}
                >
                    Send
                </button>

                <div style={{ alignSelf: "center", marginLeft: "10px" }}>
                    <FaPaperclip
                        style={{ cursor: "pointer", fontSize: "24px", color: "#4A90E2" }}
                        onClick={triggerFileInput}
                    />
                </div>
            </div>

            {/* Hidden file input */}
            <input
                id="fileInput"
                type="file"
                multiple
                onChange={handleFileChange}
                style={{ display: "none" }}  // Hide the default file input
            />
        </>
        }
    </>
}