import { USDollar } from "./format";
import { FaFilePdf, FaFileWord, FaFileExcel, FaFileImage, FaFileVideo, FaFileAudio, FaFileArchive, FaFileAlt } from 'react-icons/fa';

export function isPhoneSize() {
    return window.innerWidth <= 480
}

function decodeJWT(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

function getUserPermissions() {
    const fief_authstate = sessionStorage.getItem('fief-authstate')
    if (fief_authstate === null) return []
    const value = JSON.parse(fief_authstate);
    if (value.tokenInfo === null) return []
    const token = value.tokenInfo.access_token
    const permissions = decodeJWT(token).permissions
    return permissions
}

export function validTokenInfo() {
    const fief_authstate = sessionStorage.getItem('fief-authstate')
    if (fief_authstate === null) return false
    const value = JSON.parse(fief_authstate);
    if (value.tokenInfo === null) return false
    const token = value.tokenInfo.access_token
    const permissions = decodeJWT(token).permissions
    return permissions
}

export function isAdmin() {
    return getUserPermissions().includes("fief:admin")
}

export function isSale() {
    return getUserPermissions().includes("fief:sale")
}

export function isInvestor() {
    return !isAdmin()
}

export function strToFloat(num) {
    if (num.length === 0) {
        return 0
    }
    return parseFloat(num)
}

export function propertyToAddress(property) {
    if (!property) return "Unknown Address"
    return property.address2 !== null && property.address2 !== undefined && property.address2.trim().length > 0 ?
        `${property.address} ${property.address2}, ${property.city}, ${property.state} ${property.zip}` :
        `${property.address}, ${property.city}, ${property.state} ${property.zip}`
}

export function investorStructToName(investor) {
    return `${investor.legal_name} (${investor.email})`
}

export function investmentStructToName(investment) {
    // var ret = `${investment.legal_name}`
    // if (investment.account_name !== null && investment.account_name.length > 0) {
    //     ret += ` (${investment.account_name})`
    // }
    var ret = `${investment.account_name}`
    ret += ` - ${investment.fund_name}`
    if (investment.public_fund_name) {
        ret += ` (${investment.public_fund_name})`
    }
    ret += ` (${investment.funding_receipt_date.slice(0, 10)} - ${investment.actual_redemption_date.slice(0, 10)})`
    return ret
}

export function larkTextFieldToString(textField) {
    if (!textField) return ""
    return textField.map(investorEntry => investorEntry.text).join("")
}

export function timeStampToString(timestamp) {
    if (!timestamp) return ""
    const date = new Date(timestamp);
    return date.toISOString().slice(0, 10);
}

export function larkInvestmentStructToName(investment) {
    var res = ''
    res += larkTextFieldToString(investment.fields.Investor)
    res += ' - '
    res += larkTextFieldToString(investment.fields.Project)
    res += ' ('
    res += timeStampToString(investment.fields.起始日期)
    res += ' - '
    res += timeStampToString(investment.fields.结算日期)
    res += ')'
    return res
}

export function investorStructToDisplayedName(investor) {
    return `${investor.legal_name} (${investor.email})`
}

export function fundEntityToName(fund) {
    return fund.name
}

export function getTodayDateString() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so add 1
    const day = String(today.getDate()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate
}

export function getTomorrowDateString() {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    const year = tomorrow.getFullYear();
    const month = String(tomorrow.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so add 1
    const day = String(tomorrow.getDate()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate
}

export function sendEmail(buttonText, amount, email, name, type) {
    const subject = buttonText === "Pay" ? "Interest Paid" : "Interest Unpaid";
    email = [...new Set(email)]
    const holder = email.length > 1 ? "holders" : "holder"
    const body = buttonText === "Pay"
        ? `Hi ${name} account ${holder}, 
        We have sent you ${USDollar.format(amount)} of ${type}.`
        :
        `Hi ${name} account ${holder}, 
        We have unpaid ${USDollar.format(amount)} of ${type}.`;

    window.location.href = `mailto:${email.join(',')}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
}

export function getTypeBackgroundColor(curType) {
    if (curType.toLowerCase() === "debt") {
        return "#2386DE"
    } else if (curType.toLowerCase() === "equity") {
        return "#16B37C"
    }
    return "darkcyan"
}

export function getMimeType(fileName) {
    // Extract the file extension
    const extension = fileName.split('.').pop().toLowerCase();

    // Define a map of common file extensions and their MIME types
    const mimeTypes = {
        'txt': 'text/plain',
        'pdf': 'application/pdf',
        'doc': 'application/msword',
        'docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'xls': 'application/vnd.ms-excel',
        'xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'ppt': 'application/vnd.ms-powerpoint',
        'pptx': 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'jpg': 'image/jpeg',
        'jpeg': 'image/jpeg',
        'png': 'image/png',
        'gif': 'image/gif',
        'svg': 'image/svg+xml',
        'mp3': 'audio/mpeg',
        'wav': 'audio/wav',
        'mp4': 'video/mp4',
        'avi': 'video/x-msvideo',
        'mov': 'video/quicktime',
        'mpeg': 'video/mpeg',
        'zip': 'application/zip',
        'gz': 'application/gzip',
        'tar': 'application/x-tar',
        'html': 'text/html',
        'css': 'text/css',
        'js': 'application/javascript',
        'json': 'application/json',
        'xml': 'application/xml',
        'csv': 'text/csv',
        'md': 'text/markdown',
        'ics': 'text/calendar'
    };

    // Return the corresponding MIME type or a default value
    return mimeTypes[extension] || 'application/octet-stream';
}

export function getFileIcon(fileName) {
    // Extract the file extension
    const extension = fileName.split('.').pop().toLowerCase();

    // Define a map of file extensions to their corresponding icons
    const icons = {
        'pdf': <FaFilePdf color="red" />,
        'doc': <FaFileWord color="blue" />,
        'docx': <FaFileWord color="blue" />,
        'xls': <FaFileExcel color="green" />,
        'xlsx': <FaFileExcel color="green" />,
        'png': <FaFileImage color="orange" />,
        'jpg': <FaFileImage color="orange" />,
        'jpeg': <FaFileImage color="orange" />,
        'gif': <FaFileImage color="purple" />,
        'mp4': <FaFileVideo color="purple" />,
        'mov': <FaFileVideo color="purple" />,
        'mp3': <FaFileAudio color="brown" />,
        'wav': <FaFileAudio color="brown" />,
        'zip': <FaFileArchive color="blue" />,
        'tar': <FaFileArchive color="blue" />,
        'txt': <FaFileAlt color="black" />,
        'html': <FaFileAlt color="black" />,
        'csv': <FaFileAlt color="black" />
    };

    // Return the corresponding icon or a default file icon
    return icons[extension] || <FaFileAlt color="gray" />;
}

export function truncateText(text, length) {
    if (text.length <= length) {
        return text
    }
    return `${text.slice(0, length - 3).trim()}...`
}

export function convertAndFormatDatetime(dateStr) {
    // Parse the ISO 8601 datetime string
    const date = new Date(dateStr.replace(/\.\d{3,6}/, ''));

    // Format the datetime to the desired format: 'October 7, 2024, 10:35 AM'
    const options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        // hour: 'numeric',
        // minute: 'numeric',
        // hour12: true,
        // timeZoneName: 'short' // Optional: adds timezone abbreviation
    };

    // Get system's local timezone format using Intl.DateTimeFormat
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);

    return formattedDate;
}

export function getTomorrow(todayString) {
    const date = new Date(todayString);
    date.setDate(date.getDate() + 1); // Add one day
    return date.toISOString().split('T')[0]; // Output: "2022-07-01"
}

export function sevenDaysFromNow() {
    const nowPlus7Days = new Date();
    nowPlus7Days.setDate(nowPlus7Days.getDate() + 7);
    return nowPlus7Days
}

export function stringifyDict(dict) {
    console.log(dict, Object.keys(dict).length)
    if (Object.keys(dict).length === 0) {
        return "{}"
    }
    return JSON.stringify(dict)
}

export function downloadCSV(data, filename) {
    // Convert data array to CSV string
    const csvRows = data.map(row =>
        row.map(item => {
            item = item === null ? "" : item
            return `"${item.toString().replace(/"/g, '""')}"` // Escape double quotes
        }).join(',')
    );
    const csvContent = csvRows.join('\n');

    // Create a Blob for the CSV content
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

    // Create a link element and set it to the Blob
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.download = filename;

    // Trigger the download
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
}