import { Divider, Row } from "antd"
import www from "../assets/img/www.png"
import phone from '../assets/img/phone.png'
import emailImg from '../assets/img/email.png'
import address from "../assets/img/address.png"
import mainLogo from '../assets/img/logo-fullsize.png';
import { isPhoneSize } from "utils/util"

export function CompanyInfo(props) {
    const { action } = props
    return <div style={{ background: "#F7F7F9", padding: "10px", borderRadius: "12px" }}>
        <img style={{ width: '200px' }} src={mainLogo} alt="main-logo" />
        {/* <h3>AlphaX RE Capital</h3> */}
        <p>
            AlphaX RE Capital is a builder-developer real estate investment and management company based in Silicon Valley. We specialize in creating sustainable communities and investing in core California counties like Santa Clara and Orange, while expanding into areas such as the Inland Empire. With over 630 projects completed, we manage $300 million in assets and plan future developments valued at $800 million. Our flexible investment strategies are driven by market dynamics, ensuring strong returns and long-term asset appreciation. <br></br><br></br>Please click the link below to schedule an 1 on 1 meeting for more information!<br></br>
        </p>
        {action && action === "Invest" ?
            <a target="_blank" rel="noopener noreferrer" href='https://calendly.com/alphax-investment/30min' style={{ color: "red" }}>
                <button style={{ background: "#1777FF", color: "white", width: "100%", height: "30px", borderRadius: "8px", cursor: "pointer", marginTop: "10px", border: "none" }}>
                    Schedule a meeting
                </button>
            </a> :
            <a target="_blank" rel="noopener noreferrer" href='https://calendly.com/alphax-investment/30min' style={{ color: "red" }}>
                <button style={{ background: "#1777FF", color: "white", width: "100%", height: "30px", borderRadius: "8px", cursor: "pointer", marginTop: "10px", border: "none" }}>
                    Schedule a meeting
                </button>
            </a>}

        {/* <div style={{ display: "flex", flexDirection: "row", paddingTop: "10px" }}>
            <div style={{ width: "30px", height: "30px", display: "flex" }}>
                <img src={phone}></img>
            </div>
            <a style={{ alignContent: "center" }} href="tel:818-818-3888">(818) 818-3888</a>
        </div> */}

        <div style={{ display: "flex", flexDirection: "row", paddingTop: "10px" }}>
            <div style={{ width: "30px", height: "30px", display: "flex" }}>
                <img src={emailImg}></img>
            </div>
            <a style={{ alignContent: "center" }} href="mailto:info@alphax-capital.com">
                info@alphax-capital.com
            </a>
        </div>

        <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ width: "30px", height: "30px", display: "flex" }}>
                <img src={address}></img>
            </div>
            <a style={{ alignContent: "center" }} target="_blank" rel="noopener noreferrer" href='https://maps.app.goo.gl/UgViqSU5yLBu31zr7'>
                10050 N Foothill Blvd, Cupertino, CA 95014
            </a>
        </div>

        <div style={{ display: "flex", flexDirection: "row", marginBottom: isPhoneSize() ? "200px" : "" }}>
            <div style={{ width: "30px", height: "30px", display: "flex" }}>
                <img src={www}></img>
            </div>
            <a style={{ alignContent: "center" }} target="_blank" rel="noopener noreferrer" href='https://www.alphax-capital.com'>
                www.alphax-capital.com/
            </a>
        </div>
    </div>
}